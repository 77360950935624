import { all, call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import {
    SMB_GET_ORDER_REQUEST,
    SMB_REORDER_REQUEST,
    smbGetOrderResponse,
    smbReorderResponse,
} from '../actions/orderDetails'
import * as Sentry from '@sentry/react'

function* reorder(action) {
    const { orderNumber } = action.payload

    try {
        yield call(axios.request, {
            url: `/ajax/copy-cart-from-order/${orderNumber}/`,
            method: 'POST',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        yield put(smbReorderResponse())
    } catch (e) {
        yield call(Sentry.captureException, e)
        const errorMessage =
            e.response?.data?.message ??
            'Your request failed. Refresh the\n' +
                'page and try again or contact our\n' +
                'support team.'
        yield put(smbReorderResponse(errorMessage, '', true))
    }
}

function* getOrder(action) {
    const { orderNumber } = action.payload

    try {
        const { data } = yield call(axios.request, {
            url: `/account/orders/ajax/${orderNumber}/`,
            method: 'GET',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        yield put(smbGetOrderResponse(data))
    } catch (e) {
        yield call(Sentry.captureException, e)
        const errorMessage =
            e.response?.data?.message ??
            'Your request failed. Refresh the\n' +
                'page and try again or contact our\n' +
                'support team.'
        yield put(smbGetOrderResponse(errorMessage, '', true))
    }
}

export function* orderDetailsSagas() {
    yield all([
        takeLatest(SMB_REORDER_REQUEST, reorder),
        takeLatest(SMB_GET_ORDER_REQUEST, getOrder),
    ])
}

export default orderDetailsSagas
