import { put, takeLatest, all, call } from 'redux-saga/effects'
import axios from 'axios'
import {
    GET_SMB_STATUS,
    getSMBStatusResponse,
    SUBMIT_SMB_FORM,
    submitSMBFormResponse,
} from '../actions/buyBulk'
import { SMB_APPLICATION_SOURCE } from '../constants'

function* getSMBStatus(action) {
    try {
        const { data } = yield call(axios.request, {
            url: `/ajax/small-business-status/`,
            method: 'get',
        })
        yield put(getSMBStatusResponse({ data }))
    } catch (e) {
        yield put(getSMBStatusResponse(e.response, '', true))
    }
}

function* submitSMBForm(action) {
    let postData = {
        company_name: action.payload.companyName,
        company_phone_number: action.payload.companyPhoneNumber,
        address_line1: action.payload.addressLine1,
        address_line2: action.payload.addressLine2,
        city: action.payload.city,
        state: action.payload.stateAbbr,
        zip_code: action.payload.zipCode,
        contact_name: action.payload.contactName,
        contact_phone_number: action.payload.contactNumber,
        contact_email: action.payload.contactEmail,
        website: action.payload.website,
        password: action.payload.password,
        confirmed_password: action.payload.confirmedPassword,
        ein: action.payload.ein,
        ssn: action.payload.ssn,
        reason_for_interest: action.payload.reasonForInterest,
        recaptchaToken: action.payload.recaptchaToken,
        source: SMB_APPLICATION_SOURCE.KYC_FORM,
    }

    // Remove keys that don't have a value
    Object.keys(postData).forEach(
        (k) => !postData[k] && postData[k] !== undefined && delete postData[k]
    )

    try {
        yield call(axios.request, {
            url: `/ajax/submit-small-business-registration/`,
            method: 'post',
            data: postData,
            withCredentials: true,
        })
        yield put(submitSMBFormResponse({}))
    } catch (e) {
        yield put(submitSMBFormResponse(e.response, '', true))
    }
}

export function* buyBulkSagas() {
    yield all([
        takeLatest(GET_SMB_STATUS, getSMBStatus),
        takeLatest(SUBMIT_SMB_FORM, submitSMBForm),
    ])
}

export default buyBulkSagas
