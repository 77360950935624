import {
    ADD_TO_CART_SUCCESS,
    CARD_SELECTED,
    CARD_TYPE_SELECTED,
    CLEAR_RESET_FORM,
    GET_GIFT_TEMPLATES_SUCCESS,
    GET_MERCHANT_CARDS_ERROR,
    GET_MERCHANT_CARDS_SUCCESS,
    GET_MORE_GIFT_TEMPLATES_SUCCESS,
    POST_ADDRESS_VERIFICATION_RESULT,
    POST_ADDRESS_VERIFICATION_ERROR,
    CLEAR_ADDRESS_RESULT,
    RESET_SELECTED_TYPE_CHANGED,
    ADD_TO_CART_ERROR,
    CLEAR_ADD_TO_CART_RESPONSE,
    RESET_CARD_LIST_UPDATED,
    GET_MERCHANT_CARDS_NO_INVENTORY,
    RESET_FORMS,
    UPDATE_QUANTITY,
    MULTIPLE_RECIPIENT_CHANGED,
    ADD_PLASTIC_RECIPIENT,
    ADD_EGIFT_RECIPIENT,
    UPDATE_PENDING_EGIFT_RECIPIENT,
    UPDATE_PLASTIC_RECIPIENTS,
    UPDATE_EGIFT_RECIPIENTS,
    UPDATE_PENDING_PLASTIC_RECIPIENT,
    UPDATE_PLASTIC_FORM_ERRORS,
    UPDATE_EGIFT_FORM_ERRORS,
    FOCUS_FORM_FIELD,
} from '../actions/buyCards'
import EGiftRecipient from '../components/Models/EGiftRecipient'
import PlasticRecipient from '../components/Models/PlasticRecipient'
import head from 'lodash/head'

const initialState = {
    cards: [],
    cardListUpdated: false,
    egiftCount: 0,
    plasticCount: 0,
    // TODO: update when GypGift UX is ready
    isGypMerchant: true,
    selectedType: '',
    selectedTypeChanged: false,
    selectedTypeChangedCount: 0,
    selectedCard: {},
    multipleRecipients: false,
    quantity: 1,
    cartResponse: null,
    cartError: null,
    giftTemplatePage: 1,
    giftTemplates: [],
    moreGiftTemplates: [],
    moreGiftTemplateTotalCount: 1,
    promotionLabels: [],
    getCardsError: false,
    noCardsAvailable: false,
    resetEGiftForm: false,
    resetPlasticForm: false,
    addressVerificationResult: null,
    addressVerificationError: null,
    egiftRecipients: [],
    pendingEGiftRecipient: new EGiftRecipient(),
    egiftErrors: {},
    plasticRecipients: [],
    pendingPlasticRecipient: new PlasticRecipient(),
    plasticErrors: {},
    focusedFieldId: null,
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MERCHANT_CARDS_SUCCESS:
            return {
                ...state,
                cards: action.data.onDemandCards,
                egiftCount: action.data.egift_count,
                plasticCount: action.data.plastic_count,
                promotionLabels: action.data.promotionLabels,
                cardListUpdated: true,
            }
        case GET_MERCHANT_CARDS_ERROR:
            return {
                ...state,
                getCardsError: action.data,
            }
        case GET_MERCHANT_CARDS_NO_INVENTORY:
            return {
                ...state,
                noCardsAvailable: true,
            }
        case CARD_TYPE_SELECTED:
            return {
                ...state,
                selectedType: action.cardType,
                selectedTypeChanged: true,
                selectedTypeChangedCount: state.selectedTypeChangedCount + 1,
                resetEGiftForm: true,
                resetPlasticForm: true,
                egiftErrors: {},
                plasticErrors: {},
            }
        case CARD_SELECTED:
            return {
                ...state,
                selectedCard: action.data,
            }
        case UPDATE_QUANTITY:
            return {
                ...state,
                quantity: action.quantity,
            }
        case ADD_TO_CART_SUCCESS:
            return {
                ...state,
                cartResponse: action.data,
                resetEGiftForm: true,
                resetPlasticForm: true,
                egiftErrors: {},
                plasticErrors: {},
                egiftRecipients: [],
                plasticRecipients: [],
            }
        case ADD_TO_CART_ERROR:
            return {
                ...state,
                cartError: action.data,
            }
        case CLEAR_ADD_TO_CART_RESPONSE:
            return {
                ...state,
                cartResponse: null,
                cartError: null,
            }
        case GET_GIFT_TEMPLATES_SUCCESS:
            return {
                ...state,
                giftTemplates: action.data,
            }
        case GET_MORE_GIFT_TEMPLATES_SUCCESS:
            return {
                ...state,
                giftTemplatePage: action.data.page,
                moreGiftTemplates: action.data.templates,
                moreGiftTemplateTotalCount: action.data.total_count,
            }
        case RESET_FORMS:
            return {
                ...state,
                quantity: 1,
                multipleRecipients: false,
                pendingEGiftRecipient: new EGiftRecipient(),
                egiftRecipients: [],
                pendingPlasticRecipient: new PlasticRecipient(),
                plasticRecipients: [],
            }
        case CLEAR_RESET_FORM:
            return {
                ...state,
                resetEGiftForm: false,
                resetPlasticForm: false,
            }
        case POST_ADDRESS_VERIFICATION_RESULT:
            return {
                ...state,
                addressVerificationResult: action.data,
            }
        case POST_ADDRESS_VERIFICATION_ERROR:
            return {
                ...state,
                addressVerificationError: action.data,
            }
        case CLEAR_ADDRESS_RESULT:
            return {
                ...state,
                addressVerificationResult: null,
                addressVerificationError: null,
            }
        case RESET_SELECTED_TYPE_CHANGED:
            return {
                ...state,
                selectedTypeChanged: false,
            }
        case RESET_CARD_LIST_UPDATED:
            return {
                ...state,
                cardListUpdated: false,
            }
        case MULTIPLE_RECIPIENT_CHANGED:
            let newState = {
                ...state,
                multipleRecipients: action.enabled,
            }

            if (!action.enabled) {
                newState.plasticErrors = {}
                newState.egiftErrors = {}

                if (state.egiftRecipients.length >= 1) {
                    newState.pendingEGiftRecipient = head(state.egiftRecipients)
                }

                if (state.plasticRecipients.length >= 1) {
                    newState.pendingPlasticRecipient = head(
                        state.plasticRecipients
                    )
                }

                newState.egiftRecipients = []
                newState.plasticRecipients = []
            }

            return newState
        case ADD_PLASTIC_RECIPIENT:
            return {
                ...state,
                pendingPlasticRecipient: new PlasticRecipient(),
                plasticRecipients: state.plasticRecipients.concat(
                    action.recipient
                ),
                plasticErrors: {},
            }
        case UPDATE_PLASTIC_RECIPIENTS:
            return {
                ...state,
                plasticRecipients: action.recipients,
            }
        case UPDATE_EGIFT_RECIPIENTS:
            return {
                ...state,
                egiftRecipients: action.recipients,
            }
        case ADD_EGIFT_RECIPIENT:
            return {
                ...state,
                pendingEGiftRecipient: new EGiftRecipient(),
                egiftRecipients: state.egiftRecipients.concat(action.recipient),
                egiftErrors: {},
            }
        case UPDATE_PENDING_EGIFT_RECIPIENT:
            return {
                ...state,
                pendingEGiftRecipient: action.recipient,
            }
        case UPDATE_PENDING_PLASTIC_RECIPIENT:
            return {
                ...state,
                pendingPlasticRecipient: action.recipient,
            }
        case UPDATE_PLASTIC_FORM_ERRORS:
            return {
                ...state,
                plasticErrors: action.errors,
            }
        case UPDATE_EGIFT_FORM_ERRORS:
            return {
                ...state,
                egiftErrors: action.errors,
            }
        case FOCUS_FORM_FIELD:
            return {
                ...state,
                focusedFieldId: action.fieldId,
            }
        default:
            return state
    }
}
