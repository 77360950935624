function PlasticRecipient() {
    this.shippingName = '';
    this.shippingLine1 = '';
    this.shippingLine2 = '';
    this.shippingCity = '';
    this.shippingState = '';
    this.shippingZip = '';
    this.shippingMethod = ''
}

export default PlasticRecipient;