import allSettled from 'promise.allsettled'
import {
    CONSUMER_CART_SOURCE,
    DELIVERY_STYLE,
    CARRIER_TYPE,
} from '../../constants'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import { v4 as uuidv4 } from 'uuid'
import { getIndividualPlasticCardsByShippingMethod } from '../../utility'

const createACardAddToCart = async (
    bulkDeliveryGroups,
    cards,
    setAddingToCart,
    resetForm,
    resetAddressGroupsCardListAndBulkDeliveryGroups,
    sendAddToCartRequest,
    addToCartCallback,
    handleCartError
) => {
    setAddingToCart(true)
    const individualVirtualDeliveryGroupUuid = uuidv4()
    const containsVirtualCards = cards.some((card) => card.isVirtual())
    const individualVirtualDeliveryGroup = containsVirtualCards
        ? {
              uuid: individualVirtualDeliveryGroupUuid,
              virtual: true,
              delivery_style: DELIVERY_STYLE.INDIVIDUAL_EMAIL,
          }
        : undefined

    // At most we'll only ever have as many individual plastic delivery groups as there are individual shipping methods available
    const createIndividualPlasticDeliveryGroupsAndCardsForAddToCart = (
        individualPlasticCardShippingMethodGroups
    ) => {
        return individualPlasticCardShippingMethodGroups.map(
            (shippingMethodGroup) => {
                const individualPlasticDeliveryGroupUuid = uuidv4()
                return {
                    individualDeliveryGroup: {
                        uuid: individualPlasticDeliveryGroupUuid,
                        virtual: false,
                        delivery_style: DELIVERY_STYLE.INDIVIDUAL_SHIP,
                        shipping_method_id: shippingMethodGroup.id,
                    },
                    cards: shippingMethodGroup.cards.map((card) => ({
                        group_uuid: individualPlasticDeliveryGroupUuid, // primarily to set group_uuid correctly
                        amount: card.amount,
                        card_network: card.cardNetwork.name,
                        quantity: 1,
                        name_on_card: card.nameOnCard,
                        message_on_card: card.messageOnCard,
                        carrier_to: card.personalizationInfo.carrierTo,
                        carrier_from: card.personalizationInfo.carrierFrom,
                        carrier_message:
                            card.personalizationInfo.carrierMessage,
                        carrier_type:
                            card.personalizationInfo.carrierType ===
                            CARRIER_TYPE.GREETING_CARD_HANDWRYTTEN
                                ? CARRIER_TYPE.GREETING_CARD_HANDWRYTTEN
                                : null,
                        shipping_name: card.deliveryInfo.shippingName,
                        address_line1: card.deliveryInfo.addressLine1,
                        address_line2: card.deliveryInfo.addressLine2,
                        city: card.deliveryInfo.city,
                        state: card.deliveryInfo.state,
                        zip: card.deliveryInfo.zip,
                        cloudinary_id: card.cloudinaryId ?? undefined,
                        open_loop_product_id:
                            card.design?.open_loop_product_id ?? undefined, // For predesigns - for plastics this is the id of the WcpProduct, for eGifts this is the id of the virtual WcpProduct for the card network
                        gift_template_id:
                            card.design?.gift_template_id ?? undefined, // For eGift predesigns only, the id of the gift template record
                        greeting_card:
                            (card.carrierOption ===
                                CARRIER_TYPE.GREETING_CARD ||
                                card.carrierOption ===
                                    CARRIER_TYPE.GREETING_CARD_HANDWRYTTEN) &&
                            !isEmpty(card.greetingCard)
                                ? card.greetingCard.slug
                                : undefined,
                    })),
                }
            }
        )
    }

    // Create 'x' number of individual plastic delivery groups from individual plastic card's (cards where no deliveryGroupUuid set)
    const individuallyShippedPlasticCards = cards.filter(
        (card) => !card.isVirtual() && !card.deliveryGroupUuid
    )
    // Get array of array of cards grouped by shippingMethod and create individual delivery groups for each
    const individualPlasticCardShippingMethodGroups =
        getIndividualPlasticCardsByShippingMethod(
            individuallyShippedPlasticCards
        )
    const individualPlasticDeliveryGroupsAndCardsForAddToCart =
        createIndividualPlasticDeliveryGroupsAndCardsForAddToCart(
            individualPlasticCardShippingMethodGroups
        )

    // Get all cards belonging to a bulk plastic or virtual individual delivery group
    const bulkPlasticAndIndividualVirtualCardsForCart = await cards
        .filter((card) => card.isVirtual() || card.deliveryGroupUuid)
        .map(async (card) => {
            if (card.isVirtual()) {
                return {
                    group_uuid: individualVirtualDeliveryGroupUuid,
                    virtual: true,
                    card_network: card.cardNetwork.name,
                    amount: card.amount,
                    quantity: 1,
                    recipient_name: card.personalizationInfo.carrierTo,
                    recipient_message: card.personalizationInfo.carrierMessage,
                    recipient_email: card.personalizationInfo.recipientEmail,
                    sender_name: card.personalizationInfo.carrierFrom,
                    cloudinary_id: card.cloudinaryId ?? undefined,
                    open_loop_product_id:
                        card.design?.open_loop_product_id ?? undefined, // For predesigns - for plastics this is the id of the WcpProduct, for eGifts this is the id of the virtual WcpProduct for the card network
                    gift_template_id:
                        card.design?.gift_template_id ?? undefined, // For eGift predesigns only, the id of the gift template record
                }
            } else {
                return {
                    group_uuid: card.deliveryGroupUuid, // bulk cards will already have a deliveryGroupUuid set from the GroupShipmentsModal
                    amount: card.amount,
                    card_network: card.cardNetwork.name,
                    quantity: 1,
                    name_on_card: card.nameOnCard,
                    message_on_card: card.messageOnCard,
                    carrier_to: card.personalizationInfo.carrierTo,
                    carrier_from: card.personalizationInfo.carrierFrom,
                    carrier_message: card.personalizationInfo.carrierMessage,
                    carrier_type:
                        card.personalizationInfo.carrierType ===
                        CARRIER_TYPE.GREETING_CARD_HANDWRYTTEN
                            ? CARRIER_TYPE.GREETING_CARD_HANDWRYTTEN
                            : null,
                    shipping_name: card.deliveryInfo.shippingName,
                    shipping_method: card.deliveryInfo.shippingMethod,
                    cloudinary_id: card.cloudinaryId ?? undefined,
                    open_loop_product_id:
                        card.design?.open_loop_product_id ?? undefined, // For predesigns - for plastics this is the id of the WcpProduct, for eGifts this is the id of the virtual WcpProduct for the card network
                    gift_template_id:
                        card.design?.gift_template_id ?? undefined, // For eGift predesigns only, the id of the gift template record
                    greeting_card:
                        (card.carrierOption === CARRIER_TYPE.GREETING_CARD ||
                            card.carrierOption ===
                                CARRIER_TYPE.GREETING_CARD_HANDWRYTTEN) &&
                        !isEmpty(card.greetingCard)
                            ? card.greetingCard.slug
                            : undefined,
                }
            }
        })

    allSettled.shim() // will be a no-op if not needed

    const uploadedItems = await Promise.allSettled([
        ...bulkPlasticAndIndividualVirtualCardsForCart,
        ...individualPlasticDeliveryGroupsAndCardsForAddToCart.flatMap(
            (i) => i.cards
        ),
    ])

    const validItems = uploadedItems
        .filter((item) => item.status === 'fulfilled')
        .map((item) => {
            return item.value
        })

    if (!validItems.length) {
        setAddingToCart(false)
        handleCartError(
            'Add to cart failed. Please try again or select a new card image.'
        )
    } else {
        const bulkDeliveryGroupsForAddToCart = bulkDeliveryGroups.map(
            (bulkDeliveryGroup) => {
                // add to cart payload isn't expecting addressGroupId as part of a bulk delivery group
                const { addressGroupId, ...restOfBulkDeliveryGroup } =
                    bulkDeliveryGroup
                return restOfBulkDeliveryGroup
            }
        )

        try {
            const payload = {
                source: CONSUMER_CART_SOURCE,
                delivery_groups: compact([
                    ...bulkDeliveryGroupsForAddToCart,
                    ...individualPlasticDeliveryGroupsAndCardsForAddToCart.map(
                        (i) => i.individualDeliveryGroup
                    ),
                    individualVirtualDeliveryGroup,
                ]),
                cards: validItems,
            }

            const addResponse = await sendAddToCartRequest(
                JSON.stringify(payload)
            )
            resetForm()
            resetAddressGroupsCardListAndBulkDeliveryGroups()
            addToCartCallback(addResponse)
        } catch (error) {
            setAddingToCart(false)
            handleCartError(error)
        }
    }
}

export default createACardAddToCart
