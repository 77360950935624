import React from 'react'
import { Helmet } from 'react-helmet'

const defaultMeta = {
    pageTitle: '',
    url: window.location.href || 'https://www.giftcardgranny.com/',
    pageDescription: '',
}

export default function ({ pageTitle, pageDescription }) {
    const title = String(pageTitle || defaultMeta.pageTitle).replace(
        /\| GiftCardGranny$/,
        ''
    )
    const description = pageDescription || defaultMeta.pageDescription

    return (
        <Helmet>
            {title && <title>{title} | GiftCardGranny</title>}
            <meta
                property="og:title"
                content={`${title} | GiftCardGranny`}
                data-react-helmet="true"
            />
            <meta
                name="twitter:title"
                content={`${title} | GiftCardGranny`}
                data-react-helmet="true"
            />

            <meta
                name="description"
                content={description}
                data-react-helmet="true"
            />
            <meta
                property="og:description"
                content={description}
                data-react-helmet="true"
            />
            <meta
                name="twitter:description"
                content={description}
                data-react-helmet="true"
            />

            <meta
                property="og:url"
                content={defaultMeta.url}
                data-react-helmet="true"
            />
            <link
                rel="canonical"
                href={defaultMeta.url}
                data-react-helmet="true"
            />
        </Helmet>
    )
}
