import {
    CAC_ADD_CUSTOM_CARDS,
    CAC_ADD_TO_CART_REQUEST,
    CAC_CLEAR_RESET_APP_REQUESTED,
    CAC_DUPLICATE_CUSTOM_CARD,
    CAC_EDIT_CARD_REQUESTED,
    CAC_PRESET_CUSTOM_CARD,
    CAC_REMOVE_CARD,
    CAC_REQUEST_NEXT_STEP,
    CAC_RESET_APP,
    CAC_RESET_APP_REQUESTED,
    CAC_RESET_CUSTOM_CARD,
    CAC_RESET_EDIT_CARD_REQUESTED,
    CAC_SET_ADDING_TO_CART,
    CAC_SET_AVAILABLE_CARD_NETWORKS,
    CAC_SET_CART_ERRORS,
    CAC_SET_CONFIG,
    CAC_UPDATE_CARD_PROPERTY,
    CAC_UPDATE_BULK_DELIVERY_GROUPS,
    CAC_RESET_ADDRESS_GROUPS_CARDS_AND_BULK_DELIVERY_GROUPS,
    CAC_SET_HAS_CONFIRMED_GROUP_SHIPMENTS,
    CAC_EDIT_CUSTOM_CARD,
    CAC_SET_NEXT_CARD,
    CAC_SET_CUSTOM_BUILD_A_CARD_DATA,
    CAC_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED,
    CAC_CLEAR_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED,
    CAC_BACK_FROM_REVIEW_STEP_REQUESTED,
    CAC_CLEAR_BACK_FROM_REVIEW_STEP_REQUESTED,
} from '../actions/createACard'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import set from 'lodash/set'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import { CARD_NETWORK, CARD_TYPE } from '../constants'
import { getCardNetwork } from '../utils/getCardNetwork'
import { v4 as uuidv4 } from 'uuid'
import { CarrierPersonalizationInfo } from '../components/CreateACard/Models/CarrierPersonalizationInfo'
import { CarrierDeliveryInfo } from '../components/CreateACard/Models/CarrierDeliveryInfo'
import { CustomBuildACard } from '../components/CreateACard/Models/CustomBuildACard'
import { BACCardData } from '../components/CreateACard/Models/BACCardData'
import { BACCarrierOptionData } from '../components/CreateACard/Models/BACCarrierOptionData'
import { BACGreetingCardData } from '../components/CreateACard/Models/BACGreetingCardData'
import { BACPersonalizationData } from '../components/CreateACard/Models/BACPersonalizationData'
import { BACDeliveryData } from '../components/CreateACard/Models/BACDeliveryData'

export const initialState = {
    baseConfig: {},
    baseUrl: '',
    siteName: '',
    faqUrl: '',
    carrierImageUrl: '',
    carrierImageWithCardUrl: '',
    imageGuidelinesUrl: '',
    includeShipping: true,
    availableCardNetworks: [],
    availableShippingMethods: [],
    defaultCardNetwork: {},
    defaultDeliveryMethod: '',
    defaultCardTypeIsVirtual: false,
    customCards: [],
    lastAddedCustomCards: [],
    activeCard: null,
    addingToCart: false,
    addToCartRequested: false,
    cartError: null,
    nextStepRequested: false,
    resetAppRequested: false,
    resetCards: false,
    editCardRequest: null,
    isEditing: false,
    editingCarrierStep: null,
    duplicateCardId: null,
    hasConfirmedGroupShipments: false,
    addressGroups: [],
    bulkDeliveryGroups: [],
    cards: [],
    canAddCards: true,
    maxQuantity: 20,
    addressVerificationEnabled: false,
    discardItemAndReturnToReviewRequested: false,
    backFromReviewStepRequested: false,
}

/**
 * Used to convert the json stored in session storage for redux back to a
 * CustomBuildACard object.
 * @param data
 * @returns {null|CustomBuildACard}
 */
const initializeCustomBuildACardFromJson = (data) => {
    if (!isNull(data)) {
        const activeCard = new CustomBuildACard()
        activeCard.initializeFromJson(data)
        return activeCard
    }
    return null
}

/**
 * Used to convert the json array of cards in session storage for redux back to
 * a collection of CustomBuildACard objects.
 * @param data
 * @returns {*[CustomBuildACard]}
 */
const initializeCustomCardsFromJson = (data) => {
    let customCards = []

    data.forEach((cardJson) => {
        const activeCard = new CustomBuildACard()
        activeCard.initializeFromJson(cardJson)
        customCards = [...customCards, activeCard]
    })
    return customCards
}

const initializeAddressGroupsFromJson = (data) => {
    let addressGroups = []

    data.forEach((addressGroupJson) => {
        const group = Object.assign(
            { ...addressGroupJson },
            { cards: initializeCustomCardsFromJson(addressGroupJson.cards) }
        )
        addressGroups = [...addressGroups, group]
    })
    return addressGroups
}

export default function createACardReducer(state = initialState, action) {
    const activeCardIndex = state.customCards.findIndex((card) => {
        return card.cardId === state.activeCard.cardId
    })

    switch (action.type) {
        case 'persist/REHYDRATE':
            if (action.payload) {
                const {
                    activeCard: activeCardData,
                    customCards: customCardsData,
                    cards: cardsData,
                    addressGroups: addressGroupsData,
                } = action.payload.createACard

                return {
                    ...state,
                    ...action.payload.createACard,
                    activeCard:
                        initializeCustomBuildACardFromJson(activeCardData),
                    customCards: initializeCustomCardsFromJson(customCardsData),
                    cards: initializeCustomCardsFromJson(cardsData),
                    addressGroups:
                        initializeAddressGroupsFromJson(addressGroupsData),
                }
            }
            return {
                ...state,
            }
        case CAC_SET_CONFIG:
            return {
                ...state,
                baseConfig: action.payload.baseConfig,
                baseUrl: action.payload.baseUrl,
                siteName: action.payload.siteName,
                faqUrl: action.payload.faqUrl,
                carrierImageUrl: action.payload.carrierImageUrl,
                carrierImageWithCardUrl: action.payload.carrierImageWithCardUrl,
                imageGuidelinesUrl: action.payload.imageGuidelinesUrl,
                includeShipping: action.payload.includeShipping,
                availableShippingMethods:
                    action.payload.availableShippingMethods,
                addressVerificationEnabled:
                    action.payload.addressVerificationEnabled,
            }
        case CAC_SET_AVAILABLE_CARD_NETWORKS:
            const defaultCardNetwork = getDefaultCardNetwork(action.payload)
            const defaultDeliveryMethod =
                getDefaultDeliveryMethod(defaultCardNetwork)

            return {
                ...state,
                availableCardNetworks: action.payload,
                defaultCardNetwork: defaultCardNetwork,
                defaultDeliveryMethod: defaultDeliveryMethod,
                defaultCardTypeIsVirtual:
                    defaultDeliveryMethod === CARD_TYPE.VIRTUAL,
            }
        case CAC_UPDATE_CARD_PROPERTY:
            const cardId = get(action, 'payload.cardId')
            const propertyName = get(action, 'payload.path')
            const value = get(action, 'payload.value')
            set(find(state.cards, { cardId }), propertyName, value)

            return {
                ...state,
            }
        case CAC_EDIT_CARD_REQUESTED:
            return {
                ...state,
                editCardRequest: {
                    cardId: action.payload.cardId,
                    step: action.payload.step,
                },
                editingCarrierStep: action.payload.carrierStep,
                isEditing: true,
            }
        case CAC_RESET_EDIT_CARD_REQUESTED:
            return {
                ...state,
                editCardRequest: null,
            }
        case CAC_REMOVE_CARD:
            const cardToRemoveFromAddressGroup = state.cards.find(
                (c) => c.cardId === action.payload
            )
            const updatedCards = state.cards.filter(
                (c) => c.cardId !== action.payload
            )

            let updatedAddressGroups = [...state.addressGroups]
            let updatedBulkDeliveryGroups = [...state.bulkDeliveryGroups]
            if (
                cardToRemoveFromAddressGroup &&
                !cardToRemoveFromAddressGroup.isVirtual()
            ) {
                updatedAddressGroups = state.addressGroups.map(
                    (addressGroup) => {
                        // Remove plastic card from any potential addressGroups
                        const updatedAddressGroupCards =
                            addressGroup.cards.filter(
                                (c) =>
                                    c.cardId !==
                                    cardToRemoveFromAddressGroup.cardId
                            )

                        if (updatedAddressGroupCards.length === 0) {
                            // Remove addressGroup and potential bulkDeliveryGroup
                            // associated with it
                            updatedBulkDeliveryGroups =
                                state.bulkDeliveryGroups.filter(
                                    (b) => b.addressGroupId !== addressGroup.id
                                )

                            return undefined
                        } else if (updatedAddressGroupCards.length === 1) {
                            // Remove bulk delivery group if there's only one
                            // card left in the address group
                            updatedBulkDeliveryGroups =
                                state.bulkDeliveryGroups.filter(
                                    (b) => b.addressGroupId !== addressGroup.id
                                )
                            // Remove bulk delivery group uuid from card since
                            // we removed it and there's only one card left
                            updatedAddressGroupCards[0].deliveryGroupUuid = null
                            updatedAddressGroupCards[0].groupShipment = null
                        }

                        return {
                            ...addressGroup,
                            hasGroupedCards: false,
                            cards: updatedAddressGroupCards,
                        }
                    }
                )
            } else {
                updatedAddressGroups = [...state.addressGroups]
            }

            return {
                ...state,
                cards: updatedCards,
                addressGroups: compact(updatedAddressGroups),
                bulkDeliveryGroups: updatedBulkDeliveryGroups,
            }
        case CAC_RESET_CUSTOM_CARD:
            return {
                ...state,
                activeCard: null,
                customCards: [],
                cartError: null,
            }
        case CAC_RESET_APP:
            return {
                ...state,
                hasConfirmedGroupShipments: false,
                addressGroups: [],
                bulkDeliveryGroups: [],
                cards: [],
                cartError: null,
                canAddCards: true,
                activeCard: null,
                customCards: [],
                isEditing: false,
            }
        case CAC_RESET_APP_REQUESTED:
            return {
                ...state,
                resetAppRequested: true,
                clearCards: action.payload,
                addingToCart: false,
            }
        case CAC_CLEAR_RESET_APP_REQUESTED:
            return {
                ...state,
                resetAppRequested: false,
            }
        case CAC_REQUEST_NEXT_STEP:
            return {
                ...state,
                nextStepRequested: action.payload,
            }
        case CAC_SET_CART_ERRORS:
            return {
                ...state,
                cartError: action.payload,
                addingToCart: false,
            }
        case CAC_SET_ADDING_TO_CART:
            return {
                ...state,
                addingToCart: action.payload,
            }
        case CAC_ADD_TO_CART_REQUEST:
            return {
                ...state,
                addToCartRequested: action.payload,
            }
        case CAC_ADD_CUSTOM_CARDS:
            const newCardsToAdd = action.payload

            const {
                updatedCards: cards,
                addressGroups,
                bulkDeliveryGroups,
            } = getUpdatedCardsAddressGroupsAndBulkDeliveryGroupsFromNewCards(
                state.cards,
                newCardsToAdd,
                state.addressGroups,
                state.bulkDeliveryGroups
            )

            const newCardsContainPlastics = newCardsToAdd.some(
                (card) => !card.isVirtual()
            )

            return {
                ...state,
                // Reset hasConfirmedGroupShipments when it was previously set and any new plastic card are added
                hasConfirmedGroupShipments: !newCardsContainPlastics,
                addressGroups,
                bulkDeliveryGroups,
                cards,
                activeCard: null,
                customCards: [],
            }
        case CAC_EDIT_CUSTOM_CARD:
            // Accepts originalCard and newEditedCards (could potentially have many cards if user upped quantity via card details)
            const newEditedCard = action.payload
            const cardWasConvertedToVirtual =
                newEditedCard.isVirtual() &&
                !isNull(newEditedCard.addressGroupId)
            let currentBulkDeliveryGroups = state.bulkDeliveryGroups
            let currentCards = state.cards
            let currentAddressGroups = state.addressGroups

            // Filter out originalCard from createACard.cards
            currentCards = currentCards.filter(
                (c) => c.cardId !== newEditedCard.cardId
            )

            // originalCard belonged to a bulkDeliveryGroup, remove it
            currentBulkDeliveryGroups = currentBulkDeliveryGroups.filter(
                (b) => b.uuid !== newEditedCard.deliveryGroupUuid
            )

            currentCards = currentCards.map((c) => {
                // Reset any cards' deliveryGroupUuid that belonged to originalCard's bulkDeliveryGroup
                if (
                    c.deliveryGroupUuid &&
                    c.deliveryGroupUuid === newEditedCard.deliveryGroupUuid
                ) {
                    c.deliveryGroupUuid = null
                    c.groupShipment = null
                    return c
                }
                return c
            })

            currentAddressGroups = currentAddressGroups.map((addressGroup) => {
                // Filter out originalCard from any addressGroup it belonged to
                const addressGroupCardsWithoutOriginalCard =
                    addressGroup.cards.filter(
                        (c) => c.cardId !== newEditedCard.cardId
                    )

                if (addressGroupCardsWithoutOriginalCard.length === 0) {
                    return undefined
                }

                return {
                    ...addressGroup,
                    hasGroupedCards: false,
                    cards: addressGroupCardsWithoutOriginalCard.map((c) => {
                        // Reset other cards in addressGroups' deliveryGroupUuid, will be prompted to group shipment again
                        if (
                            c.deliveryGroupUuid &&
                            c.deliveryGroupUuid ===
                                newEditedCard.deliveryGroupUuid
                        ) {
                            c.deliveryGroupUuid = null
                            c.groupShipment = null
                            return c
                        }
                        return c
                    }),
                }
            })

            // Reset editing card delivery group data
            newEditedCard.deliveryGroupUuid = null
            newEditedCard.groupShipment = null
            newEditedCard.addressGroupId = null

            const {
                updatedCards: editedCustomCards,
                addressGroups: editedAddressGroups,
                bulkDeliveryGroups: editedBulkDeliveryGroups,
            } = getUpdatedCardsAddressGroupsAndBulkDeliveryGroupsFromNewCards(
                currentCards,
                [newEditedCard],
                compact(currentAddressGroups),
                currentBulkDeliveryGroups
            )

            const resetHasConfirmedGroupShipments =
                !newEditedCard.isVirtual() || cardWasConvertedToVirtual

            return {
                ...state,
                // Reset hasConfirmedGroupShipments when a plastic card was edited
                // or a plastic card was changed to a virtual card
                hasConfirmedGroupShipments: resetHasConfirmedGroupShipments
                    ? false
                    : state.hasConfirmedGroupShipments,
                addressGroups: editedAddressGroups,
                bulkDeliveryGroups: editedBulkDeliveryGroups,
                cards: editedCustomCards,
                activeCard: null,
                isEditing: false,
            }
        case CAC_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED:
            return {
                ...state,
                customCards: [],
                discardItemAndReturnToReviewRequested: true,
            }
        case CAC_CLEAR_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED:
            return {
                ...state,
                discardItemAndReturnToReviewRequested: false,
            }
        case CAC_PRESET_CUSTOM_CARD:
            return {
                ...state,
                activeCard: action.payload,
            }
        case CAC_DUPLICATE_CUSTOM_CARD:
            return {
                ...state,
                duplicateCardId: action.payload,
            }
        case CAC_UPDATE_BULK_DELIVERY_GROUPS:
            const { newBulkDeliveryGroup, addressGroupId, cardsToUpdate } =
                action.payload

            const existingBulkDeliveryGroup = state.bulkDeliveryGroups.find(
                (bulkDeliveryGroup) =>
                    bulkDeliveryGroup.addressGroupId === addressGroupId
            )

            if (existingBulkDeliveryGroup) {
                if (
                    existingBulkDeliveryGroup.shipping_name !==
                        newBulkDeliveryGroup.shipping_name ||
                    existingBulkDeliveryGroup.shipping_method_id !==
                        newBulkDeliveryGroup.shipping_method_id
                ) {
                    // Update existing bulkDeliveryGroup with new shipping_name or shipping_method_id
                    let updatedBulkDeliveryGroup = {
                        ...existingBulkDeliveryGroup,
                        shipping_name: newBulkDeliveryGroup.shipping_name,
                        shipping_method_id:
                            newBulkDeliveryGroup.shipping_method_id,
                    }
                    return {
                        ...state,
                        bulkDeliveryGroups: state.bulkDeliveryGroups.map(
                            (b) => {
                                if (b.addressGroupId === addressGroupId) {
                                    return {
                                        ...b,
                                        shipping_name:
                                            updatedBulkDeliveryGroup.shipping_name,
                                        shipping_method_id:
                                            updatedBulkDeliveryGroup.shipping_method_id,
                                    }
                                }
                                return b
                            }
                        ),
                        cards: state.cards.map((c) => {
                            if (
                                cardsToUpdate.find(
                                    (cardToUpdate) =>
                                        c.cardId === cardToUpdate.cardId
                                )
                            ) {
                                c.deliveryInfo.shippingName =
                                    newBulkDeliveryGroup.shipping_name
                                return c
                            }
                            return c
                        }),
                        addressGroups: state.addressGroups.map(
                            (addressGroup) => {
                                return {
                                    ...addressGroup,
                                    cards: addressGroup.cards
                                        .filter((card) => !card.isVirtual())
                                        .map((c) => {
                                            if (
                                                cardsToUpdate.find(
                                                    (cardToUpdate) =>
                                                        c.cardId ===
                                                        cardToUpdate.cardId
                                                )
                                            ) {
                                                // Keep addressGroup cards in sync with cards state
                                                c.deliveryInfo.shippingName =
                                                    newBulkDeliveryGroup.shipping_name
                                                return c
                                            }
                                            return c
                                        }),
                                }
                            }
                        ),
                    }
                } else {
                    // Skip creating duplicate bulkDeliveryGroup
                    return { ...state }
                }
            }

            // Create new bulkDeliveryGroup
            return {
                ...state,
                bulkDeliveryGroups: [
                    ...state.bulkDeliveryGroups,
                    newBulkDeliveryGroup,
                ],
                cards: state.cards.map((c) => {
                    if (
                        cardsToUpdate.find(
                            (cardToUpdate) => c.cardId === cardToUpdate.cardId
                        )
                    ) {
                        c.deliveryInfo.shippingName =
                            newBulkDeliveryGroup.shipping_name
                        c.deliveryGroupUuid = newBulkDeliveryGroup.uuid
                        return c
                    }
                    return c
                }),
                addressGroups: state.addressGroups.map((addressGroup) => {
                    return {
                        ...addressGroup,
                        hasGroupedCards:
                            addressGroupId === addressGroup.id // set hasGroupedCards true for particular addressGroup bulk delivery group was created for
                                ? true
                                : addressGroup.hasGroupedCards,
                        cards: addressGroup.cards.map((c) => {
                            if (
                                cardsToUpdate.find(
                                    (cardToUpdate) =>
                                        c.cardId === cardToUpdate.cardId
                                )
                            ) {
                                // Keep addressGroup cards in sync with cards state
                                c.deliveryInfo.shippingName =
                                    newBulkDeliveryGroup.shipping_name
                                c.deliveryGroupUuid = newBulkDeliveryGroup.uuid
                                return c
                            }
                            return c
                        }),
                    }
                }),
            }
        case CAC_RESET_ADDRESS_GROUPS_CARDS_AND_BULK_DELIVERY_GROUPS:
            if (!action.payload) {
                return {
                    ...state,
                    hasConfirmedGroupShipments: false,
                    addressGroups: [],
                    bulkDeliveryGroups: [],
                    cards: [],
                }
            }

            const {
                addressGroupId: addressGroupIdToReset,
                cardsToUpdate: cardsToReset,
            } = action.payload

            return {
                ...state,
                bulkDeliveryGroups: state.bulkDeliveryGroups.filter(
                    (b) => b.addressGroupId !== addressGroupIdToReset
                ),
                cards: state.cards.map((c) => {
                    if (
                        cardsToReset.find(
                            (cardToReset) => c.cardId === cardToReset.cardId
                        )
                    ) {
                        c.deliveryGroupUuid = null
                        c.groupShipment = null
                        return c
                    }
                    return c
                }),
                addressGroups: state.addressGroups.map((addressGroup) => {
                    return {
                        ...addressGroup,
                        hasGroupedCards:
                            addressGroup.id === addressGroupIdToReset // ensure hasGroupedCards false for particular addressGroup
                                ? false
                                : addressGroup.hasGroupedCards,
                        cards: addressGroup.cards.map((c) => {
                            if (
                                cardsToReset.find(
                                    (cardToReset) =>
                                        c.cardId === cardToReset.cardId
                                )
                            ) {
                                c.deliveryGroupUuid = null
                                c.groupShipment = null
                                return c
                            }
                            return c
                        }),
                    }
                }),
            }
        case CAC_SET_HAS_CONFIRMED_GROUP_SHIPMENTS:
            return {
                ...state,
                hasConfirmedGroupShipments: action.payload,
            }
        case CAC_SET_NEXT_CARD:
            return {
                ...state,
                activeCard: action.payload.nextCard,
            }
        case CAC_SET_CUSTOM_BUILD_A_CARD_DATA:
            const buildACardData = action.payload

            if (state.isEditing) {
                const editingCardIndex = state.cards.findIndex((card) => {
                    return card.cardId === state.activeCard.cardId
                })

                const updatedCards = setCustomBuildACardData(
                    editingCardIndex,
                    state.activeCard,
                    buildACardData,
                    state.cards
                )

                return {
                    ...state,
                    cards: updatedCards,
                    activeCard: updatedCards[editingCardIndex],
                }
            }

            // If customCards is empty, we're adding new cards
            if (isEmpty(state.customCards)) {
                const newCards = addNewCards(buildACardData)
                return {
                    ...state,
                    customCards: newCards,
                    lastAddedCustomCards: newCards,
                    activeCard: newCards[0], // Set the first card added as the current editing card
                }
            } else {
                // Otherwise, we're updating cards
                const updatedCustomCards = setCustomBuildACardData(
                    activeCardIndex,
                    state.activeCard,
                    buildACardData,
                    state.customCards
                )

                return {
                    ...state,
                    customCards: updatedCustomCards,
                    lastAddedCustomCards: updatedCustomCards,
                    activeCard: updatedCustomCards[activeCardIndex],
                }
            }
        case CAC_BACK_FROM_REVIEW_STEP_REQUESTED:
            const customCardsFromLastAdded = initializeCustomCardsFromJson(
                state.lastAddedCustomCards
            )
            const tempActiveCard = customCardsFromLastAdded[0]
            tempActiveCard.setWantToBackAfterReviewStep(true)
            const customCardsFromLastAddedCardIds =
                state.lastAddedCustomCards.map((card) => card.cardId)
            return {
                ...state,
                activeCard: tempActiveCard,
                customCards: customCardsFromLastAdded,
                cards: state.cards.filter(
                    (card) =>
                        !customCardsFromLastAddedCardIds.includes(card.cardId)
                ),
                backFromReviewStepRequested: true,
            }
        case CAC_CLEAR_BACK_FROM_REVIEW_STEP_REQUESTED:
            return {
                ...state,
                backFromReviewStepRequested: false,
            }
        default:
            return state
    }
}

const addNewCards = (buildACardData) => {
    let newCards = []

    for (let i = 0; i < buildACardData.quantity; i++) {
        let card = new CustomBuildACard(
            buildACardData.defaultCardNetwork,
            buildACardData.defaultDeliveryMethod,
            buildACardData.defaultAmount
        )

        if (i === 0 || buildACardData.applyCardImageToAll) {
            card.cloudinaryId = buildACardData.cloudinaryId
            card.design = buildACardData.design
        }

        if (i === 0 || buildACardData.applyCardDetailsToAll) {
            card.cardNetwork = buildACardData.cardNetwork
            card.deliveryMethod = buildACardData.deliveryMethod
            card.amount = buildACardData.amount
            card.nameOnCard = buildACardData.nameOnCard
            card.messageOnCard = buildACardData.messageOnCard
        }

        newCards = [...newCards, card]
    }
    return newCards
}

const updateCardData = (activeCard, card, buildACardData) => {
    let updatedCard = card

    if (
        activeCard.cardId === card.cardId ||
        buildACardData.applyCardDetailsToAll === true
    ) {
        updatedCard.cardNetwork = buildACardData.cardNetwork
        updatedCard.deliveryMethod = buildACardData.deliveryMethod
        updatedCard.amount = buildACardData.amount
        updatedCard.nameOnCard = buildACardData.nameOnCard
        updatedCard.messageOnCard = buildACardData.messageOnCard
    }

    if (
        activeCard.cardId === card.cardId ||
        buildACardData.applyCardImageToAll === true
    ) {
        updatedCard.cloudinaryId = buildACardData.cloudinaryId
        updatedCard.design = buildACardData.design
    }
    return updatedCard
}

const updateCarrierOption = (activeCard, card, carrierOptionData) => {
    let updatedCard = card

    if (
        activeCard.cardId === card.cardId ||
        carrierOptionData.applyToAll === true
    ) {
        updatedCard.carrierOption = carrierOptionData.carrierOption
    }
    return updatedCard
}

const updateGreetingCard = (activeCard, card, greetingCardData) => {
    let updatedCard = card

    if (
        activeCard.cardId === card.cardId ||
        greetingCardData.applyToAll === true
    ) {
        updatedCard.greetingCard = greetingCardData.greetingCard
    }
    return updatedCard
}

const updatePersonalization = (activeCard, card, personalizationData) => {
    let updatedCard = card

    const personalizationInfo = new CarrierPersonalizationInfo(
        personalizationData.carrierTo,
        personalizationData.carrierMessage,
        personalizationData.carrierFrom,
        personalizationData.carrierType,
        personalizationData.recipientEmail
    )

    if (
        activeCard.cardId === card.cardId ||
        personalizationData.applyToAll === true
    ) {
        updatedCard.personalizationInfo = personalizationInfo
    }
    return updatedCard
}

const updateDeliveryInfo = (activeCard, card, deliveryData) => {
    let updatedCard = card

    const deliveryInfo = new CarrierDeliveryInfo(
        deliveryData.shippingName,
        deliveryData.addressLine1,
        deliveryData.addressLine2,
        deliveryData.city,
        deliveryData.state,
        deliveryData.zip,
        deliveryData.shippingMethod
    )

    if (activeCard.cardId === card.cardId || deliveryData.applyToAll === true) {
        updatedCard.deliveryInfo = deliveryInfo
    }
    return updatedCard
}

const setCustomBuildACardData = (
    activeCardIndex,
    activeCard,
    buildACardData,
    customCards
) => {
    let updatedCustomCards = [...customCards]

    for (let i = activeCardIndex; i < customCards.length; i++) {
        let card = customCards[i]

        if (buildACardData instanceof BACCardData) {
            card = updateCardData(activeCard, card, buildACardData)
        } else if (buildACardData instanceof BACCarrierOptionData) {
            card = updateCarrierOption(activeCard, card, buildACardData)
        } else if (buildACardData instanceof BACGreetingCardData) {
            card = updateGreetingCard(activeCard, card, buildACardData)
        } else if (buildACardData instanceof BACPersonalizationData) {
            card = updatePersonalization(activeCard, card, buildACardData)
        } else if (buildACardData instanceof BACDeliveryData) {
            card = updateDeliveryInfo(activeCard, card, buildACardData)
        }

        updatedCustomCards[i] = card
    }
    return updatedCustomCards
}

/**
 * Default the card network to Visa if it exists
 * @param cardNetworks
 * @returns {null|*}
 */
const getDefaultCardNetwork = (cardNetworks) => {
    if (isEmpty(cardNetworks)) {
        return null
    }

    return getCardNetwork(cardNetworks, CARD_NETWORK.VISA) ?? cardNetworks[0]
}

/**
 * Default the delivery method to mail if it exists
 * @param cardNetwork
 * @returns {null|*}
 */
const getDefaultDeliveryMethod = (cardNetwork) => {
    if (isEmpty(cardNetwork) || isEmpty(cardNetwork.deliveryMethods)) {
        return null
    }

    const index = cardNetwork.deliveryMethods.indexOf(CARD_TYPE.PLASTIC)

    if (index !== -1) {
        return cardNetwork.deliveryMethods[index]
    } else {
        return cardNetwork.deliveryMethods[0]
    }
}

/*
    This is all the update state logic for when a user adds a new custom card. With the addition of grouped shipments,
    keeping createACard store's cards, addressGroups' cards & bulkDeliveryGroups state in sync is important.
    1. If the new card(s) address doesn’t match an existing address group’s address, we create a new address group, add
    those cards as part of that address group and update createACard.cards state to be in sync with those cards.
    2. If the new card(s) address matches an existing address group’s address, we add those cards as part of that
    addressGroup and reset the decision on that address group (the user will be prompted to confirm grouping them again
    if they already had since they could have added a new shipping name or no longer want to group the old cards).
    Part of ‘resetting the decision’ for an address group involves removing any bulkDeliveryGroups created for it.
    bulkDeliveryGroups only get created if a user selects to group the cards from the GroupShipmentsModal.
 */
const getUpdatedCardsAddressGroupsAndBulkDeliveryGroupsFromNewCards = (
    currentCards,
    newCards,
    currentAddressGroups,
    currentBulkDeliveryGroups
) => {
    let newCardsToAdd = []
    const virtualCards = newCards.filter((card) => card.isVirtual())
    const plasticCards = newCards.filter((card) => !card.isVirtual())
    let updatedCards = currentCards
    let bulkDeliveryGroups = currentBulkDeliveryGroups
    let updatedAddressGroups

    // Virtual cards don't need address groups, add them to the updatedCards collection
    if (!isEmpty(virtualCards)) {
        newCardsToAdd = newCardsToAdd.concat(virtualCards)
    }

    // No new plastic cards were added
    if (isEmpty(plasticCards)) {
        return {
            updatedCards: currentCards.concat(newCardsToAdd),
            addressGroups: [...currentAddressGroups],
            bulkDeliveryGroups: [...currentBulkDeliveryGroups],
        }
    }

    newCardsToAdd = newCardsToAdd.concat(plasticCards)

    let cardsWithMatchingAddressesMatrix = []
    let remainingCardsToCheck = [...plasticCards]

    // Group all cards with matching addresses
    while (!isEmpty(remainingCardsToCheck)) {
        let currentCard = remainingCardsToCheck.pop()
        let cardsToGroup = [currentCard]

        // Add cards to temp group array when addresses match
        remainingCardsToCheck.forEach((card) => {
            if (cardAddressesAreEqual(currentCard, card)) {
                cardsToGroup = cardsToGroup.concat(card)
            }
        })

        // Filter out any cards that are grouped
        remainingCardsToCheck = remainingCardsToCheck.filter((card) => {
            return !cardsToGroup.includes(card)
        })

        // Add the new card group to the matrix
        cardsWithMatchingAddressesMatrix =
            cardsWithMatchingAddressesMatrix.concat([cardsToGroup])
    }

    updatedAddressGroups = currentAddressGroups.map((addressGroup) => {
        // Compare the address of the first card in the new cards group with
        // the address of the first card of the existing address group to determine
        // if there's an existing address group match
        let matchingNewGroupedCards = cardsWithMatchingAddressesMatrix.find(
            (groupedCards) => {
                return cardAddressesAreEqual(
                    groupedCards[0],
                    addressGroup.cards[0]
                )
            }
        )

        if (!isEmpty(matchingNewGroupedCards)) {
            // Remove match from matrix so it a duplicate delivery group isn't created
            cardsWithMatchingAddressesMatrix =
                cardsWithMatchingAddressesMatrix.filter((groupedCards) => {
                    return !cardAddressesAreEqual(
                        groupedCards[0],
                        addressGroup.cards[0]
                    )
                })

            // Remove existing delivery group for the address group we're modifying
            bulkDeliveryGroups = bulkDeliveryGroups.filter(
                (bulkDeliveryGroup) => {
                    return (
                        bulkDeliveryGroup.uuid !==
                        addressGroup.cards[0].deliveryGroupUuid
                    )
                }
            )

            // Remove any cards that have been converted to virtual
            const resetAddressGroupCards = addressGroup.cards.filter((card) => {
                return !card.isVirtual()
            })
            resetAddressGroupCards.forEach((card) => {
                card.deliveryGroupUuid = null
                card.groupShipment = null
            })

            // Reset deliveryGroupUuid on the OLD cards associated with this addressGroup
            updatedCards.forEach((card) => {
                if (card.addressGroupId === addressGroup.id) {
                    card.deliveryGroupUuid = null
                    card.groupShipment = null
                }
            })

            // Add addressGroup.id as NEW cards's addressGroupId
            matchingNewGroupedCards.forEach((card) => {
                card.addressGroupId = addressGroup.id
            })

            return {
                id: addressGroup.id,
                hasGroupedCards: false,
                cards: [...resetAddressGroupCards, ...matchingNewGroupedCards],
            }
        } else {
            return addressGroup
        }
    })

    let newAddressGroups = []

    // Create new address groups for remaining grouped cards
    cardsWithMatchingAddressesMatrix.forEach((groupedCards) => {
        const newAddressGroupId = uuidv4()

        groupedCards.forEach((card) => {
            card.addressGroupId = newAddressGroupId
        })

        // Create new address group and add new cards with correct
        // addressGroupId to its cards
        const addressGroup = {
            id: newAddressGroupId,
            hasGroupedCards: false,
            cards: [...groupedCards],
        }

        newAddressGroups = newAddressGroups.concat(addressGroup)
    })

    return {
        updatedCards: [...updatedCards, ...newCardsToAdd],
        addressGroups: updatedAddressGroups.concat(newAddressGroups),
        bulkDeliveryGroups: [...bulkDeliveryGroups],
    }
}

const cardAddressesAreEqual = (card, otherCard) => {
    return (
        card.deliveryInfo.addressLine1.toLowerCase() ===
            otherCard.deliveryInfo.addressLine1.toLowerCase() &&
        card.deliveryInfo.addressLine2.toLowerCase() ===
            otherCard.deliveryInfo.addressLine2.toLowerCase() &&
        card.deliveryInfo.city.toLowerCase() ===
            otherCard.deliveryInfo.city.toLowerCase() &&
        card.deliveryInfo.state.toLowerCase() ===
            otherCard.deliveryInfo.state.toLowerCase() &&
        card.deliveryInfo.zip === otherCard.deliveryInfo.zip
    )
}
