import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    circle: {
        color: 'rgb(89,32,99)',
    },
}))

const GcgCircularProgress = () => {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <CircularProgress
                className={classes.circle}
                disableShrink
                data-testid="loader"
            />
        </div>
    )
}

export default GcgCircularProgress
