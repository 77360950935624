import { SET_CART_DRAWER_OPEN } from '../actions/cartDrawer'

const initialState = {
    cartDrawerOpen: false,
}

export default function cartDrawerReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CART_DRAWER_OPEN:
            return {
                ...state,
                cartDrawerOpen: Boolean(action.isOpen),
            }
        default:
            return state
    }
}
