import {
    GET_MERCHANT_CHUNK_REQUEST,
    GET_MERCHANT_CHUNK_RESPONSE,
    GET_NEXT_MERCHANT_CHUNK,
    SET_FILTERED_MERCHANTS,
    SET_MERCHANT_LIST,
} from '../actions/balanceCheck'
import indexOf from 'lodash/indexOf'
import {
    balanceCheckMerchantChunks,
    FILTERED_MERCHANT_CHUNK_REQUEST,
} from '../constants'

export const initialState = {
    merchantList: [],
    filteredMerchants: [],
    loadingMerchantChunk: false,
    merchantChunkError: null,
    currentMerchantChunk: null,
}

export default function balanceCheckReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MERCHANT_CHUNK_REQUEST:
            return { ...state, loadingMerchantChunk: true }
        case GET_MERCHANT_CHUNK_RESPONSE:
            const { merchants } = { ...action.payload.data }
            const { requestType, requestParam: letterToFilterBy } = {
                ...action.meta,
            }
            if (action.error) {
                return {
                    ...state,
                    loadingMerchantChunk: false,
                    merchantChunkError: action.payload,
                }
            }
            if (requestType === FILTERED_MERCHANT_CHUNK_REQUEST) {
                return {
                    ...state,
                    merchantList: [...state.merchantList, ...merchants],
                    filteredMerchants: merchants?.filter(
                        (merchant) => merchant.name[0] === letterToFilterBy
                    ),
                    loadingMerchantChunk: false,
                    merchantChunkError: null,
                }
            }
            return {
                ...state,
                merchantList: [
                    ...state.merchantList,
                    ...action.payload.data.merchants,
                ],
                loadingMerchantChunk: false,
                merchantChunkError: null,
            }
        case GET_NEXT_MERCHANT_CHUNK:
            return {
                ...state,
                currentMerchantChunk:
                    balanceCheckMerchantChunks[
                        indexOf(
                            balanceCheckMerchantChunks,
                            state.currentMerchantChunk
                        ) + 1
                    ],
            }
        case SET_FILTERED_MERCHANTS:
            return {
                ...state,
                filteredMerchants: action.payload,
            }
        case SET_MERCHANT_LIST:
            return {
                ...state,
                merchantList: [...state.merchantList, ...action.payload],
            }
        default:
            return state
    }
}
