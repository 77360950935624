import {
    put, takeLatest, all, call
} from 'redux-saga/effects'
import axios from 'axios';
import {GET_ORDER_STATUS, getOrderStatus} from "../actions/orderStatus";

function* handleFetchOrderStatus({payload, meta, error}) {
    if (error || (meta && meta.sent === true)) {
        return;
    }

    const email = payload.email;
    const orderNumber = payload.orderNumber;

    try {
        const { data } = yield call(axios.request, {
            url: `/ajax/order-status/`,
            method: 'post',
            data: {
                email: email,
                order_number: orderNumber
            }
        });
        yield put(getOrderStatus(data, {'sent': true}, false));
    } catch (e) {
        yield put(getOrderStatus(e.response, {'sent': true}, true));
    }
}

export function* orderStatusSagas() {
    yield all([
        takeLatest(GET_ORDER_STATUS, handleFetchOrderStatus)
    ]);
}

export default orderStatusSagas;