import React from 'react'

const DarkenedViewport = ({ darkenViewPort }) => {
    return darkenViewPort ? (
        <div
            id={'darkenedViewport'}
            style={{
                position: 'fixed',
                zIndex: 2,
                inset: 0,
                backgroundColor: 'rgba(0,0,0,0.3)',
            }}
        />
    ) : (
        ''
    )
}

export default DarkenedViewport
