import {
    CLEAR_SMB_SUBMIT_ERROR,
    GET_SMB_STATUS,
    GET_SMB_STATUS_RESPONSE,
    SUBMIT_SMB_FORM,
    SUBMIT_SMB_FORM_RESPONSE,
} from '../actions/buyBulk'

const initialState = {
    smbStatus: {
        user_id: null,
        user_email: null,
        smb_status: null,
        _token: null,
    },
    smbStatusError: null,
    smbStatusIsLoading: false,
    smbSubmitSuccess: false,
    smbSubmitError: null,
    smbSubmitIsLoading: false,
}

export default function buyBulkReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SMB_STATUS:
            return { ...state, smbStatusIsLoading: true }
        case GET_SMB_STATUS_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    smbStatusError: action.payload,
                    smbStatus: {},
                    smbStatusIsLoading: false,
                    smbSubmitSuccess: false,
                }
            }
            return {
                ...state,
                smbStatus: action.payload.data,
                smbStatusError: null,
                smbStatusIsLoading: false,
            }
        case SUBMIT_SMB_FORM:
            return { ...state, smbSubmitIsLoading: true }
        case SUBMIT_SMB_FORM_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    smbSubmitError: action.payload,
                    smbSubmitIsLoading: false,
                    smbSubmitSuccess: false,
                }
            }
            return {
                ...state,
                smbSubmitSuccess: true,
                smbSubmitIsLoading: false,
            }
        case CLEAR_SMB_SUBMIT_ERROR:
            return { ...state, smbSubmitError: null }
        default:
            return state
    }
}
