import { VISA } from '../../constants'
import { COBRAND_TYPE } from '../SmallBusiness/SmbConstants'

function CobrandCard() {
    this.cobrandId = ''
    this.cardNetwork = VISA
    this.name = ''
    this.audience = ''
    this.background = ''
    this.rawBackgroundToCrop = ''
    this.logo = ''
    this.logoZoom = ''
    this.maxLogoZoom = ''
    this.logoPlacementError = ''
    this.initialLogoPlaced = false
    this.logoX = 0
    this.logoY = 0
    this.previewBlobUrl = ''
    this.rawBlobUrl = ''
}

export default CobrandCard
