import { all, fork } from 'redux-saga/effects'
import buyCardsSagas from './buyCards'
import orderStatusSagas from './orderStatus'
import buyBulkSagas from './buyBulk'
import smbCartSagas from './smbCart'
import orderDetailsSagas from './orderDetails'
import balanceCheckSagas from './balanceCheck'

export default function* rootSaga() {
    yield all([
        fork(buyCardsSagas),
        fork(orderStatusSagas),
        fork(buyBulkSagas),
        fork(smbCartSagas),
        fork(orderDetailsSagas),
        fork(balanceCheckSagas),
    ])
}
