import React, { useCallback, useEffect, useMemo, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import isNull from 'lodash/isNull'
import { pushToDataLayer } from '../../../utils/dataLayer'
import { useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    promotionBannerContainer: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        ['@media (min-width:361px)']: {
            height: '80px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0.5rem 1rem',
            justifyContent: 'center',
        },
        flexDirection: (props) => {
            if (props.shouldShowTerms) {
                return 'column'
            } else {
                return props.isMobile ? 'row' : 'column'
            }
        },
        backgroundColor: (props) => {
            return props?.backgroundColor ?? theme.palette.secondary.main
        },
        height: (props) => {
            if (props.shouldShowTerms) {
                return '100%'
            } else {
                return props.isMediumScreenSize ? '60px' : '90px'
            }
        },
    },
    promotionBanner: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    promoTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0.5rem 0.5rem 0.5rem',
        width: '6rem',
        ['@media (min-width:361px)']: {
            padding: '0 0 0 0.5rem',
            width: 'auto',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '40vw',
            padding: '0 0 0 1rem',
        },
    },
    promoTitleLink: {
        fontSize: '0.7rem',
        ['@media (min-width:420px)']: {
            fontSize: '.8rem',
        },
        ['@media (min-width:900px)']: {
            fontSize: '.9rem',
        },
        color: theme.palette.white,
    },
    countdownContainer: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 1rem',
        },
    },
    countdownTimeContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '90px',
        height: '50px',
        backgroundColor: theme.palette.white,
        borderRadius: '8px',
        padding: '0 !important',
        alignItems: 'center',
        marginRight: '0.5rem',
        '&:last-child': {
            marginRight: '0',
        },
    },
    countdownTimeValueText: {
        fontSize: '1.75rem',
        fontWeight: 'bold',
        lineHeight: 1,
        color: theme.palette.black,
        marginRight: '0.5rem',
    },
    countdownTimeLabelText: {
        fontSize: '0.6rem',
        lineHeight: 1,
        textTransform: 'uppercase',
        color: theme.palette.black,
    },
    promoButtonContainer: {
        display: 'flex',
        flexShrink: 0,
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '1rem',
        },
    },
    promoButton: {
        backgroundColor: theme.palette.white,
        borderRadius: '8px',
        color: theme.palette.primary.main,
        textTransform: 'none',
        fontWeight: 'bold',
        padding: '0.25rem 0.5rem',
        fontSize: '.7rem',
        [theme.breakpoints.up('sm')]: {
            padding: '0.25rem 1rem',
        },
        ['@media (min-width:600px)']: {
            fontSize: '.8rem',
        },
    },
    infoIcon: {
        alignSelf: 'center',
        color: theme.palette.white,
        padding: 0,
        margin: '0 1rem',
        ['@media (min-width:361px)']: {
            margin: '0 0.5rem',
        },
        [theme.breakpoints.up('sm')]: {
            margin: 0,
            padding: '0.75rem',
        },
    },
    termsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        padding: '1rem 0.5rem 0 0.5rem',
        [theme.breakpoints.up('md')]: {
            maxWidth: '40vw',
            padding: '1rem 1rem 0',
        },
    },
    termsText: {
        fontSize: '14px',
    },
    christmasCountdownContainer: {
        width: '100%',
        background: 'rgb(199,44,51)',
        display: 'flex',
        justifyContent: 'center',
        padding: '5px 0',
    },
    christmasCountdownCopy: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            fontWeight: 'bold',
            fontSize: '1rem',
            paddingLeft: '20px',
            textAlign: 'center',
        },
    },
    printAndShipCopy: {
        fontWeight: 'bold',
        fontSize: '0.7rem',
        textAlign: 'center',
        margin: '3px',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
            margin: 'auto 0',
            fontSize: '1rem',
        },
    },
    christmasCountdownContainerLink: {
        textDecoration: 'none',
        color: 'white',
    },
}))

const PromoBanner = (props) => {
    const {
        promoBanner: {
            id: promoBannerId,
            bannerText,
            bannerHtml,
            buttonText,
            backgroundColor,
            countdownDate,
            countdownEnabled,
            landingPageUrl,
            terms,
        },
        setPromotionExpired,
    } = props

    const [[days, hrs, mins], setTime] = useState([-1, -1, -1])
    const [shouldShowTerms, setShouldShowTerms] = useState(false)
    const isExtraSmallMobile = useMediaQuery('(max-width:360px)')
    const isMobile = useMediaQuery('(max-width:600px)')
    const isMediumScreenSize = useMediaQuery('(max-width:959px)')

    const classes = useStyles({
        backgroundColor,
        shouldShowTerms,
        isExtraSmallMobile,
        isMobile,
        isMediumScreenSize,
    })

    const countdownHasRemainingTime = useMemo(() => {
        return days + hrs + mins !== 0
    }, [days, hrs, mins])

    const shouldShowCountdown = useMemo(() => {
        return (
            countdownEnabled &&
            !isNull(countdownDate?.date) &&
            countdownHasRemainingTime
        )
    }, [countdownEnabled, countdownDate, countdownHasRemainingTime])

    const shouldShowPromoButton = useMemo(() => {
        return !isNull(buttonText)
    }, [buttonText])

    useEffect(() => {
        pushToDataLayer([
            'PromotionBannerImpression',
            'Promotion Banner',
            'Impression',
            promoBannerId,
            1,
            true,
        ])
    }, [promoBannerId])

    const sendPromoClickEvent = () => {
        pushToDataLayer([
            'PromotionBannerClick',
            'Promotion Banner',
            'Click',
            promoBannerId,
        ])
    }

    const tick = useCallback(() => {
        const now = new Date().getTime()

        // Safari requirement: 2022-04-08 15:00:48 -> 2022-04-08T15:00:48
        const formattedCountdownDate = countdownDate.date.replace(/\s/, 'T')
        const expireTime = new Date(formattedCountdownDate).getTime()

        let countdownTime = 0
        if (expireTime > now) {
            countdownTime = expireTime - now
        }

        if (countdownTime > 0) {
            const days = Math.floor(countdownTime / (1000 * 60 * 60 * 24))
            const hours = Math.floor(
                (countdownTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            )
            const minutes = Math.floor(
                (countdownTime % (1000 * 60 * 60)) / (1000 * 60)
            )

            setTime([days, hours, minutes])
        } else {
            setTime([0, 0, 0])
            setPromotionExpired(true)
        }
    }, [countdownDate, setPromotionExpired])

    useEffect(() => {
        let countdownTimerId = 0
        if (shouldShowCountdown) {
            countdownTimerId = setInterval(() => tick(), 60000)
            tick()
            return () => clearInterval(countdownTimerId)
        } else {
            clearInterval(countdownTimerId)
        }
    }, [shouldShowCountdown, tick, setPromotionExpired])

    const renderHtmlContent = () => {
        return (
            <div
                id={'promotion-banner'}
                data-testid={'promotion-banner-html'}
                dangerouslySetInnerHTML={{ __html: bannerHtml }}
            />
        )
    }

    const renderPromoCodeContent = () => {
        return (
            <>
                {isExtraSmallMobile && (
                    <div className={classes.promoTitleContainer}>
                        <Link
                            id={'promo-title-link'}
                            data-testid={'promo-title-link'}
                            className={classes.promoTitleLink}
                            href={landingPageUrl}
                            title={bannerText}
                            onClick={sendPromoClickEvent}
                        >
                            {bannerText}
                        </Link>
                    </div>
                )}
                <div
                    data-testid={'promotion-banner'}
                    className={classes.promotionBanner}
                >
                    {shouldShowCountdown && (
                        <div
                            data-testid={'countdown-timer-container'}
                            className={classes.countdownContainer}
                        >
                            <div className={classes.countdownTimeContainer}>
                                <Typography
                                    data-testid={'countdown-day-value'}
                                    variant={'body1'}
                                    className={classes.countdownTimeValueText}
                                >
                                    {days}
                                </Typography>
                                <Typography
                                    className={classes.countdownTimeLabelText}
                                >
                                    {`Day${days > 1 ? 's' : ''}`}
                                </Typography>
                            </div>
                            <div className={classes.countdownTimeContainer}>
                                <Typography
                                    data-testid={'countdown-hour-value'}
                                    variant={'body1'}
                                    className={classes.countdownTimeValueText}
                                >
                                    {hrs}
                                </Typography>
                                <Typography
                                    className={classes.countdownTimeLabelText}
                                >
                                    {`Hour${hrs > 1 ? 's' : ''}`}
                                </Typography>
                            </div>
                            <div className={classes.countdownTimeContainer}>
                                <Typography
                                    data-testid={'countdown-minute-value'}
                                    variant={'body1'}
                                    className={classes.countdownTimeValueText}
                                >
                                    {mins}
                                </Typography>
                                <Typography
                                    className={classes.countdownTimeLabelText}
                                >
                                    {`Minute${mins > 1 ? 's' : ''}`}
                                </Typography>
                            </div>
                        </div>
                    )}
                    {!isExtraSmallMobile && (
                        <div className={classes.promoTitleContainer}>
                            <Link
                                id={'promo-title-link'}
                                data-testid={'promo-title-link'}
                                className={classes.promoTitleLink}
                                href={landingPageUrl}
                                title={bannerText}
                                onClick={sendPromoClickEvent}
                            >
                                {bannerText}
                            </Link>
                        </div>
                    )}
                    {shouldShowPromoButton && (
                        <div className={classes.promoButtonContainer}>
                            <Button
                                data-testid={'promo-button'}
                                variant="contained"
                                href={landingPageUrl}
                                className={classes.promoButton}
                                onClick={sendPromoClickEvent}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    )}
                    <IconButton
                        data-testid={'terms-info-icon'}
                        aria-label={'promotion info'}
                        className={classes.infoIcon}
                        onClick={() => {
                            setShouldShowTerms((prevState) => !prevState)
                        }}
                    >
                        <InfoOutlinedIcon />
                    </IconButton>
                </div>
                {shouldShowTerms && (
                    <Grow in={shouldShowTerms}>
                        <div
                            data-testid={'terms-container'}
                            className={classes.termsContainer}
                        >
                            <Typography
                                data-testid={'terms-text'}
                                variant={'caption'}
                            >
                                {terms}
                            </Typography>
                        </div>
                    </Grow>
                )}
            </>
        )
    }

    const renderBannerContent = () => {
        if (!isNull(bannerHtml)) {
            return renderHtmlContent()
        } else {
            return renderPromoCodeContent()
        }
    }

    return (
        <>
            <div
                id={'promotion-banner-container'}
                data-testid={'promotion-banner-container'}
                className={
                    !isNull(bannerHtml) ? '' : classes.promotionBannerContainer
                }
            >
                {renderBannerContent()}
            </div>
        </>
    )
}

export default PromoBanner
