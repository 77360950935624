export class CarrierDeliveryInfo {
    shippingName = null
    addressLine1 = null
    addressLine2 = null
    city = null
    state = null
    zip = null
    shippingMethod = null

    constructor(
        shippingName = null,
        addressLine1 = null,
        addressLine2 = null,
        city = null,
        state = null,
        zip = null,
        shippingMethod = null
    ) {
        this.shippingName = shippingName
        this.addressLine1 = addressLine1
        this.addressLine2 = addressLine2
        this.city = city
        this.state = state
        this.zip = zip
        this.shippingMethod = shippingMethod
    }

    initializeFromJson(data) {
        this.shippingName = data.shippingName
        this.addressLine1 = data.addressLine1
        this.addressLine2 = data.addressLine2
        this.city = data.city
        this.state = data.state
        this.zip = data.zip
        this.shippingMethod = data.shippingMethod
    }
}
