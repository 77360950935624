import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import isEmpty from 'lodash/isEmpty'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'

const DesktopMenuColumnSet = ({ columnData, classes, innerDropdownKey, extoleUserData }) => {
    const theme = useTheme()

    const extoleMenuItemLink = (linkData) => {
        return (
            <Link
                href={'#'}
                className={classes.popoverLink}
                title={linkData.linkText}
                onClick={(event) => {
                    event.preventDefault()
                    extole.createZone({
                        name: 'share_experience',
                        data: extoleUserData,
                    })
                    pushToDataLayer(linkData.analyticsEvent)
                }}
            >
                <Typography noWrap className={classes.popoverLinkText}>
                    {linkData.linkText}
                </Typography>
            </Link>
        )
    }

    const columnItemLink = (linkData) => {
        if (linkData.type === 'extole-link') {
            return (
                <>
                    {extoleMenuItemLink(linkData)}
                </>
            )
        }

        return (
            <Link
                href={linkData.href}
                className={classes.popoverLink}
                title={linkData.linkText}
                onClick={() => pushToDataLayer(linkData.analyticsEvent)}
                style={{ paddingLeft: linkData?.paddingLeftDisabled ? '0' : 'auto' }}
            >
                {linkData.linkIcon && (
                    <img
                        className={classes.linkIcon}
                        src={linkData.linkIcon}
                        alt={'linkData.linkText'}
                    />
                )}
                {linkData.linkImage ? (
                    <img src={linkData.linkImage} alt={'linkData.linkText'} />
                ) : (
                    <Typography noWrap className={classes.popoverLinkText}>
                        {linkData.linkText}
                    </Typography>
                )}
                {linkData.subText && (
                    <Typography className={classes.linkSubText}>
                        {linkData.subText}
                    </Typography>
                )}
            </Link>
        )
    }

    const ColumnItem = (columnItemData, columnCount, key) => {
        const sizeMatches = useMediaQuery(theme.breakpoints.down(columnItemData.sizeOnlyDown))
        if (columnItemData.sizeOnlyDown && !sizeMatches) {
            return null
        }

        if (columnItemData.mobileOnly) {
            return
        }

        if (columnItemData.type === 'extole-link' && isEmpty(extoleUserData)) {
            return
        }
        return (
            <Grid item xs={Math.floor(12 / columnCount)} key={key}>
                <MenuItem>{columnItemLink(columnItemData)}</MenuItem>
            </Grid>
        )
    }

    return (
        <Grid container classes={{ root: classes.popoverGridRoot }}>
            {columnData.columnItems.map((columnItemData, index) =>
                ColumnItem(
                    columnItemData,
                    columnItemData.itemsCount ?? (columnData.columnCount ?? 1),
                    innerDropdownKey + 'columnItem' + index
                )
            )}
        </Grid>
    )
}

export default DesktopMenuColumnSet
