export const GET_SMB_STATUS = 'GCG/GET_SMB_STATUS'
export const getSMBStatus = (payload, meta, error = false) => ({
    type: GET_SMB_STATUS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const GET_SMB_STATUS_RESPONSE = 'GCG/GET_SMB_STATUS_RESPONSE'
export const getSMBStatusResponse = (payload, meta, error = false) => ({
    type: GET_SMB_STATUS_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SUBMIT_SMB_FORM = 'GCG/SUBMIT_SMB_FORM'
export const submitSMBForm = (payload, meta, error = false) => ({
    type: SUBMIT_SMB_FORM,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SUBMIT_SMB_FORM_RESPONSE = 'GCG/SUBMIT_SMB_FORM_RESPONSE'
export const submitSMBFormResponse = (payload, meta, error = false) => ({
    type: SUBMIT_SMB_FORM_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CLEAR_SMB_SUBMIT_ERROR = 'GCG/CLEAR_SMB_SUBMIT_ERROR'
export const clearSMBSubmitError = () => ({
    type: CLEAR_SMB_SUBMIT_ERROR,
})
