import React, { useEffect } from 'react'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import { usePopupState } from 'material-ui-popup-state/hooks'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import DesktopMenuColumnSet from './DesktopMenuColumnSet'
import DesktopMenuButton from './DesktopMenuButton'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import { Grid } from '@material-ui/core'
import DesktopMenuMerchantSet from './DesktopMenuMerchantSet'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import ContentSeparatorSubNavBar from '../ContentSeparatorSubNavBar'

const DesktopMenuItemPopover = ({
    item,
    classes,
    setDarkenViewPort,
    popupId,
    extoleUserData,
}) => {
    const dropdownItems = item['dropdownItems']
    const popupState = usePopupState({ variant: 'popover', popupId: popupId })
    const theme = useTheme()

    const dropDownItemLayout = (dropdownItem, innerDropdownKey) => {
        if (dropdownItem.type === 'header') {
            return (
                <h4 className={classes.popoverHeader} key={innerDropdownKey}>
                    {dropdownItem.text}
                </h4>
            )
        }
        if (dropdownItem.type === 'text') {
            return (
                <p className={classes.popoverText} key={innerDropdownKey}>
                    {dropdownItem.text}
                </p>
            )
        }
        if (dropdownItem.type === 'image') {
            return (
                <p key={innerDropdownKey}>
                    <img src={dropdownItem.src} alt={'dropdownItem.alt'} />
                </p>
            )
        }
        if (dropdownItem.type === 'button') {
            return (
                <DesktopMenuButton
                    buttonData={dropdownItem}
                    classes={classes}
                    key={innerDropdownKey}
                />
            )
        }
        if (dropdownItem.type === 'merchantSet') {
            return (
                <DesktopMenuMerchantSet
                    columnData={dropdownItem}
                    key={innerDropdownKey}
                    innerDropdownKey={innerDropdownKey}
                />
            )
        }
        if (dropdownItem.type === 'columnSet') {
            return (
                <DesktopMenuColumnSet
                    columnData={dropdownItem}
                    classes={classes}
                    key={innerDropdownKey}
                    innerDropdownKey={innerDropdownKey}
                    extoleUserData={extoleUserData}
                />
            )
        }
        if (dropdownItem.type === 'multipleColumnSet') {
            return (
                <Grid container spacing={3} key={innerDropdownKey}>
                    {dropdownItem.multipleColumnSetItems.map(
                        (multipleColumnSetItem, index) => {
                            const innerMultipleColumnSetKey =
                                innerDropdownKey + 'multipleColumnSet' + index
                            return (
                                <Grid
                                    item
                                    className={classes.popoverGridItem}
                                    xs={Math.floor(
                                        12 / dropdownItem.columnCount
                                    )}
                                    key={innerMultipleColumnSetKey}
                                >
                                    {multipleColumnSetItem.map(
                                        (multipleColumnSetItemRow, index) =>
                                            dropDownItemLayout(
                                                multipleColumnSetItemRow,
                                                innerMultipleColumnSetKey +
                                                    'row' +
                                                    index
                                            )
                                    )}
                                </Grid>
                            )
                        }
                    )}
                </Grid>
            )
        }
    }

    useEffect(() => {
        setDarkenViewPort(popupState.isOpen)
    }, [popupState, setDarkenViewPort])

    const sizeMatches = useMediaQuery(theme.breakpoints.up(item.sizeOnlyUp))
    if (item.sizeOnlyUp && !sizeMatches) {
        return null
    }

    return (
        <PopupState variant="popover" popupId={popupId}>
            {() => (
                <React.Fragment>
                    <Link
                        {...bindHover(popupState)}
                        className={classes.link}
                        href={item.href}
                        title={item.linkText}
                        onClick={() => pushToDataLayer(item.analyticsEvent)}
                    >
                        <Typography className={classes.linkText}>
                            {item.linkText}
                        </Typography>
                    </Link>
                    {item.linkText !== 'More' && <ContentSeparatorSubNavBar />}
                    {dropdownItems && (
                        <HoverPopover
                            {...bindPopover(popupState)}
                            className={classes.popover}
                            classes={{
                                paper: `${classes.popoverContent} ${classes.popoverBorder}`,
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transitionDuration={0}
                            disableScrollLock={true}
                        >
                            {dropdownItems &&
                                dropdownItems.map(
                                    (dropdownItemElement, innerDropdownIndex) =>
                                        dropDownItemLayout(
                                            dropdownItemElement,
                                            'dropdownItem' + innerDropdownIndex
                                        )
                                )}
                        </HoverPopover>
                    )}
                </React.Fragment>
            )}
        </PopupState>
    )
}

export default DesktopMenuItemPopover
