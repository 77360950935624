export const SET_SMB_SELECTED_GIFT_TEMPLATE =
    'GCG/SMB_SET_SELECTED_GIFT_TEMPLATE'
export const setSmbSelectedGiftTemplate = (payload, meta, error) => ({
    type: SET_SMB_SELECTED_GIFT_TEMPLATE,
    payload,
    meta,
    error,
})

export const SMB_LOGIN_REQUEST = 'GCG/SMB_LOGIN_REQUEST'
export const smbLoginRequest = (payload, meta, error = false) => ({
    type: SMB_LOGIN_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_LOGIN_RESPONSE = 'GCG/SMB_LOGIN_RESPONSE'
export const smbLoginResponse = (payload, meta, error = false) => ({
    type: SMB_LOGIN_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_REGISTRATION_REQUEST = 'GCG/SMB_REGISTRATION_REQUEST'
export const smbRegistrationRequest = (payload, meta, error = false) => ({
    type: SMB_REGISTRATION_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_REGISTRATION_RESPONSE = 'GCG/SMB_REGISTRATION_RESPONSE'
export const smbRegistrationResponse = (payload, meta, error = false) => ({
    type: SMB_REGISTRATION_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CLEAR_SMB_REGISTRATION_ERROR = 'GCG/CLEAR_SMB_REGISTRATION_ERROR'
export const clearSmbRegistrationError = () => ({
    type: CLEAR_SMB_REGISTRATION_ERROR,
})

export const SET_SMB_SELECTED_PRODUCT = 'GCG/SMB_SET_SELECTED_PRODUCT'
export const setSmbSelectedProduct = (payload, error = false) => ({
    type: SET_SMB_SELECTED_PRODUCT,
    payload,
    ...(error && { error }),
})

export const SET_SMB_DELIVERY_STYLE = 'GCG/SMB_SET_DELIVERY_STYLE'
export const setSmbDeliveryStyle = (payload, meta, error = false) => ({
    type: SET_SMB_DELIVERY_STYLE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_SMB_ANTICIPATED_LOAD_VALUE =
    'GCG/SMB_SET_ANTICIPATED_LOAD_VALUE'
export const setAnticipatedLoadValue = (payload, meta, error = false) => ({
    type: SET_SMB_ANTICIPATED_LOAD_VALUE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_SMB_ALREADY_CHOSE_QTY_AND_LOAD_VALUE =
    'GCG/SMB_SET_ALREADY_CHOSE_QTY_AND_LOAD_VALUE'
export const setAlreadyChoseQtyAndLoadValue = (
    payload,
    meta,
    error = false
) => ({
    type: SET_SMB_ALREADY_CHOSE_QTY_AND_LOAD_VALUE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_SMB_ANTICIPATED_QUANTITY = 'GCG/SMB_SET_ANTICIPATED_QUANTITY'
export const setAnticipatedQuantity = (payload, meta, error = false) => ({
    type: SET_SMB_ANTICIPATED_QUANTITY,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_SMB_APPLY_ALL_OVERRIDES = 'GCG/SMB_SET_APPLY_ALL_OVERRIDES'
export const setApplyAllOverrides = (payload, meta, error = false) => ({
    type: SET_SMB_APPLY_ALL_OVERRIDES,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_SMB_RELOAD_CARDS = 'GCG/SMB_SET_RELOAD_CARDS'
export const setSmbReloadCards = (payload, meta, error = false) => ({
    type: SET_SMB_RELOAD_CARDS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_SMB_CARDS = 'GCG/SMB_SET_CARDS'
export const setSmbCards = (payload, meta, error = false) => ({
    type: SET_SMB_CARDS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_SMB_DETAILS_ENTRY_TYPE = 'GCG/SMB_SET_DETAILS_ENTRY_TYPE'
export const setSmbDetailsEntryType = (payload, meta, error = false) => ({
    type: SET_SMB_DETAILS_ENTRY_TYPE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_SMB_GRID_DATA = 'GCG/SMB_SET_GRID_DATA'
export const setSmbGridData = (payload, meta, error = false) => ({
    type: SET_SMB_GRID_DATA,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const UPDATE_SMB_GRID_DATA_ROW = 'GCG/SMB_UPDATE_GRID_DATA_ROW'
export const updateSmbGridDataRow = (payload, meta, error = false) => ({
    type: UPDATE_SMB_GRID_DATA_ROW,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CREATE_SMB_ORDER_RESPONSE = 'GCG/SMB_CREATE_ORDER_RESPONSE'
export const createSmbOrderResponse = (payload, meta, error = false) => ({
    type: CREATE_SMB_ORDER_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_ORDER_CANCELLED = 'GCG/SMB_ORDER_CANCELLED'
export const orderCancelled = (payload, meta, error = false) => ({
    type: SMB_ORDER_CANCELLED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_GET_CART_CONTENTS_REQUEST = 'GCG/SMB_GET_CART_CONTENTS_REQUEST'
export const getSmbCartContentsRequest = (payload, meta, error = false) => ({
    type: SMB_GET_CART_CONTENTS_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_GET_CART_CONTENTS_RESPONSE =
    'GCG/SMB_GET_CART_CONTENTS_RESPONSE'
export const getSmbCartContentsResponse = (payload, meta, error = false) => ({
    type: SMB_GET_CART_CONTENTS_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_ADD_TO_CART = 'GCG/SMB_ADD_TO_CART'
export const addToSmbCart = (payload, meta, error = false) => ({
    type: SMB_ADD_TO_CART,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_REMOVE_FROM_CART = 'GCG/SMB_REMOVE_FROM_CART'
export const removeFromSmbCart = (payload, meta, error = false) => ({
    type: SMB_REMOVE_FROM_CART,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_RESET_CART = 'GCG/SMB_RESET_CART'
export const resetSmbCart = (payload, meta, error = false) => ({
    type: SMB_RESET_CART,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_MARK_CART_ITEM_FOR_DELETION =
    'GCG/SMB_MARK_CART_ITEM_FOR_DELETION'
export const markSmbCartItemForDeletion = (payload, meta, error = false) => ({
    type: SMB_MARK_CART_ITEM_FOR_DELETION,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_SET_CART_FLYOUT_MODE = 'GCG/SMB_SET_CART_FLYOUT_MODE'
export const setSmbCartFlyoutMode = (payload) => ({
    type: SMB_SET_CART_FLYOUT_MODE,
    payload,
})

export const SMB_ITEMS_SYNCED_TO_BACKEND = 'GCG/SMB_ITEMS_SYNCED_TO_BACKEND'
export const smbItemsSyncedToBackend = (payload, meta, error = false) => ({
    type: SMB_ITEMS_SYNCED_TO_BACKEND,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_UPDATE_DELIVERY_GROUP = 'GCG/SMB_UPDATE_DELIVERY_GROUP'
export const updateDeliveryGroup = (payload, meta, error = false) => ({
    type: SMB_UPDATE_DELIVERY_GROUP,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const GET_SMB_STATUS_REQUEST = 'GCG/SMB_GET_SMB_STATUS_REQUEST'
export const getSmbStatusRequest = (payload, meta, error = false) => ({
    type: GET_SMB_STATUS_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const GET_SMB_STATUS_RESPONSE = 'GCG/SMB_GET_SMB_STATUS_RESPONSE'
export const getSmbStatusResponse = (payload, meta, error = false) => ({
    type: GET_SMB_STATUS_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_CHECK_LOGIN_REQUEST = 'GCG/SMB_CHECK_LOGIN_REQUEST'
export const smbCheckLoginRequest = (payload, meta, error = false) => ({
    type: SMB_CHECK_LOGIN_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_CHECK_LOGIN_RESPONSE = 'GCG/SMB_CHECK_LOGIN_RESPONSE'
export const smbCheckLoginResponse = (payload, meta, error = false) => ({
    type: SMB_CHECK_LOGIN_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_SET_ORDER_STATUS = 'GCG/SMB_SET_ORDER_STATUS'
export const smbSetOrderStatus = (payload, meta, error = false) => ({
    type: SMB_SET_ORDER_STATUS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_SET_EDIT_ITEM = 'GCG/SMB_SET_EDIT_ITEM'
export const smbSetEditItem = (payload, meta, error = false) => ({
    type: SMB_SET_EDIT_ITEM,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_CANCEL_EDIT_ITEM = 'GCG/SMB_CANCEL_EDIT_ITEM'
export const smbCancelEditItem = (payload, meta, error = false) => ({
    type: SMB_CANCEL_EDIT_ITEM,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_EDIT_CART_ITEM_REQUEST = 'GCG/SMB_EDIT_CART_ITEM_REQUEST'
export const smbEditCartItemRequest = (payload, meta, error = false) => ({
    type: SMB_EDIT_CART_ITEM_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_EDIT_CART_ITEM_RESPONSE = 'GCG/SMB_EDIT_CART_ITEM_RESPONSE'
export const smbEditCartItemResponse = (payload, meta, error = false) => ({
    type: SMB_EDIT_CART_ITEM_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_CLEAR_EDIT_SUCCESS = 'GCG/SMB_CLEAR_EDIT_SUCCESS'
export const smbClearEditSuccess = (payload, meta, error = false) => ({
    type: SMB_CLEAR_EDIT_SUCCESS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_UPDATE_COBRAND_CARD = 'GCG/SMB_UPDATE_COBRAND_CARD'
export const updateCobrandCard = (payload, meta, error = false) => ({
    type: SMB_UPDATE_COBRAND_CARD,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_UPDATE_COBRAND_CARD_LOGO = 'GCG/SMB_UPDATE_COBRAND_CARD_LOGO'
export const updateCobrandCardLogo = (payload, meta, error = false) => ({
    type: SMB_UPDATE_COBRAND_CARD_LOGO,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_UPDATE_COBRAND_CARD_LOGO_ZOOM =
    'GCG/SMB_UPDATE_COBRAND_CARD_LOGO_ZOOM'
export const updateCobrandCardLogoZoom = (payload, meta, error = false) => ({
    type: SMB_UPDATE_COBRAND_CARD_LOGO_ZOOM,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_RESET_COBRAND_BUILDER = 'GCG/SMB_RESET_COBRAND_BUILDER'
export const smbResetCobrandBuilder = (payload, meta, error = false) => ({
    type: SMB_RESET_COBRAND_BUILDER,
    payload,
    meta,
    error,
})

export const SMB_INITIALIZE_COBRAND_CARD_LOGO_VALUES =
    'GCG/SMB_INITIALIZE_COBRAND_CARD_LOGO_VALUES'
export const initializeCobrandCardLogoValues = (
    payload,
    meta,
    error = false
) => ({
    type: SMB_INITIALIZE_COBRAND_CARD_LOGO_VALUES,
    payload,
    meta,
    error,
})

export const SMB_UPDATE_COBRAND_CARD_LOGO_PLACEMENT_ERROR =
    'GCG/SMB_UPDATE_COBRAND_CARD_LOGO_PLACEMENT_ERROR'
export const updateCobrandCardLogoPlacementError = (
    payload,
    meta,
    error = false
) => ({
    type: SMB_UPDATE_COBRAND_CARD_LOGO_PLACEMENT_ERROR,
    payload,
    meta,
    error,
})

export const SET_CUSTOMIZE_STEP = 'GCG/SET_CUSTOMIZE_STEP'
export const setCustomizeStep = (payload, meta, error = false) => ({
    type: SET_CUSTOMIZE_STEP,
    payload,
    meta,
    error,
})

export const SMB_LOGOUT = 'GCG/SMB_LOGOUT'
export const smbLogout = (payload, meta, error = false) => ({
    type: SMB_LOGOUT,
    payload,
    meta,
    error,
})

export const SET_SMB_GUEST_USER_INFO = 'GCG/SET_SMB_GUEST_USER_INFO'
export const setSmbGuestUserInfo = (payload, meta, error = false) => ({
    type: SET_SMB_GUEST_USER_INFO,
    payload,
    meta,
    error,
})

export const SET_SMB_CHECKOUT_STEP = 'GCG/SET_SMB_CHECKOUT_STEP'
export const setSmbCheckoutStep = (payload, meta, error = false) => ({
    type: SET_SMB_CHECKOUT_STEP,
    payload,
    meta,
    error,
})
