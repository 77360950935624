import React from 'react'
import { useDispatch } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { pushToDataLayer } from '../../../utils/dataLayer'
import { smbLogout } from '../../../actions/smbCart'
import isEmpty from 'lodash/isEmpty'

const useStyles = makeStyles((theme) => ({
    menuDropdownButton: {
        borderWidth: '2px',
        '&:hover': {
            borderWidth: '2px',
        },
    },
    menuDropdownButtonText: {
        fontWeight: 'bold',
    },
    popoverLink: {
        color: '#222222',
        textUnderlineOffset: '1px',
        paddingLeft: '1em',
        paddingRight: '1em',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    popoverLinkText: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.95rem',
        },
    },
    popoverListItemText: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.95rem',
        },
        paddingLeft: '1em',
        paddingRight: '1em',
    },
}))

const AccountMenuItems = ({ isLoggedIn, firstName, baseUrl, extoleUserData }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const MenuItemLink = ({ href, linkText, analyticsEvent }) => {
        return (
            <MenuItem>
                <Link
                    href={href}
                    className={classes.popoverLink}
                    onClick={() => pushToDataLayer(analyticsEvent)}
                >
                    <Typography className={classes.popoverLinkText}>
                        {linkText}
                    </Typography>
                </Link>
            </MenuItem>
        )
    }

    const ExtoleMenuItemLink = () => {
        return (
            <>
                {!isEmpty(extoleUserData) ? <MenuItem>
                    <Link
                        href={'#'}
                        className={classes.popoverLink}
                        onClick={(event) => {
                            event.preventDefault()
                            extole.createZone({
                                name: 'share_experience',
                                data: extoleUserData,
                            })
                            pushToDataLayer(['HeaderClick', 'Header', 'Click', 'Give $5 Get $5'])
                        }}
                    >
                        <Typography className={classes.popoverLinkText}>
                            {'Give $5 Get $5'}
                        </Typography>
                    </Link>
                </MenuItem>
                : null}
            </>
        )
    }

    return (
        <React.Fragment>
            <ListItem>
                <Typography className={classes.popoverListItemText}>
                    Welcome{firstName ? ', ' + firstName : ''}!
                </Typography>
            </ListItem>
            <Divider />
            <MenuItemLink
                href={`${baseUrl}/account/`}
                linkText={'My account'}
                analyticsEvent={[
                    'HeaderClick',
                    'Header',
                    'Click',
                    'My account',
                ]}
            />
            <MenuItemLink
                href={`${baseUrl}/account/orders/`}
                linkText={'My orders'}
                analyticsEvent={['HeaderClick', 'Header', 'Click', 'My orders']}
            />
            <MenuItemLink
                href={`${baseUrl}/orderstatus/`}
                linkText={'Track Order'}
                analyticsEvent={[
                    'HeaderClick',
                    'Header',
                    'Click',
                    'Track Order',
                ]}
            />
            <MenuItemLink
                href={`${baseUrl}/account/rewards/`}
                linkText={'Cash back rewards'}
                analyticsEvent={[
                    'HeaderClick',
                    'Header',
                    'Click',
                    'Cash back rewards',
                ]}
            />
            <MenuItemLink
                href={`${baseUrl}/account/alerts/`}
                linkText={'My alerts'}
                analyticsEvent={['HeaderClick', 'Header', 'Click', 'My alerts']}
            />
            <Divider />
            {isLoggedIn ? (
                <>
                    <ExtoleMenuItemLink />
                    <MenuItem>
                        <Button
                            href={`${baseUrl}/account/logout/`}
                            onClick={() => {
                                dispatch(smbLogout())
                                pushToDataLayer([
                                    'HeaderClick',
                                    'Header',
                                    'Click',
                                    'Log out',
                                ])
                            }}
                            className={classes.menuDropdownButton}
                            variant={'outlined'}
                            color="primary"
                            size={'large'}
                            fullWidth
                        >
                            <Typography className={classes.menuDropdownButtonText}>
                                Log out
                            </Typography>
                        </Button>
                    </MenuItem>
                </>
            ) : (
                <Box>
                    <MenuItem>
                        <Button
                            href={`${baseUrl}/secure/login/`}
                            onClick={() =>
                                pushToDataLayer([
                                    'HeaderClick',
                                    'Header',
                                    'Click',
                                    'Login',
                                ])
                            }
                            className={classes.menuDropdownButton}
                            variant={'contained'}
                            color="primary"
                            size={'large'}
                            fullWidth
                        >
                            <Typography
                                className={classes.menuDropdownButtonText}
                            >
                                Log in
                            </Typography>
                        </Button>
                    </MenuItem>
                    <MenuItem>
                        <Button
                            href={`${baseUrl}/secure/signup/`}
                            onClick={() =>
                                pushToDataLayer([
                                    'HeaderClick',
                                    'Header',
                                    'Click',
                                    'Signup',
                                ])
                            }
                            className={classes.menuDropdownButton}
                            variant={'outlined'}
                            color="primary"
                            size={'large'}
                            fullWidth
                        >
                            <Typography
                                className={classes.menuDropdownButtonText}
                            >
                                Create account
                            </Typography>
                        </Button>
                    </MenuItem>
                </Box>
            )}
        </React.Fragment>
    )
}

export default AccountMenuItems

AccountMenuItems.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
    baseUrl: PropTypes.string.isRequired,
}
