export const GET_MERCHANT_CHUNK_REQUEST = 'GCG/GET_MERCHANT_CHUNK_REQUEST'
export const getMerchantChunkRequest = (payload, meta, error = false) => ({
    type: GET_MERCHANT_CHUNK_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const GET_MERCHANT_CHUNK_RESPONSE = 'GCG/GET_MERCHANT_CHUNK_RESPONSE'
export const getMerchantChunkResponse = (payload, meta, error = false) => ({
    type: GET_MERCHANT_CHUNK_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const GET_NEXT_MERCHANT_CHUNK = 'GCG/GET_NEXT_MERCHANT_CHUNK'
export const getNextMerchantChunk = (payload, meta, error = false) => ({
    type: GET_NEXT_MERCHANT_CHUNK,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_FILTERED_MERCHANTS = 'GCG/SET_FILTERED_MERCHANTS'
export const setFilteredMerchants = (payload, meta, error = false) => ({
    type: SET_FILTERED_MERCHANTS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SET_MERCHANT_LIST = 'GCG/SET_MERCHANT_LIST'
export const setMerchantList = (payload, meta, error = false) => ({
    type: SET_MERCHANT_LIST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})
