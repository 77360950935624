export const SMB_REORDER_REQUEST = 'GCG/SMB_REORDER_REQUEST'
export const smbReorderRequest = (payload, meta, error = false) => ({
    type: SMB_REORDER_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_REORDER_RESPONSE = 'GCG/SMB_REORDER_RESPONSE'
export const smbReorderResponse = (payload, meta, error = false) => ({
    type: SMB_REORDER_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_GET_ORDER_REQUEST = 'GCG/SMB_GET_ORDER_REQUEST'
export const smbGetOrderRequest = (payload, meta, error = false) => ({
    type: SMB_GET_ORDER_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const SMB_GET_ORDER_RESPONSE = 'GCG/SMB_GET_ORDER_RESPONSE'
export const smbGetOrderResponse = (payload, meta, error = false) => ({
    type: SMB_GET_ORDER_RESPONSE,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})
