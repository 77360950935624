import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
    GET_MERCHANT_CHUNK_REQUEST,
    getMerchantChunkResponse,
} from '../actions/balanceCheck'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { FILTERED_MERCHANT_CHUNK_REQUEST } from '../constants'

const axiosInstance = axios.create({
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

function* getMerchantChunk(action) {
    let payload = null
    if (action.meta?.requestType === FILTERED_MERCHANT_CHUNK_REQUEST) {
        payload = action.payload
    } else {
        payload = yield select(
            (state) => state.balanceCheck.currentMerchantChunk
        )
    }

    if (payload) {
        try {
            const { data } = yield call(axiosInstance.request, {
                url: `/ajax/get-gift-card-balance-check-merchants/?chunk=${payload}`,
                contentType: 'application/json',
            })
            if (action.meta?.requestType === FILTERED_MERCHANT_CHUNK_REQUEST) {
                yield put(
                    getMerchantChunkResponse(
                        { data },
                        {
                            requestType: FILTERED_MERCHANT_CHUNK_REQUEST,
                            requestParam: action.meta?.requestParam,
                        }
                    )
                )
            } else {
                yield put(getMerchantChunkResponse({ data }))
            }
        } catch (e) {
            yield call(Sentry.captureException, e)
            yield put(getMerchantChunkResponse(e, '', true))
        }
    }
}

export function* balanceCheckSagas() {
    yield all([takeLatest(GET_MERCHANT_CHUNK_REQUEST, getMerchantChunk)])
}

export default balanceCheckSagas
