import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import useLoggedIn from '../../hooks/useLoggedIn'

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'white',
    },
    wrapper: {
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem',
        margin: '0 auto',
        maxWidth: '1280px',
    },
    buttonContainer: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center',
        },
    },
    button: {
        display: 'inline-block',
        backgroundColor: '#7347b2',
        borderRadius: '5px',
        padding: '0.5rem 0',
        border: '1px solid #7347b2',
        color: '#ffffff',
        fontWeight: 'bold',
        width: '224px',
        textAlign: 'center',
        '&:hover': {
            backgroundColor: '#3b1a6e',
            opacity: '0.8',
        },
    },
    title: {
        color: 'rgb(66,32,111)',
        fontWeight: 'bold',
        fontSize: '24px',
        marginTop: '4px',
        marginBottom: '0',
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center',
        },
    },
}))

const ExtoleBanner = () => {
    const classes = useStyles()

    const { data: userSessionData, isLoading: loadingUserSessionData } =
        useLoggedIn()

    const extoleUserData =
        !loadingUserSessionData &&
        userSessionData &&
        userSessionData.is_logged_in
            ? {
                  email: userSessionData.username,
                  first_name: userSessionData.first_name,
                  last_name: userSessionData.last_name,
                  phone_number: userSessionData.phone_number,
                  partner_user_id: userSessionData.user_id,
              }
            : null

    return (
        <>
            {extoleUserData ? (
                <Grid container spacing={1} className={classes.container}>
                    <Grid
                        item
                        xs={12}
                        style={{ borderBottom: '2px solid #7347b2' }}
                    >
                        <Grid container spacing={2} className={classes.wrapper}>
                            <Grid
                                item
                                xs={12}
                                md={3}
                                className={classes.buttonContainer}
                            >
                                <Button
                                    className={classes.button}
                                    onClick={() => {
                                        extole.createZone({
                                            name: 'share_experience',
                                            data: extoleUserData,
                                        })
                                    }}
                                >
                                    Refer A Friend
                                </Button>
                            </Grid>

                            <Grid item xs={12} md={9}>
                                <Box>
                                    <Typography className={classes.title}>
                                        Refer a friend to Gift Card Granny and
                                        earn discounts on your next purchase!
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
        </>
    )
}

export default ExtoleBanner
