import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import isNull from 'lodash/isNull'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { pushToDataLayer } from '../../../utils/dataLayer'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles((theme) => ({
    promoBanner: {
        height: 90,
    },
    promoBannerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'white',
        padding: '20px 0',
        height: 90,
        [theme.breakpoints.up('md')]: {
            gap: 30,
        },
    },
    promoBannerCarouselImg: {
        width: 100,
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    promoBannerText: {
        fontSize: 15,
        padding: 0,
        margin: 0,
        color: 'white',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
    },
    promoBannerButton: {
        color: 'white',
        border: '1px solid white',
        borderRadius: 3,
        padding: '10px 1rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        cursor: 'pointer',
    },
    promoBannerContents: {
        display: 'flex',
        gap: 8,
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            gap: 30,
        },
    },
    promoBannerNavigationButton: {
        background: 'transparent',
        color: 'white',
        border: '1px solid transparent',
        cursor: 'pointer',
        padding: 0,
        [theme.breakpoints.up('md')]: {
            padding: '0 .5rem',
        },
    },
    promoBannerHref: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        textDecoration: 'none',
    },
    countdownContainer: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 1rem',
        },
    },
    countdownTimeContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '90px',
        height: '50px',
        backgroundColor: theme.palette.white,
        borderRadius: '8px',
        padding: '0 !important',
        alignItems: 'center',
        marginRight: '0.5rem',
        '&:last-child': {
            marginRight: '0',
        },
    },
    countdownTimeValueText: {
        fontSize: '1.75rem',
        fontWeight: 'bold',
        lineHeight: 1,
        color: theme.palette.black,
        marginRight: '0.5rem',
    },
    countdownTimeLabelText: {
        fontSize: '0.6rem',
        lineHeight: 1,
        textTransform: 'uppercase',
        color: theme.palette.black,
    },
}))

const PromoBannerCarousel = (props) => {
    const { promoBanners, setPromotionExpired } = props

    const [promoBanner, setPromoBanner] = useState(promoBanners[0])
    const [[days, hrs, mins], setTime] = useState([-1, -1, -1])

    const { countdownEnabled, countdownDate } = promoBanner

    const classes = useStyles()

    const showPreviousBanner = () => {
        const currentIndex = promoBanners.indexOf(promoBanner)
        const newIndex =
            (currentIndex - 1 + promoBanners.length) % promoBanners.length
        setPromoBanner(promoBanners[newIndex])
    }

    const showNextBanner = () => {
        const currentIndex = promoBanners.indexOf(promoBanner)
        const newIndex = (currentIndex + 1) % promoBanners.length
        setPromoBanner(promoBanners[newIndex])
    }

    const renderHtmlContent = (bannerHtml) => {
        return bannerHtml ? (
            <Box
                id={'promotion-banner'}
                data-testid={'promotion-banner-html'}
                dangerouslySetInnerHTML={{
                    __html: bannerHtml,
                }}
            />
        ) : null
    }

    // Work around until image field gets to added the CMS
    const extractImgFromBannerHtml = (promoBannerHtml) => {
        return promoBannerHtml.replace('img:', '')
    }

    const renderBannerContent = (promoBanner) => {
        if (!isNull(promoBanner.bannerHtml)) {
            if (promoBanner.bannerHtml.startsWith('img:')) {
                const bannerImg = extractImgFromBannerHtml(
                    promoBanner.bannerHtml
                )

                return renderPromoCodeContent(promoBanner, bannerImg)
            } else {
                return renderHtmlContent(promoBanner.bannerHtml)
            }
        } else {
            return renderPromoCodeContent(promoBanner, '')
        }
    }

    const countdownHasRemainingTime = useMemo(() => {
        return days + hrs + mins !== 0
    }, [days, hrs, mins])

    const shouldShowCountdown = useMemo(() => {
        return (
            countdownEnabled &&
            !isNull(countdownDate?.date) &&
            countdownHasRemainingTime
        )
    }, [countdownEnabled, countdownDate, countdownHasRemainingTime])

    const tick = useCallback(() => {
        const now = new Date().getTime()

        // Safari requirement: 2022-04-08 15:00:48 -> 2022-04-08T15:00:48
        const formattedCountdownDate = countdownDate.date.replace(/\s/, 'T')
        const expireTime = new Date(formattedCountdownDate).getTime()

        let countdownTime = 0
        if (expireTime > now) {
            countdownTime = expireTime - now
        }

        if (countdownTime > 0) {
            const days = Math.floor(countdownTime / (1000 * 60 * 60 * 24))
            const hours = Math.floor(
                (countdownTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            )
            const minutes = Math.floor(
                (countdownTime % (1000 * 60 * 60)) / (1000 * 60)
            )

            setTime([days, hours, minutes])
        } else {
            setTime([0, 0, 0])
            setPromotionExpired(true)
        }
    }, [countdownDate, setPromotionExpired])

    useEffect(() => {
        let countdownTimerId = 0
        if (shouldShowCountdown) {
            countdownTimerId = setInterval(() => tick(), 60000)
            tick()
            return () => clearInterval(countdownTimerId)
        } else {
            clearInterval(countdownTimerId)
        }
    }, [shouldShowCountdown, tick, setPromotionExpired])

    const renderPromoCodeContent = (promoBanner, bannerImg) => {
        const sendPromoClickEvent = () => {
            pushToDataLayer([
                'PromotionBannerClick',
                'Promotion Banner',
                'Click',
                promoBanner.id,
            ])
        }

        return (
            <div
                className={classes.promoBannerContainer}
                style={{
                    background: promoBanner.backgroundColor
                        ? promoBanner.backgroundColor
                        : '#592063',
                }}
            >
                {promoBanners.length > 0 && (
                    <button
                        className={classes.promoBannerNavigationButton}
                        onClick={showPreviousBanner}
                    >
                        <ChevronLeftIcon fontSize="large" />
                    </button>
                )}

                <a
                    className={classes.promoBannerHref}
                    href={promoBanner.landingPageUrl}
                    onClick={sendPromoClickEvent}
                >
                    <div className={classes.promoBannerContents}>
                        {shouldShowCountdown && (
                            <div
                                data-testid={'countdown-timer-container'}
                                className={classes.countdownContainer}
                            >
                                <div className={classes.countdownTimeContainer}>
                                    <Typography
                                        data-testid={'countdown-day-value'}
                                        variant={'body1'}
                                        className={
                                            classes.countdownTimeValueText
                                        }
                                    >
                                        {days}
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.countdownTimeLabelText
                                        }
                                    >
                                        {`Day${days > 1 ? 's' : ''}`}
                                    </Typography>
                                </div>
                                <div className={classes.countdownTimeContainer}>
                                    <Typography
                                        data-testid={'countdown-hour-value'}
                                        variant={'body1'}
                                        className={
                                            classes.countdownTimeValueText
                                        }
                                    >
                                        {hrs}
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.countdownTimeLabelText
                                        }
                                    >
                                        {`Hour${hrs > 1 ? 's' : ''}`}
                                    </Typography>
                                </div>
                                <div className={classes.countdownTimeContainer}>
                                    <Typography
                                        data-testid={'countdown-minute-value'}
                                        variant={'body1'}
                                        className={
                                            classes.countdownTimeValueText
                                        }
                                    >
                                        {mins}
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.countdownTimeLabelText
                                        }
                                    >
                                        {`Minute${mins > 1 ? 's' : ''}`}
                                    </Typography>
                                </div>
                            </div>
                        )}
                        {bannerImg && (
                            <img
                                className={classes.promoBannerCarouselImg}
                                src={bannerImg}
                                alt={`${promoBanner.buttonText} card image`}
                                width={100}
                                height={64}
                            />
                        )}

                        {promoBanner.bannerText && (
                            <p className={classes.promoBannerText}>
                                {promoBanner.bannerText}
                            </p>
                        )}
                        {promoBanner.buttonText && (
                            <button
                                className={classes.promoBannerButton}
                                style={{
                                    background: promoBanner.backgroundColor
                                        ? promoBanner.backgroundColor
                                        : '#592063',
                                }}
                            >
                                {promoBanner.buttonText}
                            </button>
                        )}
                    </div>
                </a>

                {promoBanners.length > 0 && (
                    <button
                        className={classes.promoBannerNavigationButton}
                        onClick={showNextBanner}
                    >
                        <ChevronRightIcon fontSize="large" />
                    </button>
                )}
            </div>
        )
    }

    return (
        <div className={classes.promoBanner}>
            {renderBannerContent(promoBanner)}
        </div>
    )
}

export default PromoBannerCarousel
