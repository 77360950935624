import React, { Suspense, lazy, useEffect } from 'react'
import { ErrorBoundary } from '@sentry/react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    withRouter,
} from 'react-router-dom'
import { compose } from 'redux'
import WithErrors from './hocs/WithErrors'
import * as Sentry from '@sentry/react'
import GcgCircularProgress from './components/Loaders/GcgCircularProgress'
import StoreReviewListPortal from './components/StoreReviews/StoreReviewListPortal'
import CartDrawerPortal from './components/Base/CartDrawer/CartDrawerPortal'
import HeaderPortal from './components/Base/Layout/HeaderPortal'
import isEmpty from 'lodash/isEmpty'
import has from 'lodash/has'
import { SMB_PLACE_ORDER } from './routes'
import * as fullstory from '@fullstory/browser'
import Ravelin from './components/Ravelin/Ravelin'
import {setupSardine} from "@sardine-ai/web-sdk";
import {getCookie} from "./utility";


// Only init sentry if a release version is present, this indicates we are in stage/prod.
Sentry.init({
    dsn: 'https://75761b4f5a034e87befcca69ee162529@o367719.ingest.sentry.io/5172011',
    environment: window.React?.environment,
    release: process.env.REACT_APP_RELEASE_VERSION,
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:2',
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
    ],
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // safari extensions
        /safari-extension\//i,
        // Other plugins
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /https:\/\/www\.googletagmanager\.com\/gtag\/js\//i, //ga tracking
    ],
})

const cartOpen = () => {
    window.openCartDrawer()
}

async function fullstoryInit() {
    if (!window.React?.fullstory_org_id) {
        return
    }
    fullstory.init({
        orgId: window.React?.fullstory_org_id,
        devMode: window.React?.environment === 'dev',
    })
}

const BuyGiftCards = lazy(() =>
    import(
        './components/BuyCards/BuyGiftCards' /* webpackChunkName: "buy-gift-cards" */
        )
)

const HomePage = lazy(() =>
    import('./components/HomePage/HomePage' /* webpackChunkName: "home-page" */)
)

const LegitPage = lazy(() =>
    import(
        './components/LegitPage/LegitPage' /* webpackChunkName: "legit-page" */
        )
)

const HandwrittenPage = lazy(() =>
    import(
        './components/HandwrittenPage/HandwrittenPage' /* webpackChunkName: "handwritten-page" */
        )
)

const ChristmasHolidayGiftCardsPage = lazy(() =>
    import(
        './components/HolidayLandingPages/ChristmasHolidayGiftCardsPage' /* webpackChunkName: "christmas-holiday-gift-cards-page" */
        )
)

const HanukkahHolidayGiftCardsPage = lazy(() =>
    import(
        './components/HolidayLandingPages/HanukkahHolidayGiftCardsPage' /* webpackChunkName: "hanukkah-holiday-gift-cards-page" */
        )
)

const DiwaliHolidayGiftCardsPage = lazy(() =>
    import(
        './components/HolidayLandingPages/DiwaliHolidayGiftCardsPage' /* webpackChunkName: "diwali-holiday-gift-cards-page" */
        )
)

const KwanzaaHolidayGiftCardsPage = lazy(() =>
    import(
        './components/HolidayLandingPages/KwanzaaHolidayGiftCardsPage' /* webpackChunkName: "kwanzaa-holiday-gift-cards-page" */
        )
)

const AffiliatePage = lazy(() =>
    import(
        './components/AffiliatePage/AffiliatePage' /* webpackChunkName: "affiliate-page" */
        )
)

const OpenLoopCards = lazy(() =>
    import(
        './components/BuyOpenLoop/BuyOpenLoopCards' /* webpackChunkName: "buy-open-loop-cards" */
        )
)

const OrderStatus = lazy(() =>
    import(
        './components/BuyCards/OrderStatus' /* webpackChunkName: "order-status" */
        )
)

const SmallBusinessApply = lazy(() =>
    import(
        './components/SmallBusiness/SmallBusinessApply' /* webpackChunkName: "smb-apply" */
        )
)

const AlgoliaSearch = lazy(() =>
    import(
        './components/Algolia/AlgoliaSearch' /* webpackChunkName: "algolia-search" */
        )
)

const AlgoliaSearchLocal = lazy(() =>
    import(
        './components/Algolia/AlgoliaSearchLocal' /* webpackChunkName: "algolia-search-local" */
        )
)

const SMBOrderFlow = lazy(() =>
    import('./containers/SMBOrderFlow' /* webpackChunkName: "smb-order-flow" */)
)

const SMBOrderFlowLanding = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/SMBOrderFlowLanding' /* webpackChunkName: "smb-order-flow-landing" */
        )
)

const SMBOrderFlowProductDetails = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/SMBOrderFlowProductDetails' /* webpackChunkName: "smb-order-flow-product-details" */
        )
)

const SMBOrderFlowCustomizeProduct = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/SMBOrderFlowCustomizeProduct' /* webpackChunkName: "smb-order-flow-customize-product" */
        )
)

const SMBOrderFlowPlaceOrder = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/Components/Checkout/StripeCheckoutWrapper' /* webpackChunkName: "smb-order-flow-place-order" */
        )
)

const SMBOrderFlowConfirmation = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/SMBOrderFlowConfirmation' /* webpackChunkName: "smb-order-flow-confirmation" */
        )
)

const SMBOrderFlowCancelled = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/SMBOrderFlowOrderCancelled' /* webpackChunkName: "smb-order-flow-cancelled" */
        )
)

const SMBOrderDetails = lazy(() =>
    import(
        './components/SmallBusiness/SMBOrderDetails' /* webpackChunkName: "smb-order-details" */
        )
)

const OrderDetails = lazy(() =>
    import(
        './components/OrderDetails/OrderDetails' /* webpackChunkName: "order-details" */
        )
)

const PLALandingPage = lazy(() =>
    import(
        './containers/PLALandingPage' /* webpackChunkName: "pla-landing-page-container" */
        )
)

const VisaPLAPage = lazy(() =>
    import(
        './components/PLALandingPages/VisaPLAPage' /* webpackChunkName: "visa-pla-landing-page" */
        )
)

const SmbOrderFlowExpressPage = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/SmbOrderFlowExpress/SmbOrderFlowExpress' /* webpackChunkName: "smb-order-flow-express" */
        )
)

const createACardProps = {
    siteName: window.React?.siteName || '',
    buildACardConfig: window.React?.buildACardConfig
        ? JSON.parse(window.React?.buildACardConfig)
        : {},
    baseUrl: window.React?.base_url || '',
    brandLogoUrl: window.React?.brandLogoUrl || '',
    carrierImageUrl: window.React?.carrierImageUrl || '',
    carrierImageWithCardUrl: window.React?.carrierImageWithCardUrl || '',
    imageGuidelinesUrl: window.React?.imageGuidelinesUrl || '',
    faqUrl: window.React?.faqUrl || '',
    customQuantity: window.React?.customQuantity || false,
    maxQuantity: window.React?.maxQuantity || 20,
    availableShippingMethods: window.React?.availableShippingMethods
        ? JSON.parse(window.React?.availableShippingMethods)
        : [],
    cardNetworks: window.React?.availableCardNetworks
        ? JSON.parse(window.React?.availableCardNetworks)
        : [],
    addToCartUrl: window.React?.addToCartUrl || '',
    checkoutUrl: window.React?.checkoutUrl || '',
    addToCartCallback: (response) => {
        window.location = window.React?.checkoutUrl || '/'
    },
    imageUploadUrl: window.React?.imageUploadUrl || '',
    includeShipping: window.React?.includeShipping || true,
    addressVerificationEnabled:
        window.React?.addressVerificationEnabled || false,
}

const CreateACard = lazy(() =>
    import(
        './containers/CreateACard' /* webpackChunkName: "create-a-card-container" */
        )
)

const MastercardPLAPage = lazy(() =>
    import(
        './components/PLALandingPages/MastercardPLAPage' /* webpackChunkName: "mastercard-pla-landing-page" */
        )
)

const CreateACardLanding = lazy(() =>
    import(
        './components/CreateACard/Landing/CreateACardLanding' /* webpackChunkName: "create-a-card-landing" */
        )
)

const CreateACardPersonalization = lazy(() =>
    import(
        './components/CreateACard/Carrier/CreateACardCarrier' /* webpackChunkName: "create-a-card-carrier" */
        )
)

const CreateACardReview = lazy(() =>
    import(
        './components/CreateACard/CreateACardReview' /* webpackChunkName: "create-a-card-review" */
        )
)

const SmbOrderFlowFooterContent = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/Layout/FooterContent' /* webpackChunkName: "smb-order-flow-footer-content" */
        )
)

const SMBOrderFlowLogin = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/SMBOrderFlowLogin' /* webpackChunkName: "smb-order-flow-login" */
        )
)

const SMBOrderFlowRegistration = lazy(() =>
    import(
        './components/SmallBusiness/OrderFlow/SMBOrderFlowRegistration' /* webpackChunkName: "smb-order-flow-registration" */
        )
)

const SMBCobrandCards = lazy(() =>
    import(
        './components/SmallBusiness/SMBCobrandCards' /* webpackChunkName: "smb-cobrand-cards" */
        )
)

const ReviewsPage = lazy(() =>
    import(
        './components/Base/ReviewsPage' /* webpackChunkName: "reviews-page" */
        )
)

const GiftCardBalanceCheckPage = lazy(() =>
    import(
        './components/GiftCardBalanceCheck/GiftCardBalanceCheckPage' /* webpackChunkName: "gift-card-balance-check-page" */
        )
)

const GiftCardAppPage = lazy(() =>
    import(
        './components/Base/GiftCardAppPage' /* webpackChunkName: "gift-card-app-page" */
        )
)

const FiservConsumerCheckoutPage = lazy(() =>
    import(
        './components/ConsumerCheckout/FiservConsumerCheckoutPageWrapper' /* webpackChunkName: "fiserv-consumer-checkout-page" */
        )
)

const ConsumerCheckoutPageFooterContent = lazy(() =>
    import(
        './components/ConsumerCheckout/Layout/FooterContent' /* webpackChunkName: "consumer-checkout-page-footer-content" */
        )
)

const GiftCardOccasions = lazy(() =>
    import(
        './containers/GiftCardOccasions' /* webpackChunkName: "gift-card-occasions" */
        )
)

const GreetingCardLandingPage = lazy(() =>
    import(
        './components/GreetingCard/GreetingCardLandingPage' /* webpackChunkName: "greeting-card-landing-page" */
        )
)

const DesignerGiftCardsPage = lazy(() =>
    import(
        './containers/DesignerGiftCardsPage' /* webpackChunkName: "designer-gift-cards" */
        )
)

const OpenLoopActivatePage = lazy(() =>
    import(
        './containers/OpenLoopActivate' /* webpackChunkName: "open-loop-activate-check-balance" */
        )
)

const OpenLoopCheckBalancePage = lazy(() =>
    import(
        './containers/OpenLoopCheckBalance' /* webpackChunkName: "open-loop-activate-check-balance" */
        )
)

const OpenLoopCardErrorPage = lazy(() =>
    import(
        './components/OpenLoopCardErrorPage/OpenLoopCardErrorPage' /* webpackChunkName: "card-error" */
        )
)

const OpenLoopBalanceDetailsPage = lazy(() =>
    import(
        './containers/OpenLoopBalanceDetails' /* webpackChunkName: "open-loop-activate-check-balance" */
        )
)

const UpdateFailedEmailRecipient = lazy(() =>
    import(
        './components/UpdateFailedEmailRecipient/UpdateFailedEmailRecipient' /* webpackChunkName: "update-failed-email-recipient" */
        )
)

const NewsletterSignupPage = lazy(() =>
    import(
        './components/NewsletterSignupPage/NewsletterSignupPage' /* webpackChunkName: "newsletter-signup-page" */
        )
)

const RedeemGift = lazy(() =>
    import(
        './components/RedeemGift/RedeemGift' /* webpackChunkName: "redeem-gift" */
        )
)

const RedeemGypGift = lazy(() =>
    import(
        './components/RedeemGypGift/RedeemGypGift' /* webpackChunkName: "redeem-gyp-gift" */
        )
)

const UpdatePaymentMethod = lazy(() =>
    import(
        './components/UpdatePaymentMethod/FiservUpdatePaymentMethod' /* webpackChunkName: "update-payment-method" */
        )
)

const OpenLoopVirtualExchange = lazy(() =>
    import(
        './containers/OpenLoopVirtualExchange' /* webpackChunkName: "open-loop-virtual-exchange" */
        )
)

const LazyLoad = ({ component: Component, ...rest }) => (
    <>
        <Suspense fallback={<GcgCircularProgress />}>
            <Component {...rest} />
        </Suspense>
    </>
)

const App = () => {
    useEffect(() => {
        fullstoryInit()
        if( window.React?.sardine_events_enabled == 0){
            return;
        }
        const isProd = window.React?.environment === 'prod';
        setupSardine({
            clientId: window.React?.sardine_client_id,
            environment: isProd ? 'production' : 'sandbox',
            apiSubdomain: isProd ? "sapi.giftcardgranny.com" : "sapi.giftcardgrannystage.com",
            pixelSubdomain: isProd ? "sp.giftcardgranny.com" : "sp.giftcardgrannystage.com"
        })
    }, [])

    const LazyBuyGiftCards = (props) => (
        <LazyLoad component={BuyGiftCards} cartOpen={cartOpen} {...props} />
    )

    const LazyHomePage = (props) => <LazyLoad component={HomePage} {...props} />

    const LazyLegitPage = (props) => (
        <LazyLoad component={LegitPage} {...props} />
    )

    const LazyHandwrittenPage = (props) => (
        <LazyLoad component={HandwrittenPage} {...props} />
    )

    const LazyChristmasHolidayGiftCardsPage = (props) => (
        <LazyLoad component={ChristmasHolidayGiftCardsPage} {...props} />
    )

    const LazyHanukkahHolidayGiftCardsPage = (props) => (
        <LazyLoad component={HanukkahHolidayGiftCardsPage} {...props} />
    )

    const LazyDiwaliHolidayGiftCardsPage = (props) => (
        <LazyLoad component={DiwaliHolidayGiftCardsPage} {...props} />
    )

    const LazyKwanzaaHolidayGiftCardsPage = (props) => (
        <LazyLoad component={KwanzaaHolidayGiftCardsPage} {...props} />
    )

    const LazyAffiliatePage = (props) => (
        <LazyLoad component={AffiliatePage} {...props} />
    )

    const LazyOpenLoopCards = (props) => (
        <LazyLoad component={OpenLoopCards} cartOpen={cartOpen} {...props} />
    )

    const LazyOrderStatus = (props) => (
        <LazyLoad component={OrderStatus} {...props} />
    )

    const LazySmallBusinessApply = (props) => (
        <LazyLoad component={SmallBusinessApply} {...props} />
    )

    const LazyAlgoliaSearch = (props) => (
        <LazyLoad component={AlgoliaSearch} {...props} />
    )

    const LazyAlgoliaSearchLocal = (props) => (
        <LazyLoad component={AlgoliaSearchLocal} {...props} />
    )

    const LazySMBOrderFlow = (props) => (
        <LazyLoad component={SMBOrderFlow} {...props} />
    )

    const LazySMBOrderFlowLanding = (props) => (
        <LazyLoad component={SMBOrderFlowLanding} {...props} />
    )

    const LazySMBOrderFlowProductDetails = (props) => (
        <LazyLoad component={SMBOrderFlowProductDetails} {...props} />
    )

    const LazySMBOrderFlowCustomizeProduct = (props) => (
        <LazyLoad component={SMBOrderFlowCustomizeProduct} {...props} />
    )

    const LazySMBOrderFlowPlaceOrder = (props) => (
        <LazyLoad component={SMBOrderFlowPlaceOrder} {...props} />
    )

    const LazySMBOrderFlowConfirmation = (props) => (
        <LazyLoad component={SMBOrderFlowConfirmation} {...props} />
    )

    const LazySMBOrderFlowCancelled = (props) => (
        <LazyLoad component={SMBOrderFlowCancelled} {...props} />
    )

    const LazySmbOrderFlowExpressPage = (props) => (
        <LazyLoad component={SmbOrderFlowExpressPage} {...props} />
    )

    const LazySMBOrderDetails = (props) => (
        <LazyLoad component={SMBOrderDetails} {...props} />
    )

    const LazyOrderDetails = (props) => (
        <LazyLoad component={OrderDetails} {...props} />
    )

    const LazyPLALandingPage = (props) => (
        <LazyLoad component={PLALandingPage} {...props} />
    )

    const LazyVisaPLAPage = (props) => (
        <LazyLoad component={VisaPLAPage} {...props} />
    )

    const LazyMastercardPLAPage = (props) => (
        <LazyLoad component={MastercardPLAPage} {...props} />
    )

    const LazyCreateACard = (props) => (
        <LazyLoad component={CreateACard} {...props} />
    )

    const LazyCreateACardLanding = (props) => (
        <LazyLoad component={CreateACardLanding} {...props} />
    )

    const LazyCreateACardPersonalization = (props) => (
        <LazyLoad component={CreateACardPersonalization} {...props} />
    )

    const LazyCreateACardReview = (props) => (
        <LazyLoad component={CreateACardReview} {...props} />
    )

    const LazySmbOrderFlowFooterContent = (props) => (
        <LazyLoad component={SmbOrderFlowFooterContent} {...props} />
    )

    const smbOrderFlowProps = {
        siteName: window.React?.siteName || '',
        baseUrl: window.React?.base_url || '',
        brandLogoUrl: window.React?.brandLogoUrl || '',
        cobrandProductId: window.React?.cobrandProductId || '',
        footerContent: (
            <LazySmbOrderFlowFooterContent
                baseUrl={window.React?.base_url || ''}
            />
        ),
    }

    const LazySMBOrderFlowLogin = () => (
        <LazyLoad component={SMBOrderFlowLogin} {...smbOrderFlowProps} />
    )

    const LazySMBOrderFlowRegistration = () => (
        <LazyLoad component={SMBOrderFlowRegistration} {...smbOrderFlowProps} />
    )

    const LazySMBCobrandCards = (props) => (
        <LazyLoad component={SMBCobrandCards} {...props} />
    )

    const LazyReviewsPage = (props) => (
        <LazyLoad component={ReviewsPage} {...props} />
    )

    const LazyGiftCardBalanceCheckPage = (props) => (
        <LazyLoad component={GiftCardBalanceCheckPage} {...props} />
    )

    const LazyGiftCardAppPage = (props) => (
        <LazyLoad component={GiftCardAppPage} {...props} />
    )

    const LazyFiservConsumerCheckoutPage = (props) => (
        <LazyLoad component={FiservConsumerCheckoutPage} {...props} />
    )

    const LazyConsumerCheckoutPageFooterContent = (props) => (
        <LazyLoad component={ConsumerCheckoutPageFooterContent} {...props} />
    )

    const LazyGiftCardOccasions = (props) => (
        <LazyLoad component={GiftCardOccasions} {...props} />
    )

    const LazyGreetingCardLandingPage = (props) => (
        <LazyLoad component={GreetingCardLandingPage} {...props} />
    )

    const consumerCheckoutPageProps = {
        siteName: window.React?.siteName || '',
        baseUrl: window.React?.base_url || '',
        brandLogoUrl: window.React?.brandLogoUrl || '',
        footerContent: (
            <LazyConsumerCheckoutPageFooterContent
                baseUrl={window.React?.base_url || ''}
            />
        ),
        facebookLoginUrl: window.React?.facebookLoginUrl || '',
        googleLoginUrl: window.React?.googleLoginUrl || '',
        recaptchaEnabled: window.React?.recaptcha_enabled || '',
        recaptchaSiteKey: window.React?.recaptcha_site_key || '',
    }

    const LazyDesignerGiftCardsPage = (props) => (
        <LazyLoad component={DesignerGiftCardsPage} {...props} />
    )

    const LazyOpenLoopActivatePage = (props) => (
        <LazyLoad component={OpenLoopActivatePage} {...props} />
    )

    const LazyOpenLoopCheckBalancePage = (props) => (
        <LazyLoad component={OpenLoopCheckBalancePage} {...props} />
    )

    const LazyOpenLoopBalanceDetailsPage = (props) => (
        <LazyLoad component={OpenLoopBalanceDetailsPage} {...props} />
    )

    const LazyOpenLoopCardErrorPage = (props) => (
        <LazyLoad component={OpenLoopCardErrorPage} {...props} />
    )

    const LazyUpdateRecipientPage = (props) => (
        <LazyLoad component={UpdateFailedEmailRecipient} {...props} />
    )

    const LazyNewsletterSignupPage = (props) => (
        <LazyLoad component={NewsletterSignupPage} {...props} />
    )

    const redeemGiftProps = {
        hash: window.React?.hash || '',
        merchantName: window.React?.merchantName
            ? decodeURIComponent(window.React?.merchantName)
            : '',
        merchantImageUrl: window.React?.merchantImageUrl || '',
        merchantTermsUrl: window.React?.merchantTermsUrl || '',
        cartTermsUrl: window.React?.cartTermsUrl || '',
        baseUrl: window.React?.base_url || '',
        redemptionError: window.React?.redemptionError,
    }

    const LazyRedeemGift = (props) => (
        <LazyLoad component={RedeemGift} {...props} />
    )

    const redeemGypGiftProps = {
        uuid: window.React?.uuid || '',
        email: window.React?.email || '',
    }

    const LazyRedeemGypGift = (props) => (
        <LazyLoad component={RedeemGypGift} {...props} />
    )

    const LazyUpdatePaymentMethod = (props) => (
        <LazyLoad component={UpdatePaymentMethod} {...props} />
    )

    const LazyOpenLoopVirtualExchange = (props) => (
        <LazyLoad component={OpenLoopVirtualExchange} {...props} />
    )

    return (
        <ErrorBoundary>
            <Ravelin />
            <Router>
                <Switch>
                    <Route exact path={'/'} component={LazyHomePage} />
                    <Route exact path={'/legit/'} component={LazyLegitPage} />
                    <Route
                        exact
                        path={'/custom-greeting-cards/handwritten/'}
                        component={LazyHandwrittenPage}
                    />
                    <Route
                        exact
                        path={'/christmas-holiday-gift-cards/'}
                        component={LazyChristmasHolidayGiftCardsPage}
                    />
                    <Route
                        exact
                        path={'/hanukkah-holiday-gift-cards/'}
                        component={LazyHanukkahHolidayGiftCardsPage}
                    />
                    <Route
                        exact
                        path={'/diwali-holiday-gift-cards/'}
                        component={LazyDiwaliHolidayGiftCardsPage}
                    />
                    <Route
                        exact
                        path={'/kwanzaa-holiday-gift-cards/'}
                        component={LazyKwanzaaHolidayGiftCardsPage}
                    />
                    <Route
                        exact
                        path={'/affiliate/'}
                        component={LazyAffiliatePage}
                    />
                    <Route exact path={'/cart/'}>
                        <LazyFiservConsumerCheckoutPage
                            {...consumerCheckoutPageProps}
                        />
                    </Route>
                    <Route
                        exact
                        path={'/reviews/'}
                        component={LazyReviewsPage}
                    />
                    <Route
                        exact
                        path={'/gift-card-app/'}
                        component={LazyGiftCardAppPage}
                    />
                    <Route
                        exact
                        path={'/gift-card-balance-check/'}
                        component={LazyGiftCardBalanceCheckPage}
                    />
                    <Route
                        exact
                        path={'/visa-gift-cards/predesign/'}
                        component={LazyOpenLoopCards}
                    />
                    <Route
                        exact
                        path={'/visa-gift-cards/predesign/:slug/'}
                        component={LazyOpenLoopCards}
                    />
                    <Route
                        exact
                        path={'/mastercard-gift-cards/predesign/'}
                        component={LazyOpenLoopCards}
                    />
                    <Route
                        exact
                        path={'/mastercard-gift-cards/predesign/:slug/'}
                        component={LazyOpenLoopCards}
                    />
                    <Route
                        path={'/buy-gift-cards/:slug/'}
                        component={LazyBuyGiftCards}
                    />
                    <Route
                        exact
                        path={'/buy-gift-cards/'}
                        component={LazyAlgoliaSearch}
                    />
                    {!isEmpty(createACardProps.buildACardConfig) ? (
                        <Route path={'/build-a-card/'}>
                            <LazyCreateACard {...createACardProps}>
                                <Switch>
                                    <Route
                                        exact
                                        path={'/build-a-card/'}
                                        component={LazyCreateACardLanding}
                                    />
                                    <Route
                                        exact
                                        path={'/build-a-card/carrier/'}
                                        component={
                                            LazyCreateACardPersonalization
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/build-a-card/review/'}
                                        component={LazyCreateACardReview}
                                    />
                                    <Route
                                        exact
                                        path={'/build-a-card/:slug/'}
                                        component={LazyCreateACardLanding}
                                    />
                                </Switch>
                            </LazyCreateACard>
                        </Route>
                    ) : null}
                    {!isEmpty(createACardProps.buildACardConfig) ? (
                        <Route path={'/visa-gift-cards/buildacard/'}>
                            <LazyCreateACard {...createACardProps}>
                                <Switch>
                                    <Route
                                        exact
                                        path={'/visa-gift-cards/buildacard/'}
                                        component={LazyCreateACardLanding}
                                    />
                                    <Route
                                        exact
                                        path={
                                            '/visa-gift-cards/buildacard/carrier/'
                                        }
                                        component={
                                            LazyCreateACardPersonalization
                                        }
                                    />
                                    <Route
                                        exact
                                        path={
                                            '/visa-gift-cards/buildacard/review/'
                                        }
                                        component={LazyCreateACardReview}
                                    />
                                    <Route
                                        exact
                                        path={
                                            '/visa-gift-cards/buildacard/:slug/'
                                        }
                                        component={LazyCreateACardLanding}
                                    />
                                </Switch>
                            </LazyCreateACard>
                        </Route>
                    ) : null}
                    {!isEmpty(createACardProps.buildACardConfig) ? (
                        <Route path={'/mastercard-gift-cards/buildacard/'}>
                            <LazyCreateACard {...createACardProps}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            '/mastercard-gift-cards/buildacard/'
                                        }
                                        component={LazyCreateACardLanding}
                                    />
                                    <Route
                                        exact
                                        path={
                                            '/mastercard-gift-cards/buildacard/carrier/'
                                        }
                                        component={
                                            LazyCreateACardPersonalization
                                        }
                                    />
                                    <Route
                                        exact
                                        path={
                                            '/mastercard-gift-cards/buildacard/review/'
                                        }
                                        component={LazyCreateACardReview}
                                    />
                                    <Route
                                        exact
                                        path={
                                            '/mastercard-gift-cards/buildacard/:slug/'
                                        }
                                        component={LazyCreateACardLanding}
                                    />
                                </Switch>
                            </LazyCreateACard>
                        </Route>
                    ) : null}
                    <Route
                        exact
                        path={'/e-gift-cards/'}
                        component={LazyAlgoliaSearch}
                    />
                    <Route
                        exact
                        path={'/e-gift-cards-discounts/'}
                        component={LazyAlgoliaSearch}
                    />
                    <Route
                        exact
                        path={'/visa-gift-cards/virtual/'}
                        component={LazyOpenLoopCards}
                        cartOpen={cartOpen}
                    />
                    <Route
                        exact
                        path={'/visa-gift-cards/virtual/:slug/'}
                        component={LazyOpenLoopCards}
                        cartOpen={cartOpen}
                    />
                    <Route
                        exact
                        path={'/mastercard-gift-cards/virtual/'}
                        component={LazyOpenLoopCards}
                        cartOpen={cartOpen}
                    />
                    <Route
                        exact
                        path={'/mastercard-gift-cards/virtual/:slug/'}
                        component={LazyOpenLoopCards}
                        cartOpen={cartOpen}
                    />
                    <Route path={'/visa-gift-cards/'} />
                    <Route path={'/mastercard-gift-cards/'} />
                    <Route path={'/bulk-gift-cards/'} />
                    <Route path={'/free-gift-cards/'} />
                    <Route
                        path={'/:slug-gift-cards/'}
                        component={LazyAlgoliaSearch}
                    />
                    <Route
                        path={'/buy-local-gift-cards/'}
                        component={LazyAlgoliaSearchLocal}
                    />
                    <Route
                        exact
                        path={'/orderstatus/'}
                        component={LazyOrderStatus}
                    />
                    <Route
                        exact
                        path={'/additional-verification-request/'}
                        component={LazySmallBusinessApply}
                    />
                    <Route
                        exact
                        path={'/gift-cards-for-business/login/'}
                        component={LazySMBOrderFlowLogin}
                    />
                    <Route
                        exact
                        path={'/gift-cards-for-business/registration/'}
                        component={LazySMBOrderFlowRegistration}
                    />
                    <Route path={'/gift-cards-for-business/'}>
                        <LazySMBOrderFlow {...smbOrderFlowProps}>
                            <Route
                                exact
                                path={'/gift-cards-for-business/'}
                                component={LazySMBOrderFlowLanding}
                            />
                            <Route
                                exact
                                path={'/gift-cards-for-business/details/'}
                                component={LazySMBOrderFlowProductDetails}
                            />
                            <Route
                                exact
                                path={'/gift-cards-for-business/customize/'}
                                component={LazySMBOrderFlowCustomizeProduct}
                            />
                            <Route
                                exact
                                path={SMB_PLACE_ORDER}
                                component={LazySMBOrderFlowPlaceOrder}
                            />
                            <Route
                                exact
                                path={'/gift-cards-for-business/confirmation/'}
                                component={LazySMBOrderFlowConfirmation}
                            />
                            <Route
                                exact
                                path={
                                    '/gift-cards-for-business/order-cancelled/'
                                }
                                component={LazySMBOrderFlowCancelled}
                            />
                            <Route
                                exact
                                path={'/gift-cards-for-business/express/'}
                                component={LazySmbOrderFlowExpressPage}
                            />
                        </LazySMBOrderFlow>
                    </Route>
                    {window.React &&
                    has(window.React, 'orderNumber') &&
                    window.React?.isSmbOrder && (
                        <Route
                            exact
                            path={'/account/orders/:orderNumber/'}
                            component={LazySMBOrderDetails}
                        />
                    )}
                    {window.React &&
                    has(window.React, 'orderNumber') &&
                    !window.React?.isSmbOrder && (
                        <Route
                            exact
                            path={'/account/orders/:orderNumber/'}
                            component={LazyOrderDetails}
                        />
                    )}
                    <Route
                        path={[
                            '/buy-visa-gift-card/',
                            '/buy-mastercard-gift-card/',
                        ]}
                    >
                        <LazyPLALandingPage>
                            <Route
                                exact
                                path={'/buy-visa-gift-card/'}
                                component={LazyVisaPLAPage}
                            />
                            <Route
                                exact
                                path={'/buy-mastercard-gift-card/'}
                                component={LazyMastercardPLAPage}
                            />
                        </LazyPLALandingPage>
                    </Route>
                    <Route
                        exact
                        path={'/account/cobrand-cards/'}
                        component={LazySMBCobrandCards}
                    />
                    <Route
                        exact
                        path={'/gift-cards-for-all-occasions/'}
                        component={LazyGiftCardOccasions}
                    />
                    <Route
                        exact
                        path={'/designer-visa-gift-card/'}
                        component={LazyDesignerGiftCardsPage}
                    />
                    <Route
                        exact
                        path={'/designer-mastercard-gift-card/'}
                        component={LazyDesignerGiftCardsPage}
                    />
                    <Route
                        exact
                        path={'/custom-greeting-cards/'}
                        component={LazyGreetingCardLandingPage}
                    />
                    <Route
                        exact
                        path={[
                            '/check-balance/thank-you/',
                            '/activate/thank-you/',
                        ]}
                        component={LazyOpenLoopBalanceDetailsPage}
                    />
                    <Route
                        exact
                        path={'/activate/'}
                        component={LazyOpenLoopActivatePage}
                    />
                    <Route
                        exact
                        path={'/check-balance/'}
                        component={LazyOpenLoopCheckBalancePage}
                    />
                    <Route
                        exact
                        path={'/card-error/'}
                        component={LazyOpenLoopCardErrorPage}
                    />
                    <Route
                        exact
                        path={'/update-recipient/:hash/'}
                        component={LazyUpdateRecipientPage}
                    />
                    <Route
                        exact
                        path={'/newsletter/signup/'}
                        component={LazyNewsletterSignupPage}
                    />
                    <Route exact path={'/redeem-gift/:hash/'}>
                        <LazyRedeemGift {...redeemGiftProps} />
                    </Route>
                    <Route exact path={'/redeem-linked-gift/:uniqueid/'}>
                        <LazyRedeemGypGift {...redeemGypGiftProps} />
                    </Route>
                    <Route
                        exact
                        path={'/account/payment/'}
                        component={LazyUpdatePaymentMethod}
                    />
                    <Route
                        exact
                        path={'/redeem-visa-gift/:plainTextHash/exchange'}
                        component={LazyOpenLoopVirtualExchange}
                    />
                    <Route
                        exact
                        path={
                            '/redeem-mastercard-gift/:plainTextHash/exchange/'
                        }
                        component={LazyOpenLoopVirtualExchange}
                    />
                </Switch>
            </Router>
            <Suspense fallback={<div>Loading...</div>}>
                <StoreReviewListPortal />
                <CartDrawerPortal />
                <HeaderPortal />
            </Suspense>
        </ErrorBoundary>
    )
}

export default compose(WithErrors, withRouter)(App)
