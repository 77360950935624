import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const DesktopMenuButton = ({ buttonData, classes }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'end',
                justifyContent:
                    buttonData.style === 'centered' ? 'center' : 'flex-end',
                margin:
                    buttonData.style === 'centered'
                        ? '1rem  0 0 0'
                        : '0 1.5rem 0 1rem',
            }}
        >
            <Button
                href={buttonData.href}
                onClick={() => pushToDataLayer(buttonData.analyticsEvent)}
                className={classes.menuDropdownButton}
                color="primary"
                fullWidth
                style={{
                    display: 'inline-block',
                    maxWidth: 'fit-content',
                }}
            >
                <Typography className={classes.menuDropdownButtonText}>
                    {buttonData.linkText}
                    <ArrowForwardIcon className={classes.popoverButtonIcon} />
                </Typography>
            </Button>
        </div>
    )
}

export default DesktopMenuButton
