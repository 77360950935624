import React, { lazy, Suspense } from 'react'
import { createPortal } from 'react-dom'
import GcgCircularProgress from '../Loaders/GcgCircularProgress'
import baseSiteTheme from '../Base/baseSiteTheme'
import { MuiThemeProvider } from '@material-ui/core'

const LazyStoreReviewList = lazy(() =>
    import(
        './StoreReviewList' /* webpackChunkName: "store-review-list-portal" */
    )
)
const storeReviewMount = document.getElementById('store-review-list-container')

const StoreReviewListPortal = () => {
    if (!storeReviewMount) {
        return false
    }

    function render() {
        return (
            <React.Fragment>
                <Suspense fallback={<GcgCircularProgress />}>
                    <MuiThemeProvider theme={baseSiteTheme}>
                        <LazyStoreReviewList />
                    </MuiThemeProvider>
                </Suspense>
            </React.Fragment>
        )
    }

    return createPortal(render(), storeReviewMount)
}

export default StoreReviewListPortal
