export class BACPersonalizationData {
    carrierTo = null
    carrierMessage = null
    carrierFrom = null
    recipientEmail = null
    applyToAll = null
    carrierType = null

    constructor(
        carrierTo,
        carrierMessage,
        carrierFrom,
        carrierType,
        recipientEmail,
        applyToAll
    ) {
        this.carrierTo = carrierTo
        this.carrierMessage = carrierMessage
        this.carrierType = carrierType
        this.carrierFrom = carrierFrom
        this.recipientEmail = recipientEmail
        this.applyToAll = applyToAll
    }
}
