import Ravelin from 'ravelinjs/core+encrypt';
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

const RavelinComponent = ({children = () => {return null}}) => {
    const [deviceId, setDeviceId] = useState(null);
    const [ravelin, setRavelin] = useState(null);
    const [ready, setReady] = useState(null);

    useEffect(() => {
        async function init() {
            if (!window?.React?.ravelin_publishable_key) {
                setReady(true);
                return;
            }
            try {
                const newRavelin = new Ravelin({
                    key: window?.React?.ravelin_publishable_key
                })
                setRavelin(newRavelin);
                window.RavelinGCG = newRavelin;
                setDeviceId(await newRavelin.core.id());
                setReady(true);
            } catch (error) {
                Sentry.captureException(error);
                setReady(true);
            }
        }
        try {
            init();
        } catch (error) {
            Sentry.captureException(error);
            setReady(true);
        }
    }, []);

    if (!ready) {
        return <></>
    }

    return children({
        ravelin,
        deviceId
    })


}
export default RavelinComponent;