import {
    EGIFT_TYPE,
    PLASTIC_TYPE,
    GTM_LAST_IMPRESSION_CLICKED,
} from '../constants'
import decodeHTML from './decodeHTML'
import { capitalizeFirstLetter } from '../utility'

function pushProductViewToDataLayer(cards, brandName, cardtype) {
    console.log('push product view to data layer')
    const lastImpressionClick = JSON.parse(
        localStorage.getItem(GTM_LAST_IMPRESSION_CLICKED)
    )
    const listName =
        lastImpressionClick &&
        lastImpressionClick.brand === decodeHTML(brandName)
            ? lastImpressionClick.list
            : null

    try {
        window.dataLayer = window.dataLayer || []
        var products = []
        cards.forEach((card) => {
            if (!card.plastic && cardtype === PLASTIC_TYPE) {
                return
            }
            if (card.plastic && cardtype === EGIFT_TYPE) {
                return
            }
            const offerType =
                card.discountPercentage > 0 ? 'Discount' : 'Cash back'
            var productObject = {
                id: brandName + ' ' + offerType,
                name: brandName,
                price: parseFloat(card.price),
                brand: brandName,
                category: offerType,
            }
            if (parseFloat(card.cashBack) > 0) {
                productObject.cashBack = parseFloat(card.cashBack)
            }
            if (card.discountPercentage > 0) {
                productObject.discountPercentage = card.discountPercentage
            }

            products.push(productObject)
        })
        let productDetailObject = {
            event: 'productView',
            ecommerce: {
                detail: {},
            },
        }
        if (listName) {
            productDetailObject.ecommerce.detail.actionField = {
                list: listName,
            }
        }
        products.forEach((product) => {
            productDetailObject.ecommerce.detail.products = product
            window.dataLayer.push(productDetailObject)
        })
    } catch (e) {
        console.error('Error in GTM - GA EEC Product View DL', e)
    }
}

function pushOpenLoopProductViewToDataLayer(
    brandName,
    categoryName,
    isVirtual
) {
    const lastImpressionClick = JSON.parse(
        localStorage.getItem(GTM_LAST_IMPRESSION_CLICKED)
    )
    const listName =
        lastImpressionClick && lastImpressionClick.brand === brandName
            ? lastImpressionClick.list
            : null

    try {
        window.dataLayer = window.dataLayer || []
        const cardType = !isVirtual ? 'physical' : 'eGift'
        const product = {
            id:
                cardType === 'physical'
                    ? brandName + ' Open Loop'
                    : brandName + ' Virtual Open Loop',
            name: brandName,
            category: categoryName,
            brand: brandName,
            cardType: cardType,
        }
        let productDetailObject = {
            event: 'productView',
            ecommerce: {
                detail: {},
            },
        }
        if (listName) {
            productDetailObject.ecommerce.detail.actionField = {
                list: listName,
            }
        } else if (brandName) {
            productDetailObject.ecommerce.detail.actionField = {
                list: `${brandName} Gift Cards`,
            }
        }
        productDetailObject.ecommerce.detail.products = product
        window.dataLayer.push(productDetailObject)
    } catch (e) {
        console.error('Error in GTM - GA EEC Product View DL', e)
    }
}

function pushBuildACardProductViewToDataLayer(brandName) {
    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'productView',
            ecommerce: {
                detail: {
                    actionField: {
                        list: 'Build A Card',
                    },
                    products: [
                        {
                            id: `${brandName} BAC`,
                            name: `${brandName}® Gift Card`,
                            category: 'Open Loop',
                            brand: `${brandName}® Gift Card`,
                            cardType: 'plastic',
                        },
                    ],
                },
            },
        })
    } catch (e) {
        console.error('Error in GTM - GA EEC Product View DL', e)
    }
}

function pushBuildACardAddGiftToCartToDataLayer(brandName, amount, quantity) {
    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'addGiftToCart',
            ecommerce: {
                detail: {
                    actionField: {
                        list: 'Build A Card',
                    },
                    products: [
                        {
                            id: `${brandName} BAC`,
                            name: `${brandName}® Gift Card`,
                            category: 'Open Loop',
                            price: parseFloat(amount.toFixed(2)),
                            value: parseFloat(amount.toFixed(2)),
                            variant: 'Gift',
                            brand: `${brandName}® Gift Card`,
                            cardType: 'plastic',
                            quantity: quantity,
                        },
                    ],
                },
            },
        })
    } catch (e) {
        console.error('Error in GTM - GA EEC Add Gift To Cart DL', e)
    }
}

function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
}

function pushToDataLayer(eventValuesArray, extraData) {
    if (!Array.isArray(eventValuesArray)) return
    let value = null
    if (isNumber(eventValuesArray[4])) {
        value = parseFloat(eventValuesArray[4])
    } else if (
        typeof eventValuesArray[4] === 'string' &&
        eventValuesArray[4] !== ''
    ) {
        value = eventValuesArray[4]
    }

    let eventData = {
        event: eventValuesArray[0],
        eventInfo: {
            category:
                typeof eventValuesArray[1] !== typeof undefined
                    ? eventValuesArray[1]
                    : null,
            action:
                typeof eventValuesArray[2] !== typeof undefined
                    ? eventValuesArray[2]
                    : null,
            label:
                typeof eventValuesArray[3] !== typeof undefined
                    ? eventValuesArray[3]
                    : null,
            value: value,
            nonInteraction:
                typeof eventValuesArray[5] !== typeof undefined &&
                eventValuesArray[5] !== false,
        },
    }

    let additionalProperties = []
    // We can add on some arbitrary values into the eventInfo object by including a string that has a format like "key1:value1;key2:value2" as the 7th array element passed into this function.
    if (
        typeof eventValuesArray[6] !== typeof undefined &&
        eventValuesArray[6]
    ) {
        additionalProperties = eventValuesArray[6].split(';')
        additionalProperties.forEach(function (currentValue) {
            let pairArray = currentValue.split(':')
            eventData.eventInfo[pairArray[0]] = pairArray[1]
        })
    }

    // We can add on some arbitrary values as SIBLINGS the eventInfo object by including a string that has a format like "key1:value1;key2:value2" as the 8th array element passed into this function.
    if (typeof eventValuesArray[7] !== typeof undefined) {
        additionalProperties = eventValuesArray[7].split(';')
        additionalProperties.forEach(function (currentValue) {
            let pairArray = currentValue.split(':')
            eventData[pairArray[0]] = pairArray[1]
        })
    }

    if (extraData) {
        const entries = Object.entries(extraData)
        for (const [key, value] of entries) {
            eventData[key] = isNumber(value) ? parseFloat(value) : value
        }
    }

    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(eventData)
    } catch (e) {
        console.error('Error in DataLayer Push', e)
    }
}

function sendProductClickToDataLayer(
    brandName,
    position,
    maxCashBack,
    maxDiscountPercent,
    listName
) {
    var offerType = maxDiscountPercent === 0 ? 'Cash back' : 'Discount'
    var productObject = {
        id: brandName + ' ' + offerType,
        name: brandName,
        brand: brandName,
        category: offerType,
        position: position,
    }
    if (offerType === 'Cash back') {
        productObject.cashBack = maxCashBack.replace(/\$/g, '')
    } else {
        productObject.discountPercentage = maxDiscountPercent
    }

    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: listName },
                    products: [productObject],
                },
            },
        })
    } catch (e) {
        console.error('Error in GTM - GA EEC Product View DL', e)
    }

    localStorage.setItem(
        'gtm_last_impression_clicked',
        JSON.stringify({ brand: brandName, list: listName })
    )
}

function sendProductImpressionsToDatalayer(merchants, listName) {
    var impressionArray = []
    merchants.forEach((merchant) => {
        var offerType =
            merchant.max_discount_percentage === 0 ? 'Cash back' : 'Discount'
        var productObject = {
            id: merchant.name + ' ' + offerType,
            name: merchant.name,
            brand: merchant.name,
            category: offerType,
            list: listName,
            position: merchant.__position,
        }
        if (merchant.max_discount_percentage === 0) {
            productObject.cashBack = merchant.max_cash_back_value
        } else {
            productObject.discountPercentage = Math.round(
                merchant.max_discount_percentage
            )
        }
        impressionArray.push(productObject)
    })

    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'impressionView',
            ecommerce: {
                impressions: impressionArray,
            },
        })
    } catch (e) {
        console.error('Error in GTM - GA EEC Product View DL', e)
    }
}

function sendCartLoginSignupStatusToDataLayer(eventName, status) {
    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: eventName,
            ecommerce: {
                [eventName]: {
                    success: status,
                },
            },
        })
    } catch (e) {
        console.error(
            `Error in GTM - GA EEC ${eventName} ${capitalizeFirstLetter(
                status
            )} DL`
        )
        console.log(e)
    }
}

function sendSmbCheckoutStepToDataLayer(stepNum, cartItems) {
    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'checkoutStep',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: stepNum,
                    },
                    products: cartItems.map((cartItem) => {
                        const cardNetwork = capitalizeFirstLetter(
                            cartItem.custom_card_details.wcp_product
                                .card_network
                        )
                        const loadValue = cartItem.balance.toFixed(2)
                        const cardType = cartItem.plastic ? 'Plastic' : 'eGift'
                        return {
                            id: `${cardNetwork} Reward Card`,
                            name: `${cardNetwork} Reward Card`,
                            category: 'Business Reward',
                            price: loadValue,
                            brand: cardNetwork,
                            cardType: cardType,
                            quantity: cartItem.quantity,
                        }
                    }),
                },
            },
        })
    } catch (e) {
        console.error('Error in GTM - GA SMB Checkout Step DL', e)
    }
}

function sendSmbPurchaseToDataLayer(order, cards) {
    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: order.order_number,
                        affiliation: 'Online Store',
                        revenue: parseFloat(order.transaction_amount).toFixed(
                            2
                        ),
                        tax: (0).toFixed(2),
                        shipping: parseFloat(order.shipping_fee).toFixed(2),
                    },
                    products: cards.map((card) => {
                        const cardType = card.plastic ? 'Plastic' : 'eGift'
                        return {
                            id: `${card.merchant.name} Reward Card`,
                            name: `${card.merchant.name} Reward Card`,
                            category: 'Business Reward',
                            price: parseFloat(card.balance).toFixed(2),
                            brand: card.merchant.name,
                            cardType: cardType,
                            quantity: 1,
                        }
                    }),
                },
            },
        })
    } catch (e) {
        console.error('Error in GTM - GA SMB Purchase DL', e)
    }
}

function sendSmbProductViewToDataLayer(cardNetwork) {
    try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'productView',
            ecommerce: {
                detail: {
                    actionField: {
                        list: `${cardNetwork} Gift Cards`,
                    },
                    products: [
                        {
                            id: `${cardNetwork} Reward Card`,
                            name: `${cardNetwork} Reward Card`,
                            category: 'Business Reward',
                            price: (0).toFixed(2),
                            brand: cardNetwork,
                        },
                    ],
                },
            },
        })
    } catch (e) {
        console.error('Error in GTM - GA SMB Product View DL', e)
    }
}

/**
 * Pushes addGiftToCart data layer event for all cart items that belong
 * to the given deliveryGroupUuid.
 * @param allCartItems
 * @param deliveryGroupUuid
 */
function sendAddToCartItemsToDataLayer(allCartItems, deliveryGroupUuid) {
    const newCartItems = allCartItems.filter((cartItem) => {
        return cartItem.delivery_group_uuid === deliveryGroupUuid
    })

    const cardNetwork = capitalizeFirstLetter(
        newCartItems[0].custom_card_details.wcp_product.card_network
    )

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'addGiftToCart',
        ecommerce: {
            add: {
                actionField: {
                    list: `${cardNetwork} Gift Cards`,
                    products: newCartItems.map((cartItem) => {
                        const cardNetwork = capitalizeFirstLetter(
                            cartItem.custom_card_details.wcp_product
                                .card_network
                        )
                        const loadValue = cartItem.balance.toFixed(2)
                        const cardType = cartItem.plastic ? 'Plastic' : 'eGift'
                        return {
                            id: `${cardNetwork} Reward Card`,
                            name: `${cardNetwork} Reward Card`,
                            category: 'Business Reward',
                            price: loadValue,
                            brand: cardNetwork,
                            cardType: cardType,
                            quantity: cartItem.quantity,
                        }
                    }),
                },
            },
        },
    })
}

export {
    pushToDataLayer,
    pushProductViewToDataLayer,
    pushOpenLoopProductViewToDataLayer,
    pushBuildACardProductViewToDataLayer,
    pushBuildACardAddGiftToCartToDataLayer,
    sendProductClickToDataLayer,
    sendProductImpressionsToDatalayer,
    sendCartLoginSignupStatusToDataLayer,
    sendSmbCheckoutStepToDataLayer,
    sendSmbPurchaseToDataLayer,
    sendSmbProductViewToDataLayer,
    sendAddToCartItemsToDataLayer,
}
