import { useQuery } from 'react-query'
import * as Sentry from '@sentry/react'
import { getMenuData } from '../api'
import { ERROR_GETTING_MENU_DATA } from '../errorConstants'

const useMenuData = () => {
    const { data, error, isLoading, isSuccess, isError } = useQuery(
        'menuData',
        getMenuData,
        {
            onError: (error) => {
                error.name = ERROR_GETTING_MENU_DATA
                Sentry.captureException(error, {
                    contexts: {
                        data: {
                            errorMessage:
                                'There was a problem getting menu data',
                        },
                    },
                })
            },
            refetchOnWindowFocus: false,
        }
    )
    return { data, error, isLoading, isSuccess, isError }
}

export default useMenuData
