import React from 'react'
import Link from '@material-ui/core/Link'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import MerchantCard from '../../../Cards/MerchantCard'

const DesktopMenuMerchantSet = ({ columnData, classes, innerDropdownKey }) => {
    const isExtraLargeAndBelow = useMediaQuery('(max-width:1500px)')

    const columnItemLink = (linkData) => {
        return (
            <Link
                href={linkData.href}
                title={linkData.linkText}
                underline="hover"
                onClick={() => pushToDataLayer(linkData.analyticsEvent)}
            >
                <MerchantCard
                    width={213 / 2.4}
                    height={134 / 2.4}
                    scaleOnHover
                    title={`${linkData.linkText} Gift Card`}
                    cloudinaryId={linkData.cloudinaryId}
                    imageSrc={linkData.imageUrl}
                    bordered
                />

                <Typography
                    style={{
                        color: 'rgb(64,75,88)',
                        marginBottom: '.25rem',
                        maxWidth: '6rem',
                        margin: '0 auto',
                        lineHeight: '1.25rem',
                    }}
                >
                    {linkData.linkText}
                </Typography>
            </Link>
        )
    }

    const columnItem = (merchantItemData, columnCount, key) => {
        if (merchantItemData.mobileOnly) return
        return (
            <Grid
                item
                xs={Math.floor(12 / columnCount)}
                style={{
                    textAlign: 'center',
                    marginBottom: '.75rem',
                }}
                key={key}
            >
                {columnItemLink(merchantItemData)}
            </Grid>
        )
    }

    return (
        <Grid
            container
            columnspacing={{ xl: 1 }}
            rowspacing={{ xl: 2 }}
            style={{
                minWidth: '18em',
                maxWidth: '32em',
                paddingTop: '1rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
            }}
        >
            {columnData.columnItems
                .slice(0, isExtraLargeAndBelow ? 12 : 16)
                .map((merchantItemData, index) =>
                    columnItem(
                        merchantItemData,
                        columnData.columnCount ?? 2,
                        innerDropdownKey + 'columnItem' + index
                    )
                )}
        </Grid>
    )
}

export default DesktopMenuMerchantSet
