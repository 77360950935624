import { useQuery } from 'react-query'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import { getCart } from '../api'
import { ERROR_GETTING_CART } from '../errorConstants'

const useFetchCart = () => {
    const { data, error, isLoading, isSuccess, isError } = useQuery(
        'cart',
        getCart,
        {
            onError: (error) => {
                error.name = ERROR_GETTING_CART
                Sentry.captureException(error, {
                    contexts: {
                        data: {
                            errorMessage: "Error getting the user's cart",
                        },
                    },
                    user: {
                        id: JSON.stringify(Cookies.get('GCG_CLIENT_USER_ID')),
                    },
                })
            },
            refetchOnWindowFocus: false,
        }
    )

    return { data, error, isLoading, isSuccess, isError }
}

export default useFetchCart
