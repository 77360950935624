import {
    DEFAULT_LOAD_VALUE,
    GREETING_CARD_CARRIER,
    GREETING_CARD_HANDWRYTTEN_CARRIER,
    CARD_TYPE,
    CARRIER_TYPE,
} from '../../../constants'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import {
    CARRIER_STEP,
    STEP_CARRIER,
    STEP_LANDING,
    STEP_REVIEW,
} from '../../../containers/CreateACard'
import { CarrierPersonalizationInfo } from './CarrierPersonalizationInfo'
import { CarrierDeliveryInfo } from './CarrierDeliveryInfo'

export class CustomBuildACard {
    cardId = null
    cardNetwork = ''
    deliveryMethod = ''
    amount = DEFAULT_LOAD_VALUE
    cloudinaryId = ''
    design = ''
    nameOnCard = ''
    messageOnCard = ''
    carrierOption = ''
    greetingCard = null
    personalizationInfo = null
    deliveryInfo = null
    addressGroupId = null
    deliveryGroupUuid = null
    groupShipment = null
    wantToPersonalizeGreetingCard = false
    wantToSelectGreetingCard = false
    wantToBackAfterReviewStep = false

    constructor(
        cardNetwork = '',
        deliveryMethod = '',
        amount = DEFAULT_LOAD_VALUE
    ) {
        this.cardId = uuidv4()
        this.cardNetwork = cardNetwork
        this.deliveryMethod = deliveryMethod
        this.amount = amount
    }

    initializeFromJson(data) {
        this.cardId = data.cardId
        this.cardNetwork = data.cardNetwork
        this.deliveryMethod = data.deliveryMethod
        this.amount = data.amount
        this.cloudinaryId = data.cloudinaryId
        this.design = data.design
        this.nameOnCard = data.nameOnCard
        this.messageOnCard = data.messageOnCard
        this.carrierOption = data.carrierOption
        this.greetingCard = data.greetingCard
        this.addressGroupId = data.addressGroupId
        this.deliveryGroupUuid = data.deliveryGroupUuid
        this.groupShipment = data.groupShipment

        if (!isNull(data.personalizationInfo)) {
            const info = new CarrierPersonalizationInfo()
            info.initializeFromJson(data.personalizationInfo)
            this.personalizationInfo = info
        }

        if (!isNull(data.deliveryInfo)) {
            const info = new CarrierDeliveryInfo()
            info.initializeFromJson(data.deliveryInfo)
            this.deliveryInfo = info
        }

        if (this.personalizationInfo?.carrierType) {
            this.carrierOption = this.personalizationInfo?.carrierType
        }
    }

    setNewCardId() {
        this.cardId = uuidv4()
    }

    isVirtual() {
        return this.deliveryMethod === CARD_TYPE.VIRTUAL
    }

    isGreetingCard() {
        let isGreetingCard =
            !this.isVirtual() &&
            (this.personalizationInfo?.carrierType ===
                CARRIER_TYPE.GREETING_CARD ||
                this.personalizationInfo?.carrierType ===
                    CARRIER_TYPE.GREETING_CARD_HANDWRYTTEN)
        return isGreetingCard
    }

    isComplete() {
        if (!this.isVirtual() && isNull(this.deliveryInfo)) {
            return false
        }

        if (
            isNull(this.personalizationInfo?.carrierMessage) ||
            (this.isVirtual() &&
                isEmpty(this.personalizationInfo?.recipientEmail))
        ) {
            return false
        }

        if (
            !this.isVirtual() &&
            isEmpty(this.personalizationInfo?.carrierType)
        ) {
            return false
        }

        if (this.isGreetingCard() && isNull(this.greetingCard)) {
            return false
        }

        if (!this.isVirtual() && isEmpty(this.nameOnCard)) {
            return false
        }

        if (isEmpty(this.cloudinaryId) && isEmpty(this.design)) {
            return false
        }

        return true
    }

    getStep() {
        if (isEmpty(this.cloudinaryId) && isEmpty(this.design)) {
            return STEP_LANDING
        }

        if (!this.isVirtual() && isEmpty(this.nameOnCard)) {
            return STEP_LANDING
        }

        if (
            !this.isVirtual() &&
            isEmpty(this.personalizationInfo?.carrierType)
        ) {
            return STEP_CARRIER
        }

        if (this.isGreetingCard() && isNull(this.greetingCard)) {
            return STEP_CARRIER
        }

        if (
            isNull(this.personalizationInfo?.carrierMessage) ||
            (this.isVirtual() &&
                isEmpty(this.personalizationInfo?.recipientEmail))
        ) {
            return STEP_CARRIER
        }

        if (!this.isVirtual() && isNull(this.deliveryInfo)) {
            return STEP_CARRIER
        }

        return STEP_REVIEW
    }

    getCarrierBuilderStep() {
        if (this.wantToSelectGreetingCard) {
            return CARRIER_STEP.GREETING_CARD_DESIGN_SELECTION
        }

        if (this.wantToPersonalizeGreetingCard) {
            return CARRIER_STEP.GREETING_CARD_PERSONALIZATION_INFO
        }

        if (this.wantToBackAfterReviewStep) {
            return !isEmpty(this.personalizationInfo?.recipientEmail)
                ? CARRIER_STEP.VIRTUAL_PERSONALIZATION_INFO
                : CARRIER_STEP.DELIVERY_INFO
        }

        if (
            this.isVirtual() &&
            isEmpty(this.personalizationInfo?.recipientEmail)
        ) {
            return CARRIER_STEP.VIRTUAL_PERSONALIZATION_INFO
        }

        if (this.isGreetingCard()) {
            if (isNull(this.greetingCard)) {
                return CARRIER_STEP.GREETING_CARD_DESIGN_SELECTION
            } else if (isNull(this.personalizationInfo?.carrierMessage)) {
                return CARRIER_STEP.GREETING_CARD_PERSONALIZATION_INFO
            } else if (isNull(this.deliveryInfo)) {
                return CARRIER_STEP.DELIVERY_INFO
            }
        } else {
            if (
                !this.isVirtual() &&
                isEmpty(this.personalizationInfo?.carrierMessage)
            ) {
                //In spite of the name, this step also contains paper_carrier preview/message field
                return CARRIER_STEP.VIRTUAL_PERSONALIZATION_INFO
            }
            if (
                (!this.isVirtual() && isNull(this.deliveryInfo)) ||
                isEmpty(this.personalizationInfo?.carrierTo)
            ) {
                return CARRIER_STEP.DELIVERY_INFO
            }
        }
        return CARRIER_STEP.CARRIER_COMPLETE
    }

    shouldShowPersonalizeGreetingCard() {
        return this.wantToPersonalizeGreetingCard
    }

    setWantToPersonalizeGreetingCard(wantToPersonalizeGreetingCard) {
        this.wantToPersonalizeGreetingCard = wantToPersonalizeGreetingCard
    }

    shouldShowSelectGreetingCard() {
        return this.wantToSelectGreetingCard
    }

    setWantToSelectGreetingCard(wantToSelectGreetingCard) {
        this.wantToSelectGreetingCard = wantToSelectGreetingCard
    }

    setWantToBackAfterReviewStep(wantToBackAfterReviewStep) {
        this.wantToBackAfterReviewStep = wantToBackAfterReviewStep
    }
}
