import React, { lazy, Suspense } from 'react'
import GcgCircularProgress from '../../Loaders/GcgCircularProgress'
import { MuiThemeProvider } from '@material-ui/core'
import baseSiteTheme from '../baseSiteTheme'
import { createPortal } from 'react-dom'

const LazyCartDrawer = lazy(() =>
    import('./CartDrawer' /* webpackChunkName: "cart-drawer" */)
)
const cartDrawerMount = document.getElementById('cart') // NOTE: This element needs to be added to fully-react page templates. e.g. src/GCG/BuyBundle/Resources/views/React/fullScreenApp.html.twig

const CartDrawerPortal = () => {
    if (!cartDrawerMount) {
        return false
    }

    function render() {
        return (
            <React.Fragment>
                <Suspense fallback={<GcgCircularProgress />}>
                    <MuiThemeProvider theme={baseSiteTheme}>
                        <LazyCartDrawer />
                    </MuiThemeProvider>
                </Suspense>
            </React.Fragment>
        )
    }

    return createPortal(render(), cartDrawerMount)
}

export default CartDrawerPortal
