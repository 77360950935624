import isEmpty from 'lodash/isEmpty'

export const getCardNetwork = (cardNetworks, cardNetwork) => {
    if (isEmpty(cardNetworks)) {
        return null
    }

    const index = cardNetworks
        .map((network) => network.name.toLowerCase())
        .indexOf(cardNetwork)

    if (index !== -1) {
        return cardNetworks[index]
    }
    return null
}
