export class CarrierPersonalizationInfo {
    carrierTo = ''
    carrierMessage = ''
    carrierFrom = ''
    recipientEmail = ''
    carrierType = ''

    constructor(
        carrierTo = '',
        carrierMessage = '',
        carrierFrom = '',
        carrierType = '',
        recipientEmail = ''
    ) {
        this.carrierTo = carrierTo
        this.carrierMessage = carrierMessage
        this.carrierFrom = carrierFrom
        this.carrierType = carrierType
        this.recipientEmail = recipientEmail
    }

    initializeFromJson(data) {
        this.carrierTo = data.carrierTo
        this.carrierMessage = data.carrierMessage
        this.carrierFrom = data.carrierFrom
        this.carrierType = data.carrierType
        this.recipientEmail = data.recipientEmail
    }
}
