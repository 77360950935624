import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const primaryColor = '#6E1A6A'
const secondaryColor = 'rgb(0,92,89)'
const black = '#222222'
const white = '#FFFFFF'
const grey = '#e0e0e0'
const darkestGrey = '#4F4F4F'
const darkGrey = '#868686'
const errorColor = '#B71C1C'

const breakpoints = createBreakpoints({})

export const createACardTheme = createMuiTheme({
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
        },
        black,
        white,
        grey: {
            main: grey,
            darkestGrey: darkestGrey,
        },
        error: {
            main: errorColor,
        },
    },
    typography: {
        h1: {
            margin: '1rem 0',
            fontSize: '2rem',
            fontWeight: 'bold',
            '@media (min-width:600px)': {
                fontSize: '3rem',
            },
        },
        h2: {
            margin: '1.5rem 0 1rem 0',
            fontSize: '1.5rem',
            fontWeight: 'bold',
        },
        body1: {
            fontSize: '1.1rem',
        },
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                padding: 0,
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: '2.25rem',
            },
        },
        MuiInputBase: {
            input: {
                '&::-webkit-input-placeholder': {
                    color: darkGrey,
                    opacity: 1,
                },
                '&::-moz-input-placeholder': {
                    color: darkGrey,
                    opacity: 1,
                },
                '&::-ms-input-placeholder': {
                    color: darkGrey,
                    opacity: 1,
                },
            },
        },
        MuiButton: {
            root: {
                transition: 'color .01s',
            },
            outlinedPrimary: {
                borderWidth: '2px',
                '&:hover': {
                    borderWidth: '2px',
                },
            },
        },
        MuiSelect: {
            selectMenu: {
                whiteSpace: 'pre-wrap',
            },
        },
    },
})

export const createACardRootStyles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '80vh',
        backgroundColor: 'white',
        paddingBottom: '2rem',
        '& img': {
            display: 'block',
            maxWidth: '100%',
        },
    },
    mainContainer: {
        backgroundColor: 'white',
    },
    backLinkContainer: {
        display: 'flex',
    },
}
