const ContentSeparatorSubNavBar = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="27"
            viewBox="0 0 2 27"
            fill="none"
            className={className}
        >
            <path
                d="M1 1.66156L1 25.6616"
                stroke="#E1E6EF"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
export default ContentSeparatorSubNavBar
