import { compose, createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware, { END } from 'redux-saga'
import createRootReducer from './reducers'
import { createMigrate, persistReducer, createTransform } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'
import { initialState as initialSmbCartState } from './reducers/smbCart'
import { initialState as initialCreateACardState } from './reducers/createACard'
import CobrandCard from './components/Models/CobrandCard'

/*
    See https://github.com/rt2zz/redux-persist#migrations and https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md.
    Here we can keep track of a list of state changes over time by incrementing the version in persistConfig and creating
    a migration key with the same value in the migrations object. For now, this first migration should reset any previous
    DB connections to all updated initial state for both smbCart and createACard reducers. Set debug: true below when testing
    migrations in dev to see whether or not the migration has taken effect.
 */
const migrations = {
    5: (state) => {
        console.log('Migration running...')
        return Promise.resolve({
            smbCart: { ...initialSmbCartState },
            createACard: { ...initialCreateACardState },
        })
    },
}

/**
 * Keep smbCart.cobrandCard but don't persist its state
 * now that we're storing object URLs for previewBlobUrl & rawBlobUrl.
 * An object URL's lifetime is tied to the document in the window on which it was created
 * so on refresh, it will be lost. We're storing object URLs now instead of
 * data URLs for memory's sake.
 * https://stackoverflow.com/a/63845127
 */
const smbCartBlacklist = createTransform(
    null,
    (state, key) => {
        const newState = { ...state }
        newState.cobrandCard = new CobrandCard()
        return newState
    },
    { whitelist: ['smbCart'] }
)

const persistConfig = {
    key: 'root',
    version: 5,
    storage: sessionStorage,
    whitelist: ['smbCart', 'createACard'],
    transforms: [smbCartBlacklist],
    migrate: createMigrate(migrations, { debug: false }),
}

const sagaMiddleware = createSagaMiddleware()

const configureStore = (initialState = {}, history) => {
    const composeEnhancers =
        process.env.NODE_ENV !== 'production' &&
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  shouldHotReload: false,
              })
            : compose

    const middlewares = [sagaMiddleware, routerMiddleware(history)]

    const enhancers = [applyMiddleware(...middlewares)]
    const rootReducer = createRootReducer(history)
    const persistedRootReducer = persistReducer(persistConfig, rootReducer)
    const store = createStore(
        persistedRootReducer,
        composeEnhancers(...enhancers)
    )

    store.runSaga = sagaMiddleware.run
    store.asyncReducers = {}
    store.close = () => store.dispatch(END)

    if (module.hot) {
        // Enable webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            import('./reducers').then((reducerModule) => {
                const createReducers = reducerModule.default
                const nextReducers = createReducers(store.asyncReducers)
                store.replaceReducer(nextReducers)
            })
        })
    }

    return store
}

export default configureStore
