import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import Cookies from 'js-cookie'
import {
    SMB_LOGIN_REQUEST,
    SMB_REGISTRATION_REQUEST,
    smbLoginResponse,
    smbRegistrationResponse,
    SMB_GET_CART_CONTENTS_REQUEST,
    getSmbCartContentsResponse,
    SMB_ADD_TO_CART,
    SMB_REMOVE_FROM_CART,
    setSmbCartFlyoutMode,
    smbItemsSyncedToBackend,
    SMB_ITEMS_SYNCED_TO_BACKEND,
    smbCheckLoginResponse,
    SMB_CHECK_LOGIN_REQUEST,
    SMB_UPDATE_DELIVERY_GROUP,
    smbEditCartItemResponse,
    SMB_EDIT_CART_ITEM_REQUEST,
    GET_SMB_STATUS_REQUEST,
    getSmbStatusResponse,
    getSmbStatusRequest,
} from '../actions/smbCart'
import isUndefined from 'lodash/isUndefined'
import has from 'lodash/has'
import { sendAddToCartItemsToDataLayer } from '../utils/dataLayer'
import { SMB_APPLICATION_SOURCE } from '../constants'
import {
    SMB_ERROR_ADDING_TO_CART,
    SMB_ERROR_EDITING_CART,
    SMB_ERROR_REMOVING_FROM_CART,
    SMB_ERROR_UPDATING_DELIVERY_GROUP_SHIPPING_INFORMATION,
    SMB_ERROR_UPDATING_DELIVERY_GROUP_SHIPPING_INFORMATION_MESSAGE,
} from '../errorConstants'

const axiosInstance = axios.create({
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

function* handleAddToCart(action) {
    const { meta, error } = action
    if (error || (meta && meta.sent === true)) {
        return
    }

    const payload = yield select((state) => state.smbCart.itemsPayload)

    if (payload.cards.length > 0 && payload.delivery_groups.length > 0) {
        try {
            const { data } = yield call(
                axiosInstance.post,
                `/ajax/cart/`,
                payload
            )
            // Push add to cart data layer event
            sendAddToCartItemsToDataLayer(
                data.cart.cart_items,
                payload.delivery_groups[0].uuid
            )
            yield put(smbItemsSyncedToBackend(data, { change: true }))
        } catch (error) {
            const {
                response: { data: errorData, status },
            } = error
            error.name = SMB_ERROR_ADDING_TO_CART
            error.message = errorData.message || error.message
            yield call(Sentry.captureException, error, {
                user: {
                    id: Cookies.get('GCG_CLIENT_USER_ID'),
                },
                extra: {
                    GCG_SMB_CART: Cookies.get('GCG_SMB_CART'),
                    errors: JSON.stringify(errorData?.errors, null, 2),
                    status_code: status,
                    delivery_groups: JSON.stringify(
                        payload.delivery_groups,
                        null,
                        2
                    ),
                    cards: JSON.stringify(payload.cards, null, 2),
                },
            })
            yield put(
                smbItemsSyncedToBackend(
                    errorData,
                    { cartRequestFailed: true },
                    true
                )
            )
        }
    }
}

function* handleRemoveFromCart(action) {
    if (
        has(action.payload, 'cart_id') &&
        has(action.payload, 'delivery_group_uuid')
    ) {
        let data
        try {
            const response = yield call(
                axiosInstance.post,
                `/ajax/cart/remove-delivery-group/`,
                action.payload
            )
            data = response.data
            yield put(smbItemsSyncedToBackend(data, { removingFromCart: true }))
        } catch (error) {
            const {
                response: { data: errorData },
            } = error
            error.name = SMB_ERROR_REMOVING_FROM_CART
            error.message = errorData.message || error.message
            yield call(Sentry.captureException, error, {
                contexts: {
                    data: {
                        errorData: JSON.stringify(errorData),
                        cart_id: JSON.stringify(action.payload.cart_id),
                        delivery_group_uuid: JSON.stringify(
                            action.payload.delivery_group_uuid
                        ),
                    },
                },
            })
            yield put(
                smbItemsSyncedToBackend(
                    errorData,
                    { cartRequestFailed: true },
                    true
                )
            )
        }
    }
}

function* handleEditCart(action) {
    const payload = yield select((state) => state.smbCart.itemsPayload)

    if (payload.cards.length > 0 && payload.delivery_groups.length > 0) {
        try {
            // Delete cart items first because adding replacement items
            // first could exceed the order limit.
            yield call(axiosInstance.request, {
                method: 'POST',
                url: '/ajax/cart/remove-delivery-group/',
                data: {
                    cart_id: action.payload.cart_id,
                    delivery_group_uuid: action.payload.delivery_group_uuid,
                },
            })
            // Add updated items to cart.
            const { data } = yield call(axios.post, `/ajax/cart/`, payload)

            yield put(smbItemsSyncedToBackend(data, { change: true }))
            yield put(smbEditCartItemResponse())
        } catch (error) {
            const {
                response: { data: errorData },
            } = error
            error.name = SMB_ERROR_EDITING_CART
            error.message = errorData.message || error.message
            yield call(Sentry.captureException, error, {
                contexts: {
                    data: {
                        errorData: JSON.stringify(errorData),
                        cart_id: JSON.stringify(action.payload.cart_id),
                        delivery_group_uuid: JSON.stringify(
                            action.payload.delivery_group_uuid
                        ),
                    },
                },
            })
            yield put(smbEditCartItemResponse(error, null, true))
        }
    }
}

function* handleDeliveryGroupUpdate(action) {
    if (
        has(action.payload, 'cart_id') &&
        has(action.payload, 'delivery_group_uuid') &&
        has(action.payload, 'shipping_method_id')
    ) {
        let data
        try {
            const response = yield call(
                axiosInstance.post,
                `/ajax/cart/update-delivery-group/`,
                action.payload
            )
            data = response.data
            yield put(smbItemsSyncedToBackend(data, { change: true }))
        } catch (error) {
            const {
                response: { data: errorData },
            } = error
            error.name = SMB_ERROR_UPDATING_DELIVERY_GROUP_SHIPPING_INFORMATION
            error.message = errorData.message || error.message
            yield call(Sentry.captureException, error, {
                contexts: {
                    data: {
                        errorData: JSON.stringify(errorData),
                        cart_id: JSON.stringify(action.payload.cart_id),
                        delivery_group_uuid: JSON.stringify(
                            action.payload.delivery_group_uuid
                        ),
                    },
                },
            })
            yield put(
                smbItemsSyncedToBackend(
                    errorData,
                    {
                        errorUpdatingDeliveryGroupShippingInfo:
                            SMB_ERROR_UPDATING_DELIVERY_GROUP_SHIPPING_INFORMATION_MESSAGE,
                    },
                    true
                )
            )
        }
    }
}

function* handleItemsSyncedToBackend(action) {
    if (action.meta?.cartRequestFailed) {
        yield put(
            setSmbCartFlyoutMode({
                display: true,
                addingToCart: false,
                addedToCart: !action.error,
            })
        )
    } else {
        yield put(
            setSmbCartFlyoutMode({
                addingToCart: false,
                addedToCart: !action.error,
            })
        )
    }
    return action.payload
}

function* getCartContents(action) {
    try {
        const { data, status } = yield call(axiosInstance.request, {
            url: '/ajax/cart/',
            params: {
                smb: 1,
            },
        })
        yield put(getSmbCartContentsResponse(data, { status }))
    } catch (e) {
        yield call(Sentry.captureException, e)
        yield put(getSmbCartContentsResponse(e.response.data, '', true))
    }
}

function* smbLogin(action) {
    const { email, password, recaptchaToken } = action.payload

    try {
        const res = yield call(axiosInstance.request, {
            url: '/ajax/user-login/',
            method: 'POST',
            data: {
                email: email,
                password: password,
                recaptchaToken: recaptchaToken,
            },
        })

        if (res.status !== 200) {
            yield put(smbLoginResponse('Login unsuccessful', '', true))
            return
        }
        yield put(smbLoginResponse())
        yield call(checkLogin)
    } catch (e) {
        yield call(Sentry.captureException, e)
        yield put(smbLoginResponse(e.response, '', true))
    }
}

function* smbRegistration(action) {
    let postData = {
        company_name: action.payload.companyName,
        company_phone_number: action.payload.companyPhoneNumber,
        address_line1: action.payload.addressLine1,
        address_line2: action.payload.addressLine2,
        city: action.payload.city,
        state: action.payload.stateAbbr,
        zip_code: action.payload.zipCode,
        contact_name: action.payload.contactName,
        contact_phone_number: action.payload.contactNumber,
        contact_email: action.payload.contactEmail,
        website: action.payload.website,
        password: action.payload.password,
        confirmed_password: action.payload.confirmedPassword,
        ein: action.payload.ein,
        reason_for_interest: action.payload.reasonForInterest,
        recaptchaToken: action.payload.recaptchaToken,
        source: SMB_APPLICATION_SOURCE.ORDER_FLOW,
    }

    // Remove keys that don't have a value
    Object.keys(postData).forEach(
        (k) => !postData[k] && postData[k] !== undefined && delete postData[k]
    )

    try {
        const response = yield call(axiosInstance.request, {
            url: '/ajax/submit-small-business-registration/',
            method: 'POST',
            data: postData,
        })

        yield put(smbRegistrationResponse(response.data))
        yield call(checkLogin)
    } catch (e) {
        yield call(Sentry.captureException, e)
        yield put(smbRegistrationResponse(e.response, '', true))
    }
}

function* getSmbStatus() {
    try {
        const { data } = yield call(axios.request, {
            url: `/ajax/small-business-status/`,
            method: 'get',
        })
        yield put(getSmbStatusResponse({ data }))
    } catch (e) {
        yield put(getSmbStatusResponse(e.response, '', true))
    }
}

function* checkLogin(action) {
    let saveUserInfo = false
    if (!isUndefined(action) && !isUndefined(action.payload)) {
        saveUserInfo = action.payload.saveUserInfo
    }

    try {
        const res = yield call(axiosInstance.request, {
            url: '/ajax/check-login/',
            method: 'GET',
        })
        const loggedIn = res.data.is_logged_in
        const user = {
            name: res.data.full_name,
            email: res.data.username,
            phoneNumber: res.data.phone_number,
        }

        const gcgClientUserIdCookie = Cookies.get('GCG_CLIENT_USER_ID')
        const userResponseId = res.data?.user_id?.toString()
        if (
            !gcgClientUserIdCookie ||
            gcgClientUserIdCookie !== userResponseId
        ) {
            Cookies.set('GCG_CLIENT_USER_ID', res.data?.user_id)
        }

        yield put(
            smbCheckLoginResponse({
                isLoggedIn: loggedIn,
                user: saveUserInfo ? user : null,
            })
        )
        yield put(getSmbStatusRequest())
    } catch (e) {
        yield call(Sentry.captureException, e)
        yield put(smbCheckLoginResponse({ isLoggedIn: false, user: null }))
    }
}

export function* smbCartSagas() {
    yield all([
        takeLatest(SMB_ADD_TO_CART, handleAddToCart),
        takeLatest(SMB_REMOVE_FROM_CART, handleRemoveFromCart),
        takeLatest(SMB_ITEMS_SYNCED_TO_BACKEND, handleItemsSyncedToBackend),
        takeLatest(SMB_UPDATE_DELIVERY_GROUP, handleDeliveryGroupUpdate),
        takeLatest(SMB_LOGIN_REQUEST, smbLogin),
        takeLatest(SMB_REGISTRATION_REQUEST, smbRegistration),
        takeLatest(SMB_GET_CART_CONTENTS_REQUEST, getCartContents),
        takeLatest(GET_SMB_STATUS_REQUEST, getSmbStatus),
        takeLatest(SMB_CHECK_LOGIN_REQUEST, checkLogin),
        takeLatest(SMB_EDIT_CART_ITEM_REQUEST, handleEditCart),
    ])
}

export default smbCartSagas
