function generateMerchantUrl(baseUrl, merchant, isEgiftsPage) {
    if (merchant.name !== 'Visa' && merchant.name !== 'Mastercard') {
        let merchantUrl = baseUrl + merchant.relativeUrl
        merchantUrl = merchantUrl.substring(merchantUrl.length - 1) !== '/' ? merchantUrl + '/' : merchantUrl

        return merchantUrl
    }

    const urlPrefix = merchant.name === 'Visa' ? 'visa-' : 'mastercard-'
    const virtualUrl = Boolean(isEgiftsPage) ? 'virtual' : ''

    return `${baseUrl}/${urlPrefix}gift-cards/${virtualUrl}`
}

export default generateMerchantUrl;
