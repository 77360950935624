export class BACDeliveryData {
    shippingName = null
    addressLine1 = null
    addressLine2 = null
    city = null
    state = null
    zip = null
    shippingMethod = null
    applyToAll = null
    carrierFrom = null

    constructor(
        shippingName,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        shippingMethod,
        applyToAll,
        carrierFrom
    ) {
        this.shippingName = shippingName
        this.addressLine1 = addressLine1
        this.addressLine2 = addressLine2
        this.city = city
        this.state = state
        this.zip = zip
        this.shippingMethod = shippingMethod
        this.applyToAll = applyToAll
        this.carrierFrom = carrierFrom
    }
}
