import { useQuery } from 'react-query'
import * as Sentry from '@sentry/react'
import { getLoggedIn } from '../api'
import { ERROR_GETTING_LOGGED_IN } from '../errorConstants'

const useLoggedIn = (isSmb = false) =>
    useQuery(
        [
            'loggedIn',
            {
                isSmb: isSmb,
            },
        ],
        getLoggedIn,
        {
            onError: (error) => {
                error.name = ERROR_GETTING_LOGGED_IN
                Sentry.captureException(error, {
                    contexts: {
                        data: {
                            errorMessage:
                                'There was a problem getting logged in status',
                        },
                    },
                })
            },
            onSuccess: (response) => {
                const htmlElem = document.getElementsByTagName('html')[0]
                if (response.is_logged_in) {
                    // Dispatch event that some legacy js is looking for (ex: store.js favorite system)
                    const event = new Event('userLoginCheckComplete')
                    document.dispatchEvent(event)

                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                        GCG_USER_ID: response.user_id,
                    })

                    // @TODO determine if this is still necessary
                    htmlElem.classList.add('user-session')
                    htmlElem.classList.remove('anonymous-session')
                } else {
                    htmlElem.classList.remove('user-session')
                    htmlElem.classList.add('guest-session')
                }
            },
            refetchOnMount: false,
            refetchOnWindowFocus: true, // True for reasons pertaining to cases where the user has multiple GCG windows open etc.
        }
    )

export default useLoggedIn
