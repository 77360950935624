import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Router } from 'react-router-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import rootSaga from './sagas'
import configureStore from './store'
import App from './App'
import './styles/styles.scss'
import { persistStore } from 'redux-persist'
import { createBrowserHistory } from 'history'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient()
const initialState = {}
const history = createBrowserHistory()
const store = configureStore(initialState, history)
const persistor = persistStore(store)

store.runSaga(rootSaga)

const render = (Component) => {
    ReactDOM.render(
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router history={history}>
                        <Component />
                    </Router>
                </PersistGate>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>,
        rootAppElement
    )
}

let rootAppElement = document.getElementById('root')
if (rootAppElement) {
    render(App)
}

if (module.hot) {
    module.hot.accept('./App', () => {
        if (rootAppElement) {
            // eslint-disable-next-line
            const App = require('./App').default
            render(App)
        }
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
