import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Rating from '@material-ui/lab/Rating'

const GoogleRatingsBanner = () => {
    return (
        <Grid
            container
            spacing={1}
            style={{
                backgroundColor: '#7347B2',
                marginTop: 0,
                height: '63px',
                justifyContent: 'center'
            }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                item
            >
                <Rating
                    value={4.1}
                    readOnly
                    precision={0.1}
                    size="small"
                    style={{
                        color: '#FFCE03',
                        lineHeight: 'inherit',
                    }}
                />
            </Grid>
            <Grid
                item
                style={{
                    paddingTop: {
                        xs: '2px !important',
                        sm: 'inherit',
                    },
                }}
            >
                <Typography
                    variant="body1"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        lineHeight: '1.35',
                        fontSize: '15px',
                        marginBottom: '0.35rem',
                        textAlign: 'center',
                    }}
                >
                    4.1{' '}
                    <span style={{ margin: '0 3px' }}>
                        <svg height="17" width="10" style={{ marginTop: 4 }}>
                            <line
                                x1="5"
                                y1="0"
                                x2="5"
                                y2="100"
                                style={{
                                    stroke: 'white',
                                    strokeWidth: 2,
                                }}
                            />
                        </svg>
                    </span>{' '}
                    1.5 Million Happy Customers
                </Typography>
            </Grid>
        </Grid>
    )
}

export default GoogleRatingsBanner