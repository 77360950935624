import { CARD_TYPE } from '../../../constants'

export class BACCardData {
    amount = null
    applyCardDetailsToAll = true
    applyCardImageToAll = true
    cardNetwork = null
    cloudinaryId = null
    design = null
    defaultAmount = null
    defaultCardNetwork = null
    defaultDeliveryMethod = ''
    deliveryMethod = ''
    messageOnCard = null
    nameOnCard = null
    quantity = null

    constructor(
        amount,
        applyCardDetailsToAll,
        applyCardImageToAll,
        cardNetwork,
        cloudinaryId,
        design,
        defaultAmount,
        defaultCardNetwork,
        defaultDeliveryMethod,
        deliveryMethod,
        messageOnCard,
        nameOnCard,
        quantity
    ) {
        this.amount = amount
        this.applyCardDetailsToAll = applyCardDetailsToAll
        this.applyCardImageToAll = applyCardImageToAll
        this.cardNetwork = cardNetwork
        this.cloudinaryId = cloudinaryId
        this.design = design
        this.defaultAmount = defaultAmount
        this.defaultCardNetwork = defaultCardNetwork
        this.defaultDeliveryMethod = defaultDeliveryMethod
        this.deliveryMethod = deliveryMethod
        this.messageOnCard = messageOnCard
        this.nameOnCard = nameOnCard
        this.quantity = quantity
    }

    isVirtual() {
        return this.deliveryMethod === CARD_TYPE.VIRTUAL
    }

    defaultDeliveryMethodIsVirtual() {
        return this.defaultDeliveryMethod === CARD_TYPE.VIRTUAL
    }
}
