import React from 'react'
import Header from '../../Base/Layout/Header'
import CreateACardFooter from '../CreateACardFooter'
import Footer from '../../Base/Footer'

const Layout = ({
    children,
    baseUrl,
    faqUrl,
    brandLogoUrl,
    useCreateACardFooter = true,
    removeFooterMargin = true,
    bodyStyles = {},
}) => {
    return (
        <>
            <div id="header-spacer" />
            <Header baseUrl={baseUrl} logoImage={brandLogoUrl} />
            <div
                style={{
                    minHeight: '80vh',
                    background: 'white',
                    ...bodyStyles,
                }}
            >
                {children}
            </div>
            {useCreateACardFooter ? (
                <CreateACardFooter
                    faqUrl={faqUrl}
                    removeFooterMargin={removeFooterMargin}
                />
            ) : (
                <Footer baseUrl={baseUrl} faqUrl={faqUrl} />
            )}
        </>
    )
}

export default Layout
