import React, { useEffect } from 'react'

const NortonLogo = ({ digicertTestClassEnabled }) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.text = `var __dcid = __dcid || [];__dcid.push(["DigiCertClickID_6QFMUaLr", "15", "s", "black", "6QFMUaLr"]);(function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());`
        if (document.body) document.body.appendChild(script)
    }, [])

    return (
        <div
            id="DigiCertClickID_6QFMUaLr"
            data-language="en"
            className={digicertTestClassEnabled ? 'allow-test-seal' : undefined}
            data-testid="norton-logo"
        ></div>
    )
}

export default NortonLogo
