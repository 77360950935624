import {
    IMPRESSIONS_SENT_TO_GA,
    RESET_IMPRESSIONS_SENT_TO_GA,
    FIRST_HITS_RETRIEVED,
    UPDATE_LOCAL_REFINEMENT_TYPE,
    SWITCH_REFINEMENT_TYPE,
    RESET_REFINEMENT_TYPE_SWITCH,
    UPDATE_SELECTED_STATE,
} from '../actions/algoliaSearch'

const initialState = {
    impressionsSentToGoogleAnalytics: false,
    isInitialTypeFilterRender: true,
    hideTypeFilter: false,
    firstHitsRetrieved: false,
    localRefinementType: 'city',
    switchRefinementType: false,
    selectedState: '',
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case IMPRESSIONS_SENT_TO_GA:
            return {
                ...state,
                impressionsSentToGoogleAnalytics: true,
            }
        case RESET_IMPRESSIONS_SENT_TO_GA:
            return {
                ...state,
                impressionsSentToGoogleAnalytics: false,
            }
        case FIRST_HITS_RETRIEVED:
            return {
                ...state,
                firstHitsRetrieved: true,
            }
        case UPDATE_LOCAL_REFINEMENT_TYPE:
            return {
                ...state,
                localRefinementType: action.data,
            }
        case SWITCH_REFINEMENT_TYPE:
            return {
                ...state,
                switchRefinementType: true,
            }
        case RESET_REFINEMENT_TYPE_SWITCH:
            return {
                ...state,
                switchRefinementType: false,
            }
        case UPDATE_SELECTED_STATE:
            return {
                ...state,
                selectedState: action.data,
            }
        default:
            return state
    }
}
