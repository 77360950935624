export const CAC_SET_CONFIG = 'GCG/CAC_SET_CONFIG'
export const setCacConfig = (payload, meta, error = false) => ({
    type: CAC_SET_CONFIG,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_SET_AVAILABLE_CARD_NETWORKS =
    'GCG/CAC_SET_AVAILABLE_CARD_NETWORKS'
export const setCacAvailableCardNetworks = (payload, meta, error = false) => ({
    type: CAC_SET_AVAILABLE_CARD_NETWORKS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_UPDATE_CARD_PROPERTY = 'GCG/CAC_UPDATE_CARD_PROPERTY'
export const updateCardProperty = (payload) => ({
    type: CAC_UPDATE_CARD_PROPERTY,
    payload,
})

export const CAC_EDIT_CARD_REQUESTED = 'GCG/CAC_EDIT_CARD_REQUESTED'
export const editCustomCardRequest = (payload, meta, error = false) => ({
    type: CAC_EDIT_CARD_REQUESTED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_RESET_EDIT_CARD_REQUESTED = 'GCG/CAC_RESET_EDIT_CARD_REQUESTED'
export const resetEditCustomCardRequest = (payload, meta, error = false) => ({
    type: CAC_RESET_EDIT_CARD_REQUESTED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_REMOVE_CARD = 'GCG/CAC_REMOVE_CARD'
export const removeCustomCard = (payload, meta, error = false) => ({
    type: CAC_REMOVE_CARD,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_RESET_CUSTOM_CARD = 'GCG/CAC_RESET_CUSTOM_CARD'
export const resetCustomCard = (payload, meta, error = false) => ({
    type: CAC_RESET_CUSTOM_CARD,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_RESET_APP_REQUESTED = 'GCG/CAC_RESET_APP_REQUESTED'
export const resetCacAppRequested = (payload, meta, error = false) => ({
    type: CAC_RESET_APP_REQUESTED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_CLEAR_RESET_APP_REQUESTED = 'GCG/CAC_CLEAR_RESET_APP_REQUESTED'
export const clearResetCacAppRequest = (payload, meta, error = false) => ({
    type: CAC_CLEAR_RESET_APP_REQUESTED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_RESET_APP = 'GCG/CAC_RESET_APP'
export const resetCacApp = (payload, meta, error = false) => ({
    type: CAC_RESET_APP,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_REQUEST_NEXT_STEP = 'GCG/CAC_REQUEST_NEXT_STEP'
export const requestNextStep = (payload, meta, error = false) => ({
    type: CAC_REQUEST_NEXT_STEP,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_ADD_TO_CART_REQUEST = 'GCG/CAC_ADD_TO_CART_REQUEST'
export const addToCartRequest = (payload, meta, error = false) => ({
    type: CAC_ADD_TO_CART_REQUEST,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_SET_CART_ERRORS = 'GCG/CAC_SET_CART_ERRORS'
export const setCartErrors = (payload, meta, error = false) => ({
    type: CAC_SET_CART_ERRORS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_SET_ADDING_TO_CART = 'GCG/CAC_SET_ADDING_TO_CART'
export const setAddingToCart = (payload, meta, error = false) => ({
    type: CAC_SET_ADDING_TO_CART,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_ADD_CUSTOM_CARDS = 'GCG/CAC_ADD_CUSTOM_CARDS'
export const addCustomCards = (payload, meta, error = false) => ({
    type: CAC_ADD_CUSTOM_CARDS,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_PRESET_CUSTOM_CARD = 'GCG/CAC_PRESET_CUSTOM_CARD'
export const presetCustomCard = (payload, meta, error = false) => ({
    type: CAC_PRESET_CUSTOM_CARD,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_DUPLICATE_CUSTOM_CARD = 'GCG/CAC_DUPLICATE_CUSTOM_CARD'
export const duplicateCustomCardRequest = (payload, meta, error = false) => ({
    type: CAC_DUPLICATE_CUSTOM_CARD,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_UPDATE_BULK_DELIVERY_GROUPS =
    'GCG/CAC_UPDATE_BULK_DELIVERY_GROUPS'
export const updateBulkDeliveryGroups = (payload) => ({
    type: CAC_UPDATE_BULK_DELIVERY_GROUPS,
    payload,
})

export const CAC_RESET_ADDRESS_GROUPS_CARDS_AND_BULK_DELIVERY_GROUPS =
    'GCG/CAC_RESET_ADDRESS_GROUPS_CARDS_AND_BULK_DELIVERY_GROUPS'
export const resetAddressGroupsCardsAndBulkDeliveryGroups = (payload) => ({
    type: CAC_RESET_ADDRESS_GROUPS_CARDS_AND_BULK_DELIVERY_GROUPS,
    payload,
})

export const CAC_SET_HAS_CONFIRMED_GROUP_SHIPMENTS =
    'GCG/CAC_SET_HAS_CONFIRMED_GROUP_SHIPMENTS'
export const setHasConfirmedGroupShipments = (payload) => ({
    type: CAC_SET_HAS_CONFIRMED_GROUP_SHIPMENTS,
    payload,
})

export const CAC_EDIT_CUSTOM_CARD = 'GCG/CAC_EDIT_CUSTOM_CARD'
export const editCustomCard = (payload) => ({
    type: CAC_EDIT_CUSTOM_CARD,
    payload,
})

export const CAC_SET_NEXT_CARD = 'GCG/CAC_SET_NEXT_CARD'
export const setNextCard = (payload) => ({
    type: CAC_SET_NEXT_CARD,
    payload,
})

export const CAC_SET_CUSTOM_BUILD_A_CARD_DATA =
    'GCG/CAC_SET_CUSTOM_BUILD_A_CARD_DATA'
export const setCustomBuildACardData = (payload) => ({
        type: CAC_SET_CUSTOM_BUILD_A_CARD_DATA,
        payload,
})

export const CAC_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED = 'GCG/CAC_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED'
export const discardItemAndReturnToReviewRequested = (payload, meta, error = false) => ({
    type: CAC_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_CLEAR_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED = 'GCG/CAC_CLEAR_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED'
export const clearDiscardItemAndReturnToReviewRequested = (payload, meta, error = false) => ({
    type: CAC_CLEAR_DISCARD_ITEM_AND_RETURN_TO_REVIEW_REQUESTED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_BACK_FROM_REVIEW_STEP_REQUESTED = 'GCG/CAC_BACK_FROM_REVIEW_STEP_REQUESTED'
export const setBackFromReviewStepRequested = (payload, meta, error = false) => ({
    type: CAC_BACK_FROM_REVIEW_STEP_REQUESTED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})

export const CAC_CLEAR_BACK_FROM_REVIEW_STEP_REQUESTED = 'GCG/CAC_CLEAR_BACK_FROM_REVIEW_STEP_REQUESTED'
export const clearBackFromReviewStepRequested = (payload, meta, error = false) => ({
    type: CAC_CLEAR_BACK_FROM_REVIEW_STEP_REQUESTED,
    payload,
    ...(error && { error }),
    ...(meta && { meta }),
})
