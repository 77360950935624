import {
    ORDER_STATUS_FOCUS_FORM_FIELD,
    GET_ORDER_STATUS,
    UPDATE_ORDER_STATUS_FORM_ERRORS,
    UPDATE_ORDER_STATUS_FORM,
    RESET_ORDER_STATUS_DETAILS_FIRST_LOADED
} from "../actions/orderStatus";
import OrderStatusForm from "../components/Models/OrderStatusForm";

const initialState = {
    resetForm: false,
    formErrors: {},
    orderStatusResponse: null,
    orderNotFound: false,
    focusedFieldId: null,
    orderStatusForm: new OrderStatusForm(),
    orderStatusDetailsFirstLoaded: false
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ORDER_STATUS:
            if (!action.meta || !action.meta.sent) {
                return {...state};
            }

            return action.error ?
                {
                   ...state,
                   orderStatusResponse: null,
                   orderNotFound: true
                } :
                {
                    ...state,
                    orderStatusResponse: action.payload,
                    orderNotFound: false,
                    orderStatusDetailsFirstLoaded: true
                }
        case ORDER_STATUS_FOCUS_FORM_FIELD:
            return {
                ...state,
                focusedFieldId: action.payload
            };
        case UPDATE_ORDER_STATUS_FORM_ERRORS:
            return {
                ...state,
                formErrors: action.payload
            };
        case UPDATE_ORDER_STATUS_FORM:
            return {
                ...state,
                orderStatusForm: action.payload
            }
        case RESET_ORDER_STATUS_DETAILS_FIRST_LOADED:
            return {
                ...state,
                orderStatusDetailsFirstLoaded: false
            }
        default:
            return state;
    }
}