export const GET_ORDER_STATUS = 'GCG/GET_ORDER_STATUS';
export const getOrderStatus = (payload, meta, error = false) => ({
    type: GET_ORDER_STATUS,
    payload,
    ...(error && {error}),
    ...(meta && {meta})
});

export const ORDER_STATUS_FOCUS_FORM_FIELD = 'GCG/ORDER_STATUS_FOCUS_FORM_FIELD';
export const orderStatusFocusFormField = (payload, meta, error = false) => ({
    type: ORDER_STATUS_FOCUS_FORM_FIELD,
    payload,
    ...(error && {error}),
    ...(meta && {meta})
});

export const UPDATE_ORDER_STATUS_FORM_ERRORS = 'GCG/UPDATE_ORDER_STATUS_FORM_ERRORS';
export const orderStatusUpdateFormErrors = (payload, meta, error = false) => ({
    type: UPDATE_ORDER_STATUS_FORM_ERRORS,
    payload,
    ...(error && {error}),
    ...(meta && {meta})
});

export const UPDATE_ORDER_STATUS_FORM = 'GCG/UPDATE_ORDER_STATUS_FORM';
export const updateOrderStatusForm = (payload, meta, error = false) => ({
    type: UPDATE_ORDER_STATUS_FORM,
    payload,
    ...(error && {error}),
    ...(meta && {meta})
});

export const RESET_ORDER_STATUS_DETAILS_FIRST_LOADED = 'GCG/RESET_ORDER_STATUS_DETAILS_FIRST_LOADED';
export const resetOrderStatusDetailsFirstLoaded = (payload, meta, error = false) => ({
    type: RESET_ORDER_STATUS_DETAILS_FIRST_LOADED,
    payload,
    ...(error && {error}),
    ...(meta && {meta})
});