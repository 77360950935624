import { Typography } from '@material-ui/core'
import React from 'react'
import startCase from 'lodash/startCase'

export const SMB_STATUS = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
}

export const CARD_TYPE = {
    PLASTIC: 'plastic',
    PHYSICAL: 'physical',
    DIGITAL: 'digital',
}

export const CARD_STYLE = {
    PREDESIGNED: 'predesigned',
    COBRAND: 'cobrand',
}

export const PAYMENT_METHOD = {
    EXISTING: 'existing_payment_method',
    NEW: 'new_payment_method',
}

export const ORDER_STATUS = {
    COMPLETE: 'complete',
    INCOMPLETE: 'incomplete',
    CANCELLED: 'cancelled',
}
export const CART_SOURCE = 'gcg_smb'
export const DELIVERY_STYLE = {
    TOGETHER: 'bulk_ship',
    INDIVIDUAL: 'individual_ship',
    INDIVIDUAL_EMAIL: 'email',
    SPREADSHEET: 'download',
    PHYSICAL_TOGETHER_INFO_TEXT:
        'The cards will be sent to a single location. All cards will still be affixed to a carrier inside the delivered package. Shipping fee is paid per location.',
    PHYSICAL_INDIVIDUAL_INFO_TEXT:
        'The cards will be sent to individual addresses that you specify per card. Shipping fee is paid per card.',
    VIRTUAL_SPREADSHEET_INFO_IMAGE:
        'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_692,h_130/v1622815155/GCG/smb_assets/gcg_spreadsheet_delivery_example_mkq0cw',
    VIRTUAL_SPREADSHEET_INFO_TEXT: (
        <Typography>
            A downloadable spreadsheet will be provided to you with the Card
            Values, Redemption URLs, and an Access Code (Your purchasing email
            address). You will be responsible for providing the Redemption URL
            and Access Code to your card eGift recipients.{' '}
        </Typography>
    ),
    VIRTUAL_INDIVIDUAL_EMAIL_INFO_IMAGE:
        'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_529,h_398/v1622816742/GCG/smb_assets/gcg_example_delivery_email_ouuyld',
    VIRTUAL_INDIVIDUAL_EMAIL_INFO_TEXT: (
        <Typography>
            Gift Card Granny will send an email to the addresses you specify,
            including a clickable URL link to access their eGift code. They will
            use the email address at which they received the eGift to access
            their eGift code.
        </Typography>
    ),
}
export const DETAILS_ENTRY_TYPE = {
    MANUAL: 'manual',
    SPREADSHEET: 'spreadsheet',
}

export const STEP_SMB_ORDER_FLOW_LANDING = {
    name: 'smb-order-flow-landing',
    route: '/gift-cards-for-business/',
    pageTitle:
        'Customized Visa and Mastercard Reward Cards For Your Business | GiftCardGranny',
    pageDescription:
        'Order customized Visa gift cards with your company logo in bulk for your business. Have them shipped same day for fast delivery!',
}

export const STEP_SMB_ORDER_FLOW_EXPRESS = {
    name: 'smb-order-flow-express',
    route: '/gift-cards-for-business/express/',
    pageTitle: 'Gift and Reward Cards for Business | GiftCardGranny',
    pageDescription:
        'Visa and Mastercard Reward Cards are the perfect solution to your business needs. Reward and incentivize your customers, employees, and clientele with plastic or virtual gift cards!',
}

export const STEP_SMB_ORDER_FLOW_PRODUCT_DETAILS = {
    name: 'smb-order-flow-product-details',
    route: '/gift-cards-for-business/details/',
    pageTitle:
        'Customize Your Visa Reward Card For Your Business | GiftCardGranny',
    pageDescription:
        'Customize your bulk visa gift card order for your business ',
}

export const STEP_SMB_ORDER_FLOW_CUSTOMIZE = {
    name: 'smb-order-flow-customize',
    route: '/gift-cards-for-business/customize/',
    pageTitle: 'Customize your Reward Cards | GiftCardGranny',
    pageDescription:
        'Customize your Visa or Mastercard Reward Cards. Choose how your cards will be delivered.',
}

export const STEP_SMB_ORDER_FLOW_LOGIN = {
    name: 'smb-order-flow-login',
    route: '/gift-cards-for-business/login/',
    pageTitle: 'Log In To Your Business Account | GiftCardGranny',
    pageDescription:
        'Log into your business account to order custom Visa gift cards in bulk on giftcardgranny.com',
}

export const STEP_SMB_ORDER_FLOW_REGISTRATION = {
    name: 'smb-order-flow-registration',
    route: '/gift-cards-for-business/registration/',
    pageTitle: 'Sign Up For A Business Account | GiftCardGranny',
    pageDescription:
        'Register your business on giftcardgranny.com in order to begin ordering custom Visa gift cards in bulk',
}

export const STEP_SMB_ORDER_FLOW_PLACE_ORDER = {
    name: 'smb-order-flow-place-order',
    route: '/gift-cards-for-business/place-order/',
    pageTitle: 'Finalize your Reward Cards Order | GiftCardGranny',
    pageDescription:
        'Review and place your order for your Visa or Mastercard Reward Cards.',
}

export const STEP_SMB_ORDER_FLOW_PLACE_ORDER_FORTER = {
    name: 'smb-order-flow-place-order',
    route: '/gift-cards-for-business/place-order-forter/',
    pageTitle: 'Finalize your Reward Cards Order | GiftCardGranny',
    pageDescription:
        'Review and place your order for your Visa or Mastercard Reward Cards.',
}

export const STEP_SMB_ORDER_FLOW_CONFIRMATION = {
    name: 'smb-order-flow-confirmation',
    route: '/gift-cards-for-business/confirmation/',
    pageTitle: 'View your Reward Cards Order | GiftCardGranny',
    pageDescription:
        'View your Gift Card Granny reward card order and print an invoice.',
}

export const STEP_SMB_ORDER_FLOW_ORDER_CANCELLED = {
    name: 'smb-order-flow-order-cancelled',
    route: '/gift-cards-for-business/order-cancelled/',
    pageTitle: 'Gift Cards For Business | Order Cancelled',
    pageDescription: 'Your order has been cancelled',
}

export const SMB_CUSTOMIZE_STEP = {
    ALL_IN_ONE: 1,
    INFORMATION_ADDING_TYPE_SELECTION: 1,
    MANUAL_ENTER_DETAILS: 2,
    UPLOAD_SPREADSHEET: 3,
    DATA_GRID: 4,
}

export const SMB_CUSTOMIZE_STEP_UTM_CONTENT = {
    1: 'gift_details',
    2: 'manual_details',
    3: 'upload_details',
    4: 'review_card',
}

export const SMB_COBRAND_BUILDER_STEP = {
    GETTING_STARTED: 1,
    SELECT_TYPE_AND_CREATE_DESIGN: 2,
    PROGRAM_DETAILS: 3,
    SENT_FOR_APPROVAL: 4,
}

export const SMB_CHECKOUT_STEP = {
    SHIPPING: 1,
    PAYMENT: 2,
    CONFIRMATION: 3,
}

export const smbOrderFlowSteps = [
    STEP_SMB_ORDER_FLOW_LANDING,
    STEP_SMB_ORDER_FLOW_EXPRESS,
    STEP_SMB_ORDER_FLOW_PRODUCT_DETAILS,
    STEP_SMB_ORDER_FLOW_CUSTOMIZE,
    STEP_SMB_ORDER_FLOW_LOGIN,
    STEP_SMB_ORDER_FLOW_REGISTRATION,
    STEP_SMB_ORDER_FLOW_PLACE_ORDER,
    STEP_SMB_ORDER_FLOW_PLACE_ORDER_FORTER,
    STEP_SMB_ORDER_FLOW_CONFIRMATION,
    STEP_SMB_ORDER_FLOW_ORDER_CANCELLED,
]

export const COBRAND_TYPE = {
    PREDESIGN: 'predesign',
    CUSTOM: 'custom',
}

export const SMB_EXPRESS_COBRAND_OPTIONS = [
    { label: 'With Logo', value: CARD_STYLE.COBRAND },
    { label: 'Without Logo', value: CARD_STYLE.PREDESIGNED },
]

export const SMB_EXPRESS_NAME_ON_CARD = 'CARD HOLDER'

export const getStepByRoute = (route) =>
    smbOrderFlowSteps.find((el) => el.route === route)

export const getCardType = (cardType) => {
    return cardType === CARD_TYPE.PLASTIC || cardType === CARD_TYPE.PHYSICAL
        ? startCase(CARD_TYPE.PLASTIC)
        : startCase(CARD_TYPE.DIGITAL)
}

export const getProductAltText = (cardNetwork, isVirtual) => {
    if (isVirtual) {
        return `${startCase(cardNetwork)} Virtual Reward Card`
    }
    return `${startCase(cardNetwork)} Reward Card`
}

export const CLOUDINARY_OVERLAY_TRANSFORMATION = {
    VISA: 't_smb_visa_emboss_preview',
    VISA_WITHOUT_EMBOSS_NAME: 't_smb_visa_without_emboss_preview',
    VISA_WITHOUT_EMBOSS_WITH_YOUR_LOGO_HERE:
        't_smb_visa_without_emboss_your_logo_preview',
    VISA_WITH_YOUR_LOGO_HERE: 't_smb_visa_emboss_your_logo_preview',
    MASTERCARD: 't_smb_mc_emboss_preview',
    MASTERCARD_WITHOUT_EMBOSS_NAME: 't_smb_mc_without_emboss_preview',
    MASTERCARD_WITHOUT_EMBOSS_WITH_YOUR_LOGO_HERE:
        't_smb_mc_without_emboss_your_logo_preview',
}
