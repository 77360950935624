export const IMPRESSIONS_SENT_TO_GA = 'GCG/IMPRESSIONS_SENT_TO_GA'
export const sendImpressionsToGA = (data) => ({
    type: IMPRESSIONS_SENT_TO_GA,
    data,
})

export const RESET_IMPRESSIONS_SENT_TO_GA = 'GCG/RESET_IMPRESSIONS_SENT_TO_GA'
export const resetSendImpressionsToGA = (data) => ({
    type: RESET_IMPRESSIONS_SENT_TO_GA,
    data,
})

export const FIRST_HITS_RETRIEVED = 'GCG/FIRST_HITS_RETRIEVED'
export const setFirstHitsRetrieved = (data) => ({
    type: FIRST_HITS_RETRIEVED,
    data,
})

export const UPDATE_LOCAL_REFINEMENT_TYPE = 'GCG/UPDATE_LOCAL_REFINEMENT_TYPE'
export const setLocalRefinementType = (data) => ({
    type: UPDATE_LOCAL_REFINEMENT_TYPE,
    data,
})

export const SWITCH_REFINEMENT_TYPE = 'GCG/SWITCH_REFINEMENT_TYPE'
export const switchRefinementType = (data) => ({
    type: SWITCH_REFINEMENT_TYPE,
    data,
})

export const RESET_REFINEMENT_TYPE_SWITCH = 'GCG/RESET_REFINEMENT_TYPE_SWITCH'
export const resetRefinementTypeSwitch = (data) => ({
    type: RESET_REFINEMENT_TYPE_SWITCH,
    data,
})

export const UPDATE_SELECTED_STATE = 'GCG/UPDATE_SELECTED_STATE'
export const updateSelectedState = (data) => ({
    type: UPDATE_SELECTED_STATE,
    data,
})
