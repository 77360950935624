export const CARD_SELECTED = 'GCG/CARD_SELECTED'
export const CARD_TYPE_SELECTED = 'GCG/CARD_TYPE_SELECTED'
export const RESET_FORMS = 'GCG/RESET_FORMS'
export const CLEAR_RESET_FORM = 'GCG/CLEAR_RESET_FORM'
export const MULTIPLE_RECIPIENT_CHANGED = 'GCG/MULTIPLE_RECIPIENT_CHANGED'
export const RESET_SELECTED_TYPE_CHANGED = 'GCG/RESET_SELECTED_TYPE_CHANGED'

export const UPDATE_QUANTITY = 'GCG/UPDATE_QUANTITY'
export const ADD_TO_CART = 'GCG/ADD_TO_CART'
export const ADD_TO_CART_SUCCESS = 'GCG/ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_ERROR = 'GCG/ADD_TO_CART_ERROR'
export const CLEAR_ADD_TO_CART_RESPONSE = 'GCG/CLEAR_ADD_TO_CART_RESPONSE'
export const ADD_PLASTIC_RECIPIENT = 'GCG/ADD_PLASTIC_RECIPIENT'
export const UPDATE_PLASTIC_RECIPIENTS = 'GCG/UPDATE_PLASTIC_RECIPIENTS'
export const ADD_EGIFT_RECIPIENT = 'GCG/ADD_EGIFT_RECIPIENT'
export const UPDATE_EGIFT_RECIPIENTS = 'GCG/UPDATE_EGIFT_RECIPIENTS'
export const UPDATE_PENDING_EGIFT_RECIPIENT =
    'GCG/UPDATE_PENDING_EGIFT_RECIPIENT'
export const UPDATE_PENDING_PLASTIC_RECIPIENT =
    'GCG/UPDATE_PENDING_PLASTIC_RECIPIENT'
export const UPDATE_PLASTIC_FORM_ERRORS = 'GCG/UPDATE_PLASTIC_FORM_ERRORS'
export const UPDATE_EGIFT_FORM_ERRORS = 'GCG/UPDATE_EGIFT_FORM_ERRORS'

export const GET_GIFT_TEMPLATES_SUCCESS = 'GCG/GET_GIFT_TEMPLATES_SUCCESS'
export const GET_MORE_GIFT_TEMPLATES = 'GCG/GET_MORE_GIFT_TEMPLATES'
export const GET_MORE_GIFT_TEMPLATES_SUCCESS =
    'GCG/GET_MORE_GIFT_TEMPLATES_SUCCESS'

export const POST_ADDRESS_VERIFICATION = 'GCG/POST_ADDRESS_VERIFICATION'
export const POST_ADDRESS_VERIFICATION_RESULT =
    'GCG/POST_ADDRESS_VERIFICATION_RESULT'
export const POST_ADDRESS_VERIFICATION_ERROR =
    'GCG/POST_ADDRESS_VERIFICATION_ERROR'
export const CLEAR_ADDRESS_RESULT = 'GCG/CLEAR_ADDRESS_VERIFICATION_RESULT'

export const FOCUS_FORM_FIELD = 'GCG/FOCUS_FORM_FIELD'

export const GET_MERCHANT_CARDS = 'GCG/GET_MERCHANT_CARDS'
export const getMerchantCards = (merchant) => ({
    type: GET_MERCHANT_CARDS,
    merchant,
})

export const GET_MERCHANT_CARDS_SUCCESS = 'GCG/GET_MERCHANT_CARDS_SUCCESS'
export const getMerchantCardsSuccess = (data) => ({
    type: GET_MERCHANT_CARDS_SUCCESS,
    data,
})

export const GET_MERCHANT_CARDS_ERROR = 'GCG/GET_MERCHANT_CARDS_ERROR'
export const getMerchantCardsError = (data) => ({
    type: GET_MERCHANT_CARDS_ERROR,
    data,
})

export const GET_MERCHANT_CARDS_NO_INVENTORY =
    'GCG/GET_MERCHANT_CARDS_NO_INVENTORY'
export const getMerchantCardsNoInventory = (data) => ({
    type: GET_MERCHANT_CARDS_NO_INVENTORY,
    data,
})

export const RESET_CARD_LIST_UPDATED = 'GCG/RESET_CARD_LIST_UPDATED'
export const resetCardListUpdated = () => ({
    type: RESET_CARD_LIST_UPDATED,
})

export const getGiftTemplatesSuccess = (data) => ({
    type: GET_GIFT_TEMPLATES_SUCCESS,
    data,
})

export const getMoreGiftTemplates = (page, limit) => ({
    type: GET_MORE_GIFT_TEMPLATES,
    data: {
        page: page,
        limit: limit,
    },
})

export const getMoreGiftTemplatesSuccess = (data) => ({
    type: GET_MORE_GIFT_TEMPLATES_SUCCESS,
    data,
})

export const cardSelected = (data) => ({
    type: CARD_SELECTED,
    data,
})

export const cardTypeSelected = (cardType) => ({
    type: CARD_TYPE_SELECTED,
    cardType,
})

export const resetSelectedTypeChanged = (data) => ({
    type: RESET_SELECTED_TYPE_CHANGED,
    data,
})

export const updateQuantity = (quantity) => ({
    type: UPDATE_QUANTITY,
    quantity,
})

export const addToCart = (payload) => ({
    type: ADD_TO_CART,
    payload,
})

export const addToCartSuccess = (data) => ({
    type: ADD_TO_CART_SUCCESS,
    data,
})

export const addToCartError = (data) => ({
    type: ADD_TO_CART_ERROR,
    data,
})

export const clearAddToCartResponse = (data) => ({
    type: CLEAR_ADD_TO_CART_RESPONSE,
    data: data,
})

export const resetForms = (data) => ({
    type: RESET_FORMS,
    data,
})

export const clearResetForm = (data) => ({
    type: CLEAR_RESET_FORM,
    data,
})

export const multipleRecipientChanged = (enabled) => ({
    type: MULTIPLE_RECIPIENT_CHANGED,
    enabled,
})

export const addPlasticRecipient = (recipient) => ({
    type: ADD_PLASTIC_RECIPIENT,
    recipient,
})

export const updatePlasticRecipients = (recipients) => ({
    type: UPDATE_PLASTIC_RECIPIENTS,
    recipients,
})

export const addEGiftRecipient = (recipient) => ({
    type: ADD_EGIFT_RECIPIENT,
    recipient,
})

export const updateEGiftRecipients = (recipients) => ({
    type: UPDATE_EGIFT_RECIPIENTS,
    recipients,
})

export const updatePendingEGiftRecipient = (recipient) => ({
    type: UPDATE_PENDING_EGIFT_RECIPIENT,
    recipient,
})

export const updatePendingPlasticRecipient = (recipient) => ({
    type: UPDATE_PENDING_PLASTIC_RECIPIENT,
    recipient,
})

export const updatePlasticFormErrors = (errors) => ({
    type: UPDATE_PLASTIC_FORM_ERRORS,
    errors,
})

export const updateEGiftFormErrors = (errors) => ({
    type: UPDATE_EGIFT_FORM_ERRORS,
    errors,
})

export const verifyAddress = (data) => ({
    type: POST_ADDRESS_VERIFICATION,
    address_line1: data.address_line1,
    address_line2: data.address_line2,
    city: data.city,
    state: data.state,
    zip_code: data.zip_code,
})

export const verifyAddressResult = (data) => ({
    type: POST_ADDRESS_VERIFICATION_RESULT,
    data,
})

export const verifyAddressError = (data) => ({
    type: POST_ADDRESS_VERIFICATION_ERROR,
    data,
})

export const clearAddressResult = () => ({
    type: CLEAR_ADDRESS_RESULT,
})

export const focusFormField = (fieldId) => ({
    type: FOCUS_FORM_FIELD,
    fieldId,
})
