export const ClosedCircleIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
    >
        <path
            d="M43.5742 22.9341C43.5742 34.8082 33.9483 44.4341 22.0742 44.4341C10.2001 44.4341 0.574219 34.8082 0.574219 22.9341C0.574219 11.06 10.2001 1.43408 22.0742 1.43408C33.9483 1.43408 43.5742 11.06 43.5742 22.9341Z"
            fill="white"
            stroke="#3B1A6E"
        />
        <path
            d="M30.0742 24.4341H14.0742V22.4341H30.0742V24.4341Z"
            fill="#7347B2"
        />
        <path
            d="M21.0742 31.4341V15.4341H23.0742V31.4341H21.0742Z"
            fill="#7347B2"
        />
    </svg>
)
export const OpenedCircleIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
    >
        <path
            d="M43.6777 22.9146C43.6777 34.7887 34.0519 44.4146 22.1777 44.4146C10.3036 44.4146 0.677734 34.7887 0.677734 22.9146C0.677734 11.0405 10.3036 1.41461 22.1777 1.41461C34.0519 1.41461 43.6777 11.0405 43.6777 22.9146Z"
            fill="white"
            stroke="#3B1A6E"
        />
        <path
            d="M30.1777 23.9146H14.1777V21.9146H30.1777V23.9146Z"
            fill="#7347B2"
        />
    </svg>
)
