import * as Sentry from '@sentry/browser'

export const smartyStreetsValidate = async (addressData) => {
    const SmartyStreetsSDK = require('smartystreets-javascript-sdk')
    const SmartyStreetsCore = SmartyStreetsSDK.core
    const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup
    const credentials = new SmartyStreetsCore.SharedCredentials(
        window.React.smarty_streets_key,
        window.React.smarty_streets_host
    )
    let clientBuilder = new SmartyStreetsCore.ClientBuilder(
        credentials
    ).withLicenses(['us-autocomplete-pro-cloud'])
    let client = clientBuilder.buildUsAutocompleteProClient()

    let lookup = new Lookup(addressData['address-1'])
    lookup.includeOnlyZIPCodes = [addressData['postal-code']]
    lookup.preferGeolocation = 'NONE' // must be set to NONE if include_only_zip_codes passed, see filtering docs https://rb.gy/kgdt5

    return await client
        .send(lookup)
        .then(function (lookupResponse) {
            const autocompleteSuggestions = lookupResponse?.result
            return autocompleteSuggestions?.length > 0
        })
        .catch(function (error) {
            Sentry.captureException(error)
            return null
        })
}
