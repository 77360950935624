import { put, takeLatest, all, call } from 'redux-saga/effects'
import axios from 'axios'
import {
    ADD_TO_CART,
    addToCartError,
    addToCartSuccess,
    GET_MERCHANT_CARDS,
    GET_MORE_GIFT_TEMPLATES,
    getGiftTemplatesSuccess,
    getMerchantCardsSuccess,
    getMoreGiftTemplatesSuccess,
    POST_ADDRESS_VERIFICATION,
    verifyAddressResult,
    verifyAddressError,
    getMerchantCardsError,
    getMerchantCardsNoInventory,
} from '../actions/buyCards'

function* handleFetchMerchantCards(action) {
    try {
        const { data } = yield call(axios.request, {
            url: `/ajax/direct-cards/${action.merchant}/`,
        })

        if (data.no_inventory) {
            yield put(getMerchantCardsNoInventory(data))
            return
        }

        yield put(getMerchantCardsSuccess(data))
        yield put(getGiftTemplatesSuccess(data.giftTemplates))
    } catch (e) {
        yield put(getMerchantCardsError(e.response))
    }
}

function* handleFetchGiftTemplates(action) {
    try {
        const { data } = yield call(axios.request, {
            url: `/ajax/gift-templates/?page=${action.data.page}&limit=${action.data.limit}`,
        })

        yield put(
            getMoreGiftTemplatesSuccess({ ...data, page: action.data.page })
        )
    } catch (e) {
        // todo: handle failure
    }
}

function* handleAddToCart(action) {
    try {
        const { data } = yield call(axios.request, {
            url: `/ajax/cart/`,
            method: 'post',
            data: action.payload,
        })
        yield put(addToCartSuccess(data))
    } catch (e) {
        yield put(addToCartError(e.response))
    }
}

function* handleAddressVerification(action) {
    try {
        const { data } = yield call(axios.request, {
            url: `/ajax/validate-address/`,
            method: 'post',
            data: {
                address_line1: action.address_line1,
                address_line2: action.address_line2,
                city: action.city,
                state: action.state,
                zip_code: action.zip_code,
            },
        })
        yield put(verifyAddressResult(data))
    } catch (e) {
        yield put(verifyAddressError(e))
    }
}

export function* buyCardsSagas() {
    yield all([
        takeLatest(GET_MERCHANT_CARDS, handleFetchMerchantCards),
        takeLatest(ADD_TO_CART, handleAddToCart),
        takeLatest(GET_MORE_GIFT_TEMPLATES, handleFetchGiftTemplates),
        takeLatest(POST_ADDRESS_VERIFICATION, handleAddressVerification),
    ])
}

export default buyCardsSagas
