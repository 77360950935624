const addToCartErrorHandler = (error, setError) => {
    let errorData = [
        'Unable to add to cart. Refresh the page and try again or contact support.',
    ]
    let responseData = {}

    if (error.response) {
        responseData = error.response.data

        if (responseData.message) {
            errorData = [responseData.message]
        }

        if (responseData.errors) {
            for (const property in responseData.errors) {
                if (responseData.errors.hasOwnProperty(property)) {
                    errorData = errorData.concat(responseData.errors[property])
                }
            }
        }
    } else if (typeof error === 'string') {
        errorData = [error]
    }

    setError({ ...responseData, messages: errorData })
}

export default addToCartErrorHandler
