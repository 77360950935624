import { combineReducers } from 'redux'
import buyCards from './buyCards'
import orderStatus from './orderStatus'
import algoliaSearch from './algoliaSearch'
import buyBulkReducer from './buyBulk'
import { connectRouter } from 'connected-react-router'
import smbCartReducer from './smbCart'
import createACardReducer from './createACard'
import orderDetailsReducer from './orderDetails'
import balanceCheckReducer from './balanceCheck'
import cartDrawerReducer from './cartDrawer'

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        buyCards: buyCards,
        algoliaSearch: algoliaSearch,
        orderStatus: orderStatus,
        buyBulk: buyBulkReducer,
        smbCart: smbCartReducer,
        createACard: createACardReducer,
        orderDetails: orderDetailsReducer,
        balanceCheck: balanceCheckReducer,
        cartDrawer: cartDrawerReducer,
    })

export default createRootReducer
