import React, { useCallback, useEffect, useRef, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import Menu from '@material-ui/icons/Menu'
import PropTypes from 'prop-types'
import HeaderSearchComponent from '../../Algolia/HeaderSearchComponent'
import AccountMenuItems from './AccountMenuItems'
import useMenuData from '../../../hooks/useMenuData'
import { pushToDataLayer } from '../../../utils/dataLayer'
import { useDispatch, useSelector } from 'react-redux'
import { setCartDrawerOpen } from '../../../actions/cartDrawer'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import useFetchCart from '../../../hooks/useFetchCart'
import Badge from '@material-ui/core/Badge'
import isNull from 'lodash/isNull'
import PromoBanner from './PromoBanner'
import PromoBannerCarousel from './PromoBannerCarousel'
import { v4 as uuidv4 } from 'uuid'
import { useLocation } from 'react-router-dom'
import HeaderHelpCenter from './HeaderHelpCenter'

const useStyles = ({ disablePromotionBanner }) =>
    makeStyles((theme) => ({
        appBar: {
            backgroundColor: '#FFF',
            borderBottom: '2px solid #F4F4F4',
        },
        toolBar: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '1rem',
        },
        phoneLinkBar: {
            display: 'flex',
            alignItems: 'center',
            padding: '0.5em 01em 0 0',
        },
        phoneLink: {
            marginLeft: 'auto',
        },
        phoneLinkText: {
            fontSize: '1em',
            fontWeight: 'bold',
        },
        phoneLinkIcon: {
            fontSize: '1.4em',
            verticalAlign: 'middle',
        },
        menuContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
            [theme.breakpoints.up('md')]: {
                padding: `0 ${theme.spacing(6)}px`,
            },
            backgroundColor: 'inherit', // Override legacy SCSS styling
            boxShadow: 'inherit', // Override legacy SCSS styling
        },
        brandLogoAnchor: {},
        brandLogo: {
            width: '90%',
            maxWidth: 300,
            [theme.breakpoints.up('sm')]: {
                width: 300,
            },
            position: 'inherit', // Override legacy SCSS styling
        },
        linkText: {
            color: '#222222',
        },
        link: {
            margin: '0.5rem 0 0.5rem 0.5rem',
        },
        drawerMenu: {
            padding: '0 4rem 0.5rem 1rem',
        },
        drawerImageMenu: {
            padding: '0 4rem 0 1rem',
        },
        accountIcon: {
            fontSize: '1.5rem',
            color: '#222222',
        },
        searchWrapper: {
            flexGrow: 1,
            paddingBottom: '0.5rem',
            [theme.breakpoints.up('md')]: {
                paddingBottom: 0,
            },
        },
        linkImg: {
            display: 'block',
        },
        promoBannerContainer: {
            minHeight: disablePromotionBanner ? '0' : '60px',
        },
    }))

const MobileMenu = ({
    baseUrl,
    logoImage,
    setIsSearchDropdownOpen,
    isLoggedIn,
    firstName,
    disablePromotionBanner,
    extoleUserData,
}) => {
    const classes = useStyles({ disablePromotionBanner })()
    const location = useLocation()
    const currentPath = location.pathname
    const headerMenuRef = useRef(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const [searchBarOpen, setSearchBarOpen] = useState(false)
    const [accountMenuOpen, setAccountMenuOpen] = useState(false)
    const [sortedMobileMenuData, setSortedMobileMenuData] = useState([])
    const dispatch = useDispatch()
    const cartDrawerOpen = useSelector(
        (state) => state.cartDrawer.cartDrawerOpen
    )
    const toggleCartDrawerOpen = () =>
        dispatch(setCartDrawerOpen(!cartDrawerOpen))

    const { data: menuData } = useMenuData()
    const { data: cartResponse } = useFetchCart()
    const cartQuantity = cartResponse?.cart?.total_quantity

    const hideSearchPaths = [
        '/visa-gift-cards/virtual/',
        '/mastercard-gift-cards/virtual/',
        '/visa-gift-cards/predesign/',
        '/mastercard-gift-cards/predesign/',
        '/build-a-card/',
        '/visa-gift-cards/buildacard/',
        '/mastercard-gift-cards/buildacard/',
    ]

    const isHideSearchBarPage = () => {
        if (hideSearchPaths.includes(currentPath)) {
            return true
        }

        const dynamicPathPattern = /^\/build-a-card\/[^/]+\//
        return dynamicPathPattern.test(currentPath)
    }

    const [promotionExpired, setPromotionExpired] = useState(false)
    const [coundDownVisibleScrollTop, setCoundDownVisibleScrollTop] =
        useState(true)

    const scrollHandler = useCallback(() => {
        let heightToHideFrom = 150
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        if (winScroll > heightToHideFrom) {
            coundDownVisibleScrollTop && setCoundDownVisibleScrollTop(false)
        } else {
            setCoundDownVisibleScrollTop(true)
        }
    }, [coundDownVisibleScrollTop, setCoundDownVisibleScrollTop])

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        return () => window.removeEventListener('scroll', scrollHandler)
    }, [scrollHandler])

    // Filter and sort the menu data to generate the mobile version
    useEffect(() => {
        if (!menuData?.links) return

        let sortedMobileMenuDataArray = []

        // Flatten top-level items and dropdown items
        menuData?.links.map((item) => {
            if (item['mobilePos']) {
                sortedMobileMenuDataArray.push(item)
            }
            if (item['dropdownItems']) {
                item['dropdownItems'].map((dropdownItem) => {
                    if (
                        dropdownItem['type'] === 'columnSet' &&
                        dropdownItem['columnItems']
                    ) {
                        dropdownItem['columnItems'].map((dropdownItemLink) => {
                            if (dropdownItemLink['mobilePos']) {
                                sortedMobileMenuDataArray.push(dropdownItemLink)
                            }
                        })
                    }
                })
            }
        })

        // Sort by mobilePos
        sortedMobileMenuDataArray.sort(
            (firstEl, secondEl) => firstEl.mobilePos - secondEl.mobilePos
        )

        setSortedMobileMenuData(sortedMobileMenuDataArray)
    }, [menuData?.links])

    /**
     * Set the header spacer div height so the homepage content appears below
     * the menu
     */
    useEffect(() => {
        if (!isNull(headerMenuRef?.current?.clientHeight)) {
            const headerHeight = headerMenuRef.current.clientHeight
            const headerSpacer = document.getElementById('header-spacer')
            if (!isNull(headerSpacer)) {
                headerSpacer.style.height = `${headerHeight}px`
            }
        }
    }, [headerMenuRef?.current?.clientHeight, searchBarOpen])

    return (
        <AppBar
            id="header-menu"
            ref={headerMenuRef}
            position="fixed"
            className={classes.appBar}
        >
            {!disablePromotionBanner &&
                coundDownVisibleScrollTop &&
                (menuData?.promoBanner &&
                menuData?.promoBanners?.length === 1 &&
                !promotionExpired ? (
                    <div className={classes.promoBannerContainer}>
                        <PromoBanner
                            promoBanner={menuData.promoBanner}
                            setPromotionExpired={setPromotionExpired}
                        />
                    </div>
                ) : (
                    menuData?.promoBanners?.length > 1 && (
                        <PromoBannerCarousel
                            promoBanners={menuData.promoBanners}
                            setPromotionExpired={setPromotionExpired}
                        />
                    )
                ))}

            <Toolbar aria-label={'Main Menu'} className={classes.toolBar}>
                <Container
                    component={'nav'}
                    className={classes.menuContainer}
                    aria-label={'Card management and user menu'}
                >
                    <>
                        <IconButton
                            aria-label="open drawer"
                            onClick={() => {
                                setMenuOpen(true)
                            }}
                            edge="start"
                        >
                            <Menu />
                        </IconButton>
                        <Drawer
                            anchor={'left'}
                            open={menuOpen}
                            onClose={() => {
                                setMenuOpen(false)
                            }}
                        >
                            <Box className={classes.drawerMenu}>
                                {sortedMobileMenuData &&
                                    sortedMobileMenuData
                                        .filter((item) => !item.linkImage) // Filter out items with a linkImage
                                        .map((item) => {
                                            const itemKey = uuidv4()

                                            return (
                                                <Link
                                                    href={item.href}
                                                    onClick={() =>
                                                        pushToDataLayer(
                                                            item.analyticsEvent
                                                        )
                                                    }
                                                    className={classes.link}
                                                    key={itemKey}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.linkText
                                                        }
                                                        variant={'body1'}
                                                    >
                                                        {item.linkText}
                                                    </Typography>
                                                </Link>
                                            )
                                        })}
                            </Box>
                            <HeaderHelpCenter
                                layout="mobile"
                                baseUrl={baseUrl}
                            />
                            <Box className={classes.drawerImageMenu}>
                                {sortedMobileMenuData &&
                                    sortedMobileMenuData
                                        .filter((item) => item.linkImage) // Filter out items without a linkImage
                                        .map((item) => {
                                            const itemKey = uuidv4()

                                            return (
                                                <Link
                                                    href={item.href}
                                                    onClick={() =>
                                                        pushToDataLayer(
                                                            item.analyticsEvent
                                                        )
                                                    }
                                                    className={classes.link}
                                                    key={itemKey}
                                                >
                                                    <img
                                                        src={item.linkImage}
                                                        className={
                                                            classes.linkImg
                                                        }
                                                        alt={item.linkText}
                                                    />
                                                </Link>
                                            )
                                        })}
                            </Box>
                        </Drawer>
                    </>
                    <a
                        className={classes.brandLogoAnchor}
                        href={baseUrl}
                        onClick={() =>
                            pushToDataLayer([
                                'HeaderClick',
                                'Header',
                                'Click',
                                'Home',
                            ])
                        }
                        title="Back to homepage"
                        style={{ flex: 1 }}
                    >
                        <img
                            className={classes.brandLogo}
                            src={logoImage}
                            alt={'Brand Logo'}
                        />
                    </a>
                    {isHideSearchBarPage() && (
                        <IconButton
                            onClick={() => {
                                setSearchBarOpen(!searchBarOpen)
                            }}
                            title={'Search'}
                        >
                            {searchBarOpen ? (
                                <img
                                    alt="Close Search"
                                    src={'/top-bar/Times.svg'}
                                    width="15"
                                    height="15"
                                />
                            ) : (
                                <img
                                    alt="Search"
                                    src={'/top-bar/Search.svg'}
                                    width="25"
                                    height="25"
                                />
                            )}
                        </IconButton>
                    )}
                    <IconButton
                        onClick={() => {
                            setAccountMenuOpen(true)
                            pushToDataLayer([
                                'HeaderClick',
                                'Header',
                                'Click',
                                'My Account',
                            ])
                        }}
                        title={'My account'}
                    >
                        <PersonOutlineIcon className={classes.accountIcon} />
                    </IconButton>
                    <IconButton
                        onClick={toggleCartDrawerOpen}
                        title={'My cart'}
                    >
                        <Badge badgeContent={cartQuantity} color="error">
                            <ShoppingCartOutlinedIcon
                                className={classes.accountIcon}
                            />
                        </Badge>
                    </IconButton>
                </Container>
            </Toolbar>
            {(!isHideSearchBarPage() || searchBarOpen) && (
                <Toolbar aria-label={'Search'}>
                    <Box className={classes.searchWrapper}>
                        <HeaderSearchComponent
                            baseUrl={baseUrl}
                            setIsSearchDropdownOpen={setIsSearchDropdownOpen}
                        />
                    </Box>
                </Toolbar>
            )}
            <Drawer
                anchor={'right'}
                open={accountMenuOpen}
                onClose={() => {
                    setAccountMenuOpen(false)
                }}
            >
                <AccountMenuItems
                    isLoggedIn={isLoggedIn}
                    firstName={firstName}
                    baseUrl={baseUrl}
                    extoleUserData={extoleUserData}
                />
            </Drawer>
        </AppBar>
    )
}

export default MobileMenu

MobileMenu.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    logoImage: PropTypes.string.isRequired,
    setIsSearchDropdownOpen: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
}
