import { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
    AccordionDetails,
    Button,
    Container,
    Divider,
    Grid,
    Link,
    Typography,
} from '@material-ui/core'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Accordion from '@material-ui/core/Accordion'
import { ClosedCircleIcon, OpenedCircleIcon } from '../../icons/FAQSectionIcons'

const useStyles = makeStyles((theme) => ({
    faqSection: {
        background: 'white',
        padding: '4rem 0',
        overflow: 'auto',
        '& a': {
            fontWeight: 600,
        },
    },
    faqSectionHeader: {
        fontSize: '1.65rem',
        fontWeight: '700',
        color: '#3A1B6F',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
            maxWidth: '25rem',
        },
    },
    showMoreButtonContainer: {
        marginTop: '2rem',
        [theme.breakpoints.up('md')]: {
            marginTop: '4rem',
        },
    },
    showMoreButton: {
        display: 'block',
        width: '12rem',
        color: '#3A1B6F',
        border: `3px solid #005C59`,
        borderRadius: 30,
        lineHeight: '1.5',
        height: 'auto',
        minHeight: '3.5rem',
        padding: '10px',
        fontSize: '1.3rem',
        fontWeight: '600',
        transition: '0.5s ease',
        marginBottom: '2rem',
        textTransform: 'none',
        textAlign: 'center',
        cursor: 'pointer',
        '& span': {
            position: 'relative',
            zIndex: 1,
            transition: '0.3s ease',
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: '100%',
            left: '0',
            top: '0',
            borderRadius: 30,
            transition: '0.5s ease',
        },
        '&:hover': {
            color: 'white',
            '&:before': {
                backgroundColor: '#005C59',
                width: '100%',
            },
        },
        [theme.breakpoints.up('md')]: {
            width: '15rem',
        },
    },
    faqSectionContent: {
        width: '95%',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            width: '70%',
            margin: '30px auto 0 auto',
        },
    },
    faqSectionQuestion: {
        color: '#3A1B6F',
        fontSize: '1.1rem',
        fontWeight: '600',
        letterSpacing: 1.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginLeft: '.75rem',
    },
    faqSectionAnswer: {
        marginLeft: theme.spacing(8),
        '& a': {
            textDecoration: 'underline',
        },
    },
    faqSectionAnswerUl: {
        marginLeft: theme.spacing(2),
        '& a': {
            textDecoration: 'underline',
        },
    },
    divider: {
        marginRight: '-8px',
        marginLeft: '30px',
        backgroundColor: '#CDCDCD',
    },
}))

const StyledAccordion = withStyles((theme) => ({
    root: {
        padding: '3px 0 3px',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        expanded: {
            margin: 'auto',
        },
        [theme.breakpoints.up('md')]: {
            padding: '10px 0 10px',
        },
    },
}))(Accordion)

const StyledAccordionSummary = withStyles((theme) => ({
    root: {
        flexDirection: 'row-reverse',
        marginBottom: '1rem',
        '& .MuiAccordionSummary-expandIcon': {
            color: '#3A1B6F',
        },
    },
    content: {
        margin: '0 0 0 10px!important',
    },
}))(AccordionSummary)

const FaqSection = ({ id, question, answer }) => {
    const panelName = `panel${id}`
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <StyledAccordion
            expanded={expanded === panelName}
            onChange={handleChange(panelName)}
        >
            <StyledAccordionSummary
                expandIcon={
                    expanded === panelName
                        ? OpenedCircleIcon()
                        : ClosedCircleIcon()
                }
                aria-controls={`${panelName}a-content`}
                id={`${panelName}a-header`}
            >
                {question}
            </StyledAccordionSummary>
            <AccordionDetails>{answer}</AccordionDetails>
        </StyledAccordion>
    )
}

const FAQSection = () => {
    const classes = useStyles()

    return (
        <div className={classes.faqSection}>
            <Container maxWidth="lg" className={classes.faqSectionContent}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h4"
                            className={classes.faqSectionHeader}
                        >
                            Frequently asked questions
                        </Typography>
                        <div className={classes.showMoreButtonContainer}>
                            <Button
                                className={classes.showMoreButton}
                                href={
                                    'https://help.giftcardgranny.com/hc/en-us'
                                }
                            >
                                <span>Get Help</span>
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FaqSection
                            id={1}
                            question={
                                <Typography
                                    className={classes.faqSectionQuestion}
                                >
                                    What are the most popular gift cards?
                                </Typography>
                            }
                            answer={
                                <Typography
                                    className={classes.faqSectionAnswer}
                                >
                                    As a leading gift card company, we feature
                                    options from popular national brands. Our
                                    offerings include Visa Gift Cards, Walmart
                                    Gift Cards, and iTunes Gift Cards. You’ll
                                    find gift card deals from many more of the
                                    top brand gift cards here, as well.
                                </Typography>
                            }
                        />
                        <Divider className={classes.divider} />
                        <FaqSection
                            id={2}
                            question={
                                <Typography
                                    className={classes.faqSectionQuestion}
                                >
                                    Why was my order canceled?
                                </Typography>
                            }
                            answer={
                                <Typography
                                    className={classes.faqSectionAnswer}
                                >
                                    Gift Card Granny reviews most orders before
                                    they are approved. Unfortunately, some
                                    orders may be canceled because they did not
                                    pass our compliance or risk processes. Other
                                    reasons why your order may have been
                                    canceled include payment issues, your
                                    customer image being rejected, or you are
                                    attempting to place a bulk order without a
                                    corporate account. More information can be
                                    found here:{' '}
                                    <Link href="https://help.giftcardgranny.com/hc/en-us/articles/4416673297047-Why-was-my-order-canceled-">
                                        Why was my order canceled?
                                    </Link>
                                </Typography>
                            }
                        />
                        <Divider className={classes.divider} />
                        <FaqSection
                            id={3}
                            question={
                                <Typography
                                    className={classes.faqSectionQuestion}
                                >
                                    Why did my transaction fail?
                                </Typography>
                            }
                            answer={
                                <div className={classes.faqSectionAnswer}>
                                    If your card is not working, it may be due
                                    to one of the following reasons:
                                    <ul className={classes.faqSectionAnswerUl}>
                                        <li>
                                            Your plastic card is not{' '}
                                            <Link href="https://help.giftcardgranny.com/hc/en-us/articles/360061601294-How-do-I-activate-my-Visa-or-Mastercard-gift-card-online-">
                                                activated.
                                            </Link>{' '}
                                            (Activation is not required for
                                            virtual cards).
                                        </li>
                                        <li>
                                            Your card is{' '}
                                            <Link href="https://help.giftcardgranny.com/hc/en-us/articles/1500008670321-Do-gift-cards-expire-">
                                                expired.
                                            </Link>
                                        </li>
                                        <li>
                                            You have insufficient funds on your
                                            card to support your purchase. (May
                                            be due to a{' '}
                                            <Link href="https://help.giftcardgranny.com/hc/en-us/articles/1500007938481-Using-at-Tipping-Establishments-restaurants-salons-etc-">
                                                pre-authorization charge
                                            </Link>
                                            ).
                                        </li>
                                        <li>
                                            The merchant does not accept prepaid
                                            cards. (See more info below)
                                            <span
                                                style={{
                                                    display: 'block',
                                                    marginTop: '20px',
                                                }}
                                            >
                                                Our Visa and Mastercard Prepaid
                                                Gift Cards are valid for use
                                                within the{' '}
                                                <strong
                                                    style={{
                                                        textDecoration:
                                                            'underline',
                                                    }}
                                                >
                                                    USA only.
                                                </strong>{' '}
                                                They are accepted almost
                                                everywhere that accepts Visa or
                                                Mastercard debit cards. Use
                                                these cards just like you would
                                                use a regular debit card.
                                            </span>
                                            <strong
                                                style={{
                                                    display: 'block',
                                                    marginTop: '8px',
                                                    fontStyle: 'italic',
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                Virtual cards are valid for
                                                online use only.
                                            </strong>
                                            <Link
                                                style={{
                                                    textDecoration: 'underline',
                                                    display: 'block',
                                                    marginTop: '8px',
                                                }}
                                                href="https://help.giftcardgranny.com/hc/en-us/articles/1500007939341-Common-reasons-why-your-card-is-not-working"
                                            >
                                                Common reasons why your card is
                                                not working
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                        <Divider className={classes.divider} />
                        <FaqSection
                            id={4}
                            question={
                                <Typography
                                    className={classes.faqSectionQuestion}
                                >
                                    Where is my order?
                                </Typography>
                            }
                            answer={
                                <Typography
                                    className={classes.faqSectionAnswer}
                                >
                                    Our plastic Visa and Mastercard gifts are
                                    mailed via the selected shipping method
                                    (FedEx or USPS) when an order is placed.
                                    Orders that are processed before 4 pm EST on
                                    a business day will print and ship that day!
                                    All other orders will be shipped on the
                                    following business day (Monday - Friday).
                                    <span
                                        style={{
                                            display: 'block',
                                            marginTop: '15px',
                                        }}
                                    >
                                        Virtual Visa and Mastercard gift cards
                                        are typically sent via email within 15
                                        minutes! All orders require a manual
                                        review which may delay delivery for up
                                        to 4 hours.
                                    </span>
                                    <Link
                                        style={{
                                            display: 'block',
                                            marginTop: '10px',
                                        }}
                                        href="https://help.giftcardgranny.com/hc/en-us/articles/1500008644142-I-have-not-received-my-card-"
                                    >
                                        I have not received my card.
                                    </Link>
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default FAQSection
