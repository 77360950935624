export const SMB_PLACE_ORDER = '/gift-cards-for-business/place-order/'
export const CUSTOM_GREETING_CARDS = '/custom-greeting-cards/'
export const BUY_LOCAL_GIFT_CARDS = '/buy-local-gift-cards/'
export const FREE_GIFT_CARDS = '/free-gift-cards/'
export const E_GIFT_CARDS = '/e-gift-cards/'
export const BUY_BULK_GIFT_CARDS = '/bulk-gift-cards/?buyingFor=company/'
export const ABOUT = '/about/'
export const PRESS = '/press/'
export const CONTACT_US = '/contact-us/'
export const IMAGE_GUIDELINES = '/image-guidelines/'
