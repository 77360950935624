import React, { lazy, Suspense } from 'react'
import GcgCircularProgress from '../../Loaders/GcgCircularProgress'
import { MuiThemeProvider } from '@material-ui/core'
import baseSiteTheme from '../baseSiteTheme'
import { createPortal } from 'react-dom'

const LazyHeader = lazy(() =>
    import('./Header' /* webpackChunkName: "header-navigation" */)
)
const headerMount = document.getElementById('headerPortal')

const HeaderPortal = () => {
    if (!headerMount) {
        return false
    }

    function render() {
        return (
            <React.Fragment>
                <Suspense fallback={<GcgCircularProgress />}>
                    <MuiThemeProvider theme={baseSiteTheme}>
                        <LazyHeader />
                    </MuiThemeProvider>
                </Suspense>
            </React.Fragment>
        )
    }

    return createPortal(render(), headerMount)
}

export default HeaderPortal
