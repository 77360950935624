import * as FullStory from '@fullstory/browser'

// See https://help.fullstory.com/hc/en-us/articles/360020828113
export async function fullstoryIdentify(userSessionData) {
    if (userSessionData?.is_logged_in) {
        console.log('User logged in, identify FS')
        FullStory.identify(`${userSessionData.user_id}`, {
            displayName: userSessionData.full_name ?? null,
            email: userSessionData.username,
            // other custom properties
        })
    }
}

// See https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties
export async function fullstorySetUserVars(userVars) {
    console.log('Setting FS user vars: ', userVars)
    FullStory.setUserVars(userVars)
}
